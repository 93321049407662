import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

type SimpleMenuProps = {
  style?: object;
  titles: string[];
  onClicks: (() => void)[];
};

type SimpleMenuState = {
  anchorEl: null | HTMLElement;
};
class SimpleMenu extends React.PureComponent<SimpleMenuProps, SimpleMenuState> {
  constructor(props: SimpleMenuProps) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleOpen(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { titles, onClicks, style } = this.props;
    return (
      <div style={style === undefined ? {} : style}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={(evt) => {
            this.handleOpen(evt);
          }}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={() => {
            this.handleClose();
          }}
        >
          {titles.map((title, index) => {
            return (
              <MenuItem
                key={title + index}
                onClick={() => {
                  this.setState({ anchorEl: null }, () => {
                    onClicks[index]();
                  });
                }}
              >
                {title}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
}

export default SimpleMenu;
