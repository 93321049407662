import moment from "moment";
import {
  LicensePlan,
  RefinedUserWithLicense,
  LicenseType,
  LicensePureObjectWDescription,
  getPlanLevel,
  getTypeLevel,
} from "@basalt-commons/api/object/license";
import { LicenseRoleTypes } from "@basalt-commons/api/object/user_license_map";
import { WorkspacePlanType } from "@basalt-commons/global-api/object/workspace_plan";

export const getDefaultLicense = (user: RefinedUserWithLicense) => {
  const { Licenses } = user;
  // User with no license?
  if (Licenses.length === 0)
    return {
      start: new Date().toISOString(),
      end: new Date().toISOString(),
      role: LicenseRoleTypes.ASSIGEND,
      plan: LicensePlan.FREE,
      type: LicenseType.TRIAL,
      description: "-",
    };

  let maxLevel: LicensePureObjectWDescription & { role: LicenseRoleTypes } = {
    ...Licenses[0],
    role: Licenses[0].role,
  };
  for (const license of Licenses) {
    if (getPlanLevel(maxLevel.plan) < getPlanLevel(license.plan)) {
      maxLevel = license;
    }
    if (getTypeLevel(maxLevel.type) < getTypeLevel(license.type)) {
      maxLevel = license;
    }
  }
  return maxLevel;
};

export const getDateFormat = (date?: string): string => {
  if (date === "-") return date;
  return moment(date).format("YYYY-MM-DD");
};

export const getDateTimeFormat = (date: string): string => {
  if (date === "-") return date;
  return moment(date).format("YYYY-MM-DD HH:mm");
};

export const getTypeName = (type: LicenseType | string): string => {
  switch (type) {
    case LicenseType.PERSONAL:
      return "개인";
    case LicenseType.INSTITUTION:
      return "기관";
    case LicenseType.TRIAL:
      return "체험판";
    default:
      return "없음";
  }
};

export const getRoleName = (role: LicenseRoleTypes): string => {
  switch (role) {
    case LicenseRoleTypes.ADMIN:
      return "관리자";
    case LicenseRoleTypes.MANAGER:
      return "매니저";
    case LicenseRoleTypes.ASSIGEND:
      return "참여자";
    default:
      return "없음";
  }
};

export const getPlanName = (plan: LicensePlan | string): string => {
  switch (plan) {
    case LicensePlan.FREE:
      return "무료";
    case LicensePlan.STANDARD:
      return "일반";
    default:
      return "없음";
  }
};

export const getWorkspacePlanName = (
  plan?: WorkspacePlanType | string
): string => {
  switch (plan) {
    case WorkspacePlanType.INDIVIDUAL:
      return "Individual";
    case WorkspacePlanType.TEAM:
      return "Team";
    case WorkspacePlanType.ENTERPRISE:
      return "Enterprise";
    default:
      return "없음";
  }
};
