import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { Dispatch } from "redux";
import {
  FileState,
  FileAction,
  FileActionKind,
  FileStateStatus,
  FileStateMachineType,
  fileStateMachine,
} from "../../store/reducers/file";
import { TokenStateStatus } from "../../store/reducers/token";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import { mkErr, InternalErrorKind } from "@redwit-commons/utils/exception2";
import Services from "@basalt-react-commons/services";
import {
  heicExtensionList,
  imageExtensionList,
  officeExtensionList,
  pdfExtensionList,
  validateHEICImageExtension,
  validateImageExtension,
  validateOfficeFileExtension,
  validatePDFFileExtension,
} from "@basalt-commons/api/request/note";

const checkExtension = (extension: string) => {
  if (imageExtensionList.includes(extension)) {
    return validateImageExtension(extension);
  } else if (pdfExtensionList.includes(extension)) {
    return validatePDFFileExtension(extension);
  } else if (heicExtensionList.includes(extension)) {
    return validateHEICImageExtension(extension);
  } else if (officeExtensionList.includes(extension)) {
    return validateOfficeFileExtension(extension);
  } else {
    throw mkErr({
      kind: InternalErrorKind.Abort,
      loc: "checkExtension",
      msg: "invalid file type",
    });
  }
};
const { IPFSService } = Services;
const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.file,
    token: state.token,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type FileContainerProps = PropsFromRedux & {
  stateMachine: FileStateMachineType;
};

class FileContainer extends ReduxStateComponent3<FileContainerProps> {
  static defaultProps = {
    stateMachine: fileStateMachine,
  };
  constructor(props: FileContainerProps) {
    super(props);
  }

  protected async onAction(
    _prevState: FileState,
    action: FileAction
  ): Promise<FileState> {
    const { token } = this.props;
    if (token.state.status !== TokenStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "FileContainer::onAction",
        msg: "token state is unvalid",
      });
    const userToken = token.state.token;
    switch (action.kind) {
      case FileActionKind.TRY_UPLOAD_IPFS: {
        const { file } = action;
        const mimeType = file.type.split("/")[1].toLowerCase();
        const extension = checkExtension(mimeType);
        const cid = await this.guardAwait(() =>
          IPFSService.uploadFileIPFS(userToken, file, extension)
        );
        if (cid === undefined) return { status: FileStateStatus.INIT };
        return { status: FileStateStatus.SUCCESS_UPLOAD_IPFS, cid };
      }
    }
  }
}

export default connector(FileContainer);
