import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../store/reducers";
import Services from "@basalt-react-commons/services";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  AdminAction,
  AdminActionKind,
  AdminState,
  adminStateMachine,
  AdminStateMachineType,
  AdminStateStatus,
} from "../../store/reducers/admin";
import { TokenStateStatus } from "../../store/reducers/token";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import { LicenseRoleTypes } from "@basalt-commons/api/object/user_license_map";
import { deleteTestUser } from "../../services/test";
import { UserLogType } from "@basalt-commons/api/object/user_log";
import moment from "moment";

const { AdminService, GlobalAdminService } = Services;
const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.admin,
    token: state.token,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type AdminContainerProps = PropsFromRedux & {
  stateMachine: AdminStateMachineType;
};

class AdminContainer extends ReduxStateComponent3<AdminContainerProps> {
  static defaultProps = {
    stateMachine: adminStateMachine,
  };
  constructor(props: AdminContainerProps) {
    super(props);
  }

  protected async onAction(
    prevState: AdminState,
    action: AdminAction
  ): Promise<AdminState> {
    const { token } = this.props;
    if (token.state.status !== TokenStateStatus.SUCCESS) {
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "AdminContainer::onAction",
        msg: "token is unvalid",
      });
    }
    const userToken = token.state.token;
    // const userEmail = token.state.email;

    switch (action.kind) {
      case AdminActionKind.TRY_GET_ALL_USERS: {
        const userRet = await this.guardAwait(() =>
          AdminService.getAllUsers(userToken)
        );
        const licenseRet = await this.guardAwait(() =>
          AdminService.getAllLicenses(userToken)
        );
        const visitLog = await this.guardAwait(() =>
          AdminService.getUserLogs(userToken, {
            type: UserLogType.VISIT,
            afterAt: moment().subtract(12, "years").toISOString(),
          })
        ).catch((_err) => {
          return undefined;
        });
        const noteLog = await this.guardAwait(() =>
          AdminService.getUserLogs(userToken, { type: UserLogType.UPLOAD_NOTE })
        ).catch((_err) => {
          return undefined;
        });
        const linkLog = await this.guardAwait(() =>
          AdminService.getUserLogs(userToken, {
            type: UserLogType.CREATE_LINK,
            afterAt: moment().subtract(12, "years").toISOString(),
          })
        );
        const pdfLog = await this.guardAwait(() =>
          AdminService.getUserLogs(userToken, {
            type: UserLogType.DOWNLOAD_FILE,
          })
        ).catch((_err) => {
          return undefined;
        });
        const users = userRet.response.results;
        const licenses = licenseRet.response.results;
        const workspaces =
          prevState.status === AdminStateStatus.SUCCESS
            ? prevState.workspaces
            : [];
        return {
          status: AdminStateStatus.SUCCESS,
          users,
          licenses,
          visitLog: visitLog?.response.results,
          extensionInfo: [],
          pdfInfo: pdfLog?.response.results,
          linkInfo: linkLog?.response.results,
          noteInfo: noteLog?.response.results,
          workspaces,
        };
      }
      case AdminActionKind.TRY_DELETE_USER: {
        if (prevState.status !== AdminStateStatus.SUCCESS) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: AdminActionKind.TRY_DELETE_LICENSE,
            msg: "prevState is unvalid",
          });
        }
        const { email } = action;
        await this.guardAwait(() => deleteTestUser(email));
        const userRet = await this.guardAwait(() =>
          AdminService.getAllUsers(userToken)
        );
        const licenseRet = await this.guardAwait(() =>
          AdminService.getAllLicenses(userToken)
        );

        const users = userRet.response.results;
        const licenses = licenseRet.response.results;

        return { ...prevState, users, licenses };
      }
      case AdminActionKind.TRY_CREATE_LICENSE: {
        if (prevState.status !== AdminStateStatus.SUCCESS) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: AdminActionKind.TRY_CREATE_LICENSE,
            msg: "prevState is unvalid",
          });
        }
        const { args, users, admins } = action;
        const ret = await this.guardAwait(() =>
          AdminService.createLicense(userToken, args)
        );
        const { id } = ret.response;
        for (const user of users) {
          await this.guardAwait(() =>
            AdminService.assignLicense(userToken, {
              UserId: user.id,
              LicenseId: id,
              role: LicenseRoleTypes.ASSIGEND,
            })
          );
        }

        for (const admin of admins) {
          await this.guardAwait(() =>
            AdminService.assignLicense(userToken, {
              UserId: admin.id,
              LicenseId: id,
              role: LicenseRoleTypes.ADMIN,
            })
          );
        }

        const licenseRet = await this.guardAwait(() =>
          AdminService.getAllLicenses(userToken)
        );
        const userRet = await this.guardAwait(() =>
          AdminService.getAllUsers(userToken)
        );

        const all_users = userRet.response.results;
        const all_licenses = licenseRet.response.results;
        return { ...prevState, users: all_users, licenses: all_licenses };
      }
      case AdminActionKind.TRY_UPDATE_LICENSE: {
        /**
         * Update는 resign과 assign을 모두 수행합니다
         */
        if (prevState.status !== AdminStateStatus.SUCCESS) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: AdminActionKind.TRY_UPDATE_LICENSE,
            msg: "prevState is unvalid",
          });
        }
        const { args, users, admins } = action;
        const ret = await this.guardAwait(() =>
          AdminService.updateLicense(userToken, args)
        );
        const { id } = ret.response;
        const targetLicense = prevState.licenses.find((l) => l.id === id);
        if (targetLicense === undefined)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: AdminActionKind.TRY_UPDATE_LICENSE,
            msg: "License doesn't exist",
          });

        const prevUsers = targetLicense.Users.filter(
          (u) => u.role === LicenseRoleTypes.ASSIGEND
        );
        const prevAdmins = targetLicense.Users.filter(
          (u) => u.role === LicenseRoleTypes.ADMIN
        );

        // assign & resign 확인하기
        const assignUsers = users.filter((u) => {
          for (const user of prevUsers) {
            if (user.id === u.id) return false;
          }
          return true;
        });
        const resignUsers = prevUsers.filter((u) => {
          for (const user of users) {
            if (user.id === u.id) return false;
          }
          return true;
        });

        const assignAdmins = admins.filter((u) => {
          for (const user of prevAdmins) {
            if (user.id === u.id) return false;
          }
          return true;
        });
        const resignAdmins = prevAdmins.filter((u) => {
          for (const user of admins) {
            if (user.id === u.id) return false;
          }
          return true;
        });

        //; resign 먼저 진행하기
        await this.guardAwait(() =>
          Promise.all(
            resignAdmins.map(
              async (u) =>
                await this.guardAwait(() =>
                  AdminService.resignLicense(userToken, {
                    UserId: u.id,
                    LicenseId: id,
                    role: LicenseRoleTypes.ADMIN,
                  })
                )
            )
          )
        );
        await this.guardAwait(() =>
          Promise.all(
            resignUsers.map(
              async (u) =>
                await this.guardAwait(() =>
                  AdminService.resignLicense(userToken, {
                    UserId: u.id,
                    LicenseId: id,
                    role: LicenseRoleTypes.ASSIGEND,
                  })
                )
            )
          )
        );

        // assign 진행하기
        await this.guardAwait(() =>
          Promise.all(
            assignUsers.map(async (u) =>
              AdminService.assignLicense(userToken, {
                UserId: u.id,
                LicenseId: id,
                role: LicenseRoleTypes.ASSIGEND,
              })
            )
          )
        );
        await this.guardAwait(() =>
          Promise.all(
            assignAdmins.map(async (u) =>
              AdminService.assignLicense(userToken, {
                UserId: u.id,
                LicenseId: id,
                role: LicenseRoleTypes.ADMIN,
              })
            )
          )
        );

        const licenseRet = await this.guardAwait(() =>
          AdminService.getAllLicenses(userToken)
        );
        const userRet = await this.guardAwait(() =>
          AdminService.getAllUsers(userToken)
        );

        const all_users = userRet.response.results;
        const all_licenses = licenseRet.response.results;
        return { ...prevState, licenses: all_licenses, users: all_users };
      }
      case AdminActionKind.TRY_DELETE_LICENSE_USER: {
        if (prevState.status !== AdminStateStatus.SUCCESS) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: AdminActionKind.TRY_DELETE_LICENSE_USER,
            msg: "prevState is unvalid",
          });
        }
        const { UserId, LicenseId, role } = action;
        // const targetLicense = prevState.licenses.find( ( l ) => l.id == LicenseId );
        // const targetUser = targetLicense?.Users.find( ( u ) => u.id === UserId );

        await this.guardAwait(() =>
          AdminService.resignLicense(userToken, { UserId, LicenseId, role })
        );

        const licenseRet = await this.guardAwait(() =>
          AdminService.getAllLicenses(userToken)
        );
        const userRet = await this.guardAwait(() =>
          AdminService.getAllUsers(userToken)
        );

        const all_users = userRet.response.results;
        const all_licenses = licenseRet.response.results;
        return { ...prevState, users: all_users, licenses: all_licenses };
      }
      case AdminActionKind.TRY_DELETE_LICENSE: {
        if (prevState.status !== AdminStateStatus.SUCCESS) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: AdminActionKind.TRY_DELETE_LICENSE,
            msg: "prevState is unvalid",
          });
        }
        const { licenseId } = action;
        // const targetLicense = prevState.licenses.find( ( l ) => l.id === licenseId );
        await this.guardAwait(() =>
          AdminService.deleteLicense(userToken, licenseId)
        );
        const licenseRet = await this.guardAwait(() =>
          AdminService.getAllLicenses(userToken)
        );
        const userRet = await this.guardAwait(() =>
          AdminService.getAllUsers(userToken)
        );

        const all_users = userRet.response.results;
        const all_licenses = licenseRet.response.results;
        return { ...prevState, users: all_users, licenses: all_licenses };
      }
      case AdminActionKind.TRY_GET_INFO: {
        if (prevState.status !== AdminStateStatus.SUCCESS) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: AdminActionKind.TRY_GET_INFO,
            msg: "prevState is unvalid",
          });
        }
        const visitLog = await this.guardAwait(() =>
          AdminService.getUserLogs(userToken, {
            type: UserLogType.VISIT,
            afterAt: moment().subtract(12, "years").toISOString(),
          })
        );
        const extensionLog = await this.guardAwait(() =>
          AdminService.getExtenstionsLogs(userToken, { fetchSize: 100 })
        );
        const pdfLog = await this.guardAwait(() =>
          AdminService.getUserLogs(userToken, {
            type: UserLogType.DOWNLOAD_FILE,
          })
        );
        const linkLog = await this.guardAwait(() =>
          AdminService.getUserLogs(userToken, {
            type: UserLogType.CREATE_LINK,
            afterAt: moment().subtract(12, "years").toISOString(),
          })
        );
        const noteLog = await this.guardAwait(() =>
          AdminService.getUserLogs(userToken, { type: UserLogType.UPLOAD_NOTE })
        );
        const ret = await this.guardAwait(() =>
          AdminService.getUserLogs(userToken, {
            type: UserLogType.UPLOAD_NOTE,
            afterAt: moment().subtract(12, "years").toISOString(),
          })
        );

        return {
          ...prevState,
          info: ret.response.results,
          visitLog: visitLog.response.results,
          extensionInfo: extensionLog.response.results,
          pdfInfo: pdfLog.response.results,
          linkInfo: linkLog.response.results,
          noteInfo: noteLog.response.results,
        };
      }
      case AdminActionKind.TRY_GET_ALL_WORKSPACES: {
        const workspaceRet = await this.guardAwait(() =>
          GlobalAdminService.getWorkspaceInfoList(userToken)
        );
        const workspaces = workspaceRet.response.results;
        const users =
          prevState.status === AdminStateStatus.SUCCESS ? prevState.users : [];

        return {
          status: AdminStateStatus.SUCCESS,
          workspaces,
          users,
          licenses: [],
          extensionInfo: [],
        };
      }
      case AdminActionKind.TRY_CREATE_PLAN: {
        if (prevState.status !== AdminStateStatus.SUCCESS) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: AdminActionKind.TRY_CREATE_PLAN,
            msg: "prevState is unvalid",
          });
        }
        const ret = await this.guardAwait(() =>
          GlobalAdminService.createPlan(userToken, action.args)
        );
        const newWorkspaces = prevState.workspaces.map((ws) =>
          ws.id !== ret.response.id ? ws : { ...ws, ...ret.response }
        );
        return { ...prevState, workspaces: newWorkspaces };
      }
      case AdminActionKind.TRY_UPDATE_PLAN: {
        if (prevState.status !== AdminStateStatus.SUCCESS) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: AdminActionKind.TRY_UPDATE_PLAN,
            msg: "prevState is unvalid",
          });
        }
        const ret = await this.guardAwait(() =>
          GlobalAdminService.updatePlan(userToken, action.args)
        );
        const newWorkspaces = prevState.workspaces.map((ws) =>
          ws.id !== ret.response.id ? ws : { ...ws, ...ret.response }
        );
        return { ...prevState, workspaces: newWorkspaces };
      }
      default: {
        return { status: AdminStateStatus.INIT };
      }
    }
  }
}

export default connector(AdminContainer);
