import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import {
  UserLogObject,
  UserLogObjectCore,
} from "@basalt-commons/api/object/user_log";

export enum LogStateStatus {
  INIT = "LogState::INIT",
  SUCCESS_GET_LOGS = "LogState::SUCCESS_GET_LOGS",
}

export enum LogActionKind {
  TRY_GET_ADMIN_LOGS = "LogAction::TRY_GET_ADMIN_LOGS",
  TRY_GET_MORE_LOGS = "LogAction::TRY_GET_MORE_LOGS",
  TRY_MAKE_LOGS = "LogAction::MAKE_LOG",
}

export type LogState =
  | {
      readonly status: LogStateStatus.INIT;
    }
  | {
      readonly status: LogStateStatus.SUCCESS_GET_LOGS;
      readonly adminLogs: (UserLogObject & { concurrency?: boolean })[];
    };
export type LogAction =
  | {
      readonly kind: LogActionKind.TRY_GET_ADMIN_LOGS;
      readonly afterAt?: string;
      readonly beforeAt?: string;
      readonly fetchSize?: number;
    }
  | ({
      readonly kind: LogActionKind.TRY_MAKE_LOGS;
    } & UserLogObjectCore)
  | {
      readonly kind: LogActionKind.TRY_GET_MORE_LOGS;
      readonly afterAt?: string;
      readonly beforeAt?: string;
      readonly fetchSize?: number;
    };

export type LogError = never;

const smid = "LOG_STATE_MACHINE3";
export type LogStateMachineType = StateMachine3<
  LogStateStatus,
  LogState,
  LogActionKind,
  LogAction,
  LogError
>;
export const logStateMachine: StateMachine3<
  LogStateStatus,
  LogState,
  LogActionKind,
  LogAction,
  LogError
> = new StateMachine3<
  LogStateStatus,
  LogState,
  LogActionKind,
  LogAction,
  LogError
>(smid, { status: LogStateStatus.INIT }, [
  transition(
    LogStateStatus.INIT,
    LogStateStatus.SUCCESS_GET_LOGS,
    LogActionKind.TRY_GET_ADMIN_LOGS
  ),
  transition(
    LogStateStatus.SUCCESS_GET_LOGS,
    LogStateStatus.SUCCESS_GET_LOGS,
    LogActionKind.TRY_GET_ADMIN_LOGS
  ),
  transition(
    LogStateStatus.INIT,
    LogStateStatus.SUCCESS_GET_LOGS,
    LogActionKind.TRY_GET_MORE_LOGS
  ),
  transition(
    LogStateStatus.SUCCESS_GET_LOGS,
    LogStateStatus.SUCCESS_GET_LOGS,
    LogActionKind.TRY_GET_MORE_LOGS
  ),
  transition(
    LogStateStatus.INIT,
    LogStateStatus.INIT,
    LogActionKind.TRY_MAKE_LOGS
  ),
  transition(
    LogStateStatus.SUCCESS_GET_LOGS,
    LogStateStatus.SUCCESS_GET_LOGS,
    LogActionKind.TRY_MAKE_LOGS
  ),
]);

export type DispatchLogAction = Dispatch<
  StateMachineAction<
    LogStateStatus,
    LogState,
    LogActionKind,
    LogAction,
    LogError
  >
>;
export default mkReducer<
  LogStateStatus,
  LogState,
  LogActionKind,
  LogAction,
  LogError
>(logStateMachine);
export const doLogAction = (
  dispatch: DispatchLogAction,
  nextAction: LogAction,
  onResolve: () => void = () => {},
  onReject: (err: LogError | InternalError) => void = () => {}
) => {
  dispatch(logStateMachine.newTryAction(nextAction, onResolve, onReject));
};

export const doLogActionAsync = (
  dispatch: DispatchLogAction,
  nextAction: LogAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(logStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetLog = (dispatch: DispatchLogAction) => {
  dispatch(logStateMachine.newResetAction());
};
