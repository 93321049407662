import React from "react";
import { Grid, Typography, Button, IconButton } from "@material-ui/core";
import UserItem, { UserItemProps } from "./items/UserItem";
import { RefinedUserWithLicense } from "@basalt-commons/api/object/license";
import { WorkspaceWithPaymentInfo } from "@basalt-commons/global-api/object/admin";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@material-ui/icons";

type UserListProps = {
  users: RefinedUserWithLicense[];
  workspaces: WorkspaceWithPaymentInfo[];
  getXLSXFile: () => void;
} & UserItemProps;

type UserListState = {
  firstIndex: number;
};

class UserList extends React.PureComponent<UserListProps, UserListState> {
  static defaultProps = {
    onClick: () => {},
  };
  constructor(props: UserListProps) {
    super(props);
    this.state = {
      firstIndex: 0,
    };
  }

  getUserWorkspace(userId: string) {
    const workspaces = this.props.workspaces;
    const userWorkspaces = workspaces.filter(
      (workspace) =>
        workspace.Users.find((user) => user.id === userId) !== undefined
    );
    return userWorkspaces;
  }

  onPrevUserList() {
    if (this.state.firstIndex <= 0) return;
    this.setState({ firstIndex: this.state.firstIndex - 20 });
    return;
  }

  onNextUserList() {
    const { users } = this.props;
    if (this.state.firstIndex + 20 >= users.length) return;
    this.setState({ firstIndex: this.state.firstIndex + 20 });
    return;
  }

  getUsersList() {
    const { users } = this.props;
    if (this.state.firstIndex + 20 > users.length)
      return users.slice(this.state.firstIndex, users.length);
    else return users.slice(this.state.firstIndex, 20 + this.state.firstIndex);
  }

  render() {
    const { users, getXLSXFile } = this.props;
    const filteredUser = this.getUsersList();
    return (
      <Grid
        container
        item
        xs={12}
        style={{
          width: "100%",
          padding: 24,
          backgroundColor: "white",
          borderRadius: 10,
        }}
      >
        <Grid
          container
          item
          xs={12}
          style={{
            borderBottom: `1px solid #f1f3f5`,
            padding: 16,
            marginBottom: 4,
          }}
        >
          <Grid container alignItems="center" item xs={6}>
            <Typography
              style={{
                fontSize: 14,
                color: "#495057",
                fontWeight: "bold",
                marginRight: 16,
              }}
            >
              전체 가입자 ({users.length} 명)
            </Typography>
            <IconButton
              onClick={() => {
                this.onPrevUserList();
              }}
              style={{ width: 30, height: 30, marginRight: 4 }}
            >
              <ArrowBackIosRounded style={{ fontSize: 18 }} />
            </IconButton>
            <Typography style={{ fontSize: 12 }}>
              {this.state.firstIndex + 1} ~{" "}
              {this.state.firstIndex + 20 > users.length
                ? users.length
                : this.state.firstIndex + 20}{" "}
              / {users.length}
            </Typography>
            <IconButton
              onClick={() => {
                this.onNextUserList();
              }}
              style={{ width: 30, height: 30, marginLeft: 4 }}
            >
              <ArrowForwardIosRounded style={{ fontSize: 18 }} />
            </IconButton>
          </Grid>
          <Grid justify="flex-end" container item xs={6}>
            <Button
              onClick={() => {
                getXLSXFile();
              }}
              style={{
                padding: 16,
                paddingTop: 8,
                paddingBottom: 8,
                backgroundColor: "#ebfbee",
              }}
            >
              <Typography
                style={{ fontSize: 14, fontWeight: "bold", color: "#2f9e44" }}
              >
                엑셀 다운받기
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          style={{ width: "100%", paddingTop: 12, paddingBottom: 12 }}
        >
          <Grid container item xs={1}></Grid>
          <Grid container item xs={3}>
            <Typography style={{ fontSize: 12 }}>이름</Typography>
          </Grid>
          <Grid container item xs={3}>
            <Typography style={{ fontSize: 12 }}>이메일</Typography>
          </Grid>
          <Grid container item xs={1}>
            <Typography style={{ fontSize: 12 }}>워크스페이스</Typography>
          </Grid>
          <Grid container item xs={2}>
            <Typography style={{ fontSize: 12 }}>Timezone</Typography>
          </Grid>
          <Grid container item xs={2}>
            <Typography style={{ fontSize: 12 }}>가입 일자</Typography>
          </Grid>
        </Grid>

        {filteredUser.map((user) => {
          return (
            <UserItem
              key={`${user.id}s`}
              user={user}
              {...this.props}
              workspaces={this.getUserWorkspace(user.id)}
            />
          );
        })}
      </Grid>
    );
  }
}

export default UserList;
