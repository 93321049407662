import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import {
  LicenseObject,
  RefinedUserWithLicense,
} from "@basalt-commons/api/object/license";
import {
  ICreateLicense,
  IUpdateLicense,
} from "@basalt-commons/api/request/admin";
import { LicenseRoleTypes } from "@basalt-commons/api/object/user_license_map";
import { UserLogObject } from "@basalt-commons/api/object/user_log";
import { WorkspaceWithPaymentInfo } from "@basalt-commons/global-api/object/admin";
import {
  ICreatePlan,
  IUpdatePlan,
} from "@basalt-commons/global-api/request/admin";

export enum AdminStateStatus {
  INIT = "AdminState::INIT",
  SUCCESS = "AdminState::SUCCESS",
}

export enum AdminActionKind {
  TRY_GET_ALL_USERS = "AdminAction::TRY_GET_ALL_USERS",
  TRY_CREATE_LICENSE = "AdminAction::TRY_CREAETE_LICENSE",
  TRY_UPDATE_LICENSE = "AdminAction::TRY_UPDATE_LICENSE",
  TRY_DELETE_LICENSE = "AdminAction::TRY_DELETE_LICENSE",
  TRY_DELETE_LICENSE_USER = "AdminAction::TRY_DELETE_LICENSE_USER",
  TRY_DELETE_USER = "AdminAction::TRY_DELETE_USER",
  TRY_GET_INFO = "AdminAction::TRY_GET_INFO",
  /** 바솔트 액션 */
  TRY_GET_ALL_WORKSPACES = "AdminAction::TRY_GET_ALL_WORKSPACES",
  TRY_CREATE_PLAN = "AdminAction::TRY_CREATE_PLAN",
  TRY_UPDATE_PLAN = "AdminAction::TRY_UPDATE_PLAN",
}

export type AdminState =
  | {
      readonly status: AdminStateStatus.INIT;
    }
  | {
      readonly status: AdminStateStatus.SUCCESS;
      readonly users: RefinedUserWithLicense[];
      readonly licenses: LicenseObject[];
      readonly visitLog?: UserLogObject[];
      readonly info?: UserLogObject[];
      readonly extensionInfo: string[];
      readonly pdfInfo?: UserLogObject[];
      readonly noteInfo?: UserLogObject[];
      readonly linkInfo?: UserLogObject[];
      readonly workspaces: WorkspaceWithPaymentInfo[];
    };

export type AdminAction =
  | {
      readonly kind: AdminActionKind.TRY_GET_ALL_USERS;
    }
  | {
      readonly kind: AdminActionKind.TRY_CREATE_LICENSE;
      readonly args: ICreateLicense;
      readonly users: RefinedUserWithLicense[];
      readonly admins: RefinedUserWithLicense[];
    }
  | {
      readonly kind: AdminActionKind.TRY_UPDATE_LICENSE;
      readonly args: IUpdateLicense;
      readonly users: RefinedUserWithLicense[];
      readonly admins: RefinedUserWithLicense[];
    }
  | {
      readonly kind: AdminActionKind.TRY_DELETE_LICENSE_USER;
      readonly UserId: string;
      readonly LicenseId: string;
      readonly role: LicenseRoleTypes;
    }
  | {
      readonly kind: AdminActionKind.TRY_DELETE_LICENSE;
      readonly licenseId: string;
    }
  | {
      readonly kind: AdminActionKind.TRY_DELETE_USER;
      readonly email: string;
    }
  | {
      readonly kind: AdminActionKind.TRY_GET_INFO;
      readonly afterAt?: string;
    }
  | {
      readonly kind: AdminActionKind.TRY_GET_ALL_WORKSPACES;
    }
  | {
      readonly kind: AdminActionKind.TRY_CREATE_PLAN;
      readonly args: ICreatePlan;
    }
  | {
      readonly kind: AdminActionKind.TRY_UPDATE_PLAN;
      readonly args: IUpdatePlan;
    };

export type AdminError = never;

const smid = "ADMIN_STATE_MACHINE3";
export type AdminStateMachineType = StateMachine3<
  AdminStateStatus,
  AdminState,
  AdminActionKind,
  AdminAction,
  AdminError
>;
export const adminStateMachine: AdminStateMachineType = new StateMachine3<
  AdminStateStatus,
  AdminState,
  AdminActionKind,
  AdminAction,
  AdminError
>(smid, { status: AdminStateStatus.INIT }, [
  transition(
    AdminStateStatus.INIT,
    AdminStateStatus.SUCCESS,
    AdminActionKind.TRY_GET_ALL_USERS
  ),
  transition(
    AdminStateStatus.SUCCESS,
    AdminStateStatus.SUCCESS,
    AdminActionKind.TRY_GET_ALL_USERS
  ),
  transition(
    AdminStateStatus.SUCCESS,
    AdminStateStatus.SUCCESS,
    AdminActionKind.TRY_CREATE_LICENSE
  ),
  transition(
    AdminStateStatus.SUCCESS,
    AdminStateStatus.SUCCESS,
    AdminActionKind.TRY_UPDATE_LICENSE
  ),
  transition(
    AdminStateStatus.SUCCESS,
    AdminStateStatus.SUCCESS,
    AdminActionKind.TRY_DELETE_LICENSE_USER
  ),
  transition(
    AdminStateStatus.SUCCESS,
    AdminStateStatus.SUCCESS,
    AdminActionKind.TRY_DELETE_LICENSE
  ),
  transition(
    AdminStateStatus.SUCCESS,
    AdminStateStatus.SUCCESS,
    AdminActionKind.TRY_DELETE_USER
  ),
  transition(
    AdminStateStatus.SUCCESS,
    AdminStateStatus.SUCCESS,
    AdminActionKind.TRY_GET_INFO
  ),

  transition(
    AdminStateStatus.INIT,
    AdminStateStatus.SUCCESS,
    AdminActionKind.TRY_GET_ALL_WORKSPACES
  ),
  transition(
    AdminStateStatus.SUCCESS,
    AdminStateStatus.SUCCESS,
    AdminActionKind.TRY_GET_ALL_WORKSPACES
  ),
  transition(
    AdminStateStatus.SUCCESS,
    AdminStateStatus.SUCCESS,
    AdminActionKind.TRY_CREATE_PLAN
  ),
  transition(
    AdminStateStatus.SUCCESS,
    AdminStateStatus.SUCCESS,
    AdminActionKind.TRY_UPDATE_PLAN
  ),
]);

export type DispatchAdminAction = Dispatch<
  StateMachineAction<
    AdminStateStatus,
    AdminState,
    AdminActionKind,
    AdminAction,
    AdminError
  >
>;
export default mkReducer<
  AdminStateStatus,
  AdminState,
  AdminActionKind,
  AdminAction,
  AdminError
>(adminStateMachine);
export const doAdminAction = (
  dispatch: DispatchAdminAction,
  nextAction: AdminAction,
  onResolve: () => void = () => {},
  onReject: (err: AdminError | InternalError) => void = () => {}
) => {
  dispatch(adminStateMachine.newTryAction(nextAction, onResolve, onReject));
};

export const doAdminActionAsync = (
  dispatch: DispatchAdminAction,
  nextAction: AdminAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(adminStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetAdmin = (dispatch: DispatchAdminAction) => {
  dispatch(adminStateMachine.newResetAction());
};
