import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  Grid,
  Typography,
  IconButton,
  Button,
  InputBase,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  Menu,
} from "@material-ui/core";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { includesString } from "@redwit-commons/utils/function";

import DeleteIcon from "@material-ui/icons/Delete";
import DatePicker from "../component/pure/DatePicker";
import moment from "moment";
import { getPlanName, getTypeName } from "../utils/data-validator";
import {
  LicenseObject,
  LicensePlan,
  LicenseType,
  RefinedUserWithLicense,
} from "@basalt-commons/api/object/license";
import { LicenseRoleTypes } from "@basalt-commons/api/object/user_license_map";
import UserList from "../component/pure/UserList";
import CloseIcon from "@material-ui/icons/Close";
export type LicenseCreateArgs = {
  plan: string;
  type: string;
  seats: number;
  institution_name: string;
  select_admins: RefinedUserWithLicense[];
  select_users: RefinedUserWithLicense[];
  description: string;
  start: string;
  end: string;
};

const mapStateToProps = (state: RootState) => {
  return {
    token: state.token,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {};
type ScreenProps = {
  users: RefinedUserWithLicense[];
  onComplete: (args: LicenseCreateArgs) => void;
  onClose: () => void;
  visible: boolean;
  defaultLicense?: LicenseObject;
};
type LicenseEditScreenProps = PropsFromRedux &
  RouteComponentProps<Props> &
  ScreenProps;
type LicenseEditScreenState = {
  plan: string;
  type: string;
  seats: number;
  institution_name: string;
  select_temp_admins: RefinedUserWithLicense[];
  select_temp_users: RefinedUserWithLicense[];
  select_admins: RefinedUserWithLicense[];
  select_users: RefinedUserWithLicense[];
  description: string;
  open_select_users: LicenseRoleTypes | undefined;
  start: string;
  end: string;
  search_word: string;
  anchorElPlan: null | HTMLElement;
  anchorElType: null | HTMLElement;
};

class LicenseEditScreen extends React.Component<
  LicenseEditScreenProps,
  LicenseEditScreenState
> {
  constructor(props: LicenseEditScreenProps) {
    super(props);
    this.state = {
      plan: "",
      type: "",
      seats: 0,
      start:
        props.defaultLicense === undefined
          ? moment().toISOString()
          : props.defaultLicense.start,
      end:
        props.defaultLicense === undefined
          ? moment().toISOString()
          : props.defaultLicense.end,
      select_temp_admins: [],
      select_temp_users: [],
      select_admins: [],
      select_users: [],
      description: "",
      institution_name: "",
      open_select_users: undefined,
      search_word: "",
      anchorElPlan: null,
      anchorElType: null,
    };
  }

  componentDidMount() {
    const { defaultLicense, users } = this.props;
    if (defaultLicense !== undefined) {
      const select_users: RefinedUserWithLicense[] = users.filter((u) => {
        for (const lu of defaultLicense.Users) {
          if (lu.id === u.id && lu.role === LicenseRoleTypes.ASSIGEND)
            return true;
        }
        return false;
      });
      const select_admins = users.filter((u) => {
        for (const lu of defaultLicense.Users) {
          if (lu.id === u.id && lu.role === LicenseRoleTypes.ADMIN) return true;
        }
        return false;
      });
      this.setState({
        ...defaultLicense,
        open_select_users: undefined,
        select_admins,
        select_users,
        search_word: "",
        select_temp_admins: select_admins,
        select_temp_users: select_users,
        anchorElPlan: null,
        anchorElType: null,
      });
      return;
    }
  }

  onChangeSeats(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const seats = parseInt(event.target.value.trim());
    this.setState({ ...this.state, seats: isNaN(seats) ? 0 : seats });
  }

  selectUser(
    target: RefinedUserWithLicense,
    isSelected: boolean,
    to: LicenseRoleTypes
  ) {
    if (to === LicenseRoleTypes.ASSIGEND) {
      if (isSelected === true) {
        // admin으로 동시에 선택된 사용자 선택 해제
        const newAdmins = this.state.select_temp_admins.filter(
          (admin) => target.id !== admin.id
        );
        this.setState({
          select_temp_admins: newAdmins,
          select_temp_users: [...this.state.select_temp_users, target],
        });
      } else {
        const select_temp_users = this.state.select_temp_users.filter(
          (u) => u.id !== target.id
        );
        this.setState({ select_temp_users });
      }
    } else if (to === LicenseRoleTypes.ADMIN) {
      if (isSelected === true) {
        // admin으로 동시에 선택된 사용자 선택 해제
        const newUsers = this.state.select_temp_users.filter(
          (user) => target.id !== user.id
        );
        this.setState({
          select_temp_admins: [...this.state.select_temp_admins, target],
          select_temp_users: newUsers,
        });
      } else {
        const select_temp_admins = this.state.select_temp_admins.filter(
          (u) => u.id !== target.id
        );
        this.setState({ select_temp_admins });
      }
    }
    return;
  }

  addUser() {
    const { select_temp_admins, select_temp_users } = this.state;
    let seats = this.state.seats;
    if (seats < select_temp_admins.length + select_temp_users.length) {
      seats = select_temp_users.length + select_temp_admins.length;
    }
    this.setState({
      seats,
      select_admins: select_temp_admins,
      select_users: select_temp_users,
      open_select_users: undefined,
    });
    return;
  }

  getFilterUsers(allUsers: RefinedUserWithLicense[]) {
    // [꺼둡니다] 라이센스 2개 생성 방지
    // const users = allUsers.filter( ( u ) => {
    //   let hasLicense = false;
    //   u.Licenses.map( l => {
    //     if ( l.plan === LicensePlan.STANDARD ) hasLicense = true;
    //   } );
    //   return !hasLicense;
    // } );

    const users = allUsers;

    const { search_word } = this.state;
    if (search_word.length === 0) {
      return users;
    }
    let filtered_word = search_word.trim().split(" ");
    let target_users = users;
    for (const key of filtered_word) {
      target_users = target_users.filter((row) => {
        if (includesString(row, key)) return true;
        for (const lisc of row.Licenses) {
          if (includesString(lisc, key)) return true;
        }
        return false;
      });
    }
    return target_users;
  }

  modalBody(role: LicenseRoleTypes) {
    const { users } = this.props;
    let keys: string[] = [];
    if (role === LicenseRoleTypes.ASSIGEND) {
      keys = this.state.select_temp_users.map((u) => u.id);
    } else if (role === LicenseRoleTypes.ADMIN) {
      keys = this.state.select_temp_admins.map((u) => u.id);
    }
    const list_user = this.getFilterUsers(users);
    return (
      <React.Fragment>
        <TextField
          label="검색:"
          value={this.state.search_word}
          onChange={(e) => {
            this.setState({ search_word: e.target.value });
          }}
          autoFocus={true}
        />
        <Grid container item style={{ height: 450 }}>
          <UserList
            isSelect={true}
            users={list_user}
            selectModel={keys}
            onRowSelected={(
              user: RefinedUserWithLicense,
              isSelected: boolean
            ) => {
              this.selectUser(user, isSelected, role);
            }}
          />
        </Grid>
      </React.Fragment>
    );
  }

  removerUser(user: RefinedUserWithLicense, to: LicenseRoleTypes) {
    if (to === LicenseRoleTypes.ASSIGEND) {
      const select_users = this.state.select_users.filter(
        (u) => u.id !== user.id
      );
      this.setState({ select_users });
    } else if (to === LicenseRoleTypes.ADMIN) {
      const select_admins = this.state.select_admins.filter(
        (a) => a.id !== user.id
      );
      this.setState({ select_admins });
    }
    return;
  }

  renderSelectUsersTable(
    users: RefinedUserWithLicense[],
    to: LicenseRoleTypes
  ) {
    return (
      <TableContainer style={{ maxHeight: 350 }}>
        <Table>
          <TableBody>
            {users.map((admin) => (
              <TableRow key={admin.id}>
                <TableCell style={{ fontSize: 14 }}>{admin.name}</TableCell>
                <TableCell>{admin.email}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      this.removerUser(admin, to);
                    }}
                    style={{
                      height: 40,
                      maxWidth: 40,
                      backgroundColor: "#fadcdc",
                      color: "#e03131",
                      borderRadius: 8,
                    }}
                  >
                    <DeleteIcon style={{ width: 24, height: 24 }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  checkValidInput() {
    const { institution_name, plan, type, start, end, seats } = this.state;
    if (institution_name.trim() === "") {
      return false;
    } else if (!Object.values(LicensePlan).includes(plan as LicensePlan)) {
      return false;
    } else if (!Object.values(LicenseType).includes(type as LicenseType)) {
      return false;
    } else if (moment(start).isAfter(moment(end))) {
      return false;
    } else if (seats < 1) {
      return false;
    } else return true;
  }

  onCreateLicense() {
    if (!this.checkValidInput()) {
      alert("형식에 맞지 않습니다.");
      return;
    }
    if (
      this.state.select_admins.length + this.state.select_users.length >
      this.state.seats
    ) {
      alert("형식에 맞지 않습니다.");
      return;
    }
    if (this.state.select_users.length + this.state.select_admins.length === 0)
      return;
    else {
      this.props.onComplete(this.state);
      return;
    }
  }

  render() {
    const { onClose } = this.props;
    return (
      <Grid
        alignItems="center"
        justify="center"
        xs={12}
        container
        item
        style={{
          height: "100%",
          backgroundColor: "#00000080",
          position: "absolute",
        }}
      >
        <Grid
          xs={7}
          container
          item
          alignContent="flex-start"
          style={{ height: 800, backgroundColor: "white", borderRadius: 20 }}
        >
          <Grid
            xs={12}
            direction="row"
            alignItems="center"
            container
            item
            style={{
              height: 80,
              borderBottom: "1px solid #f1f3f5",
              paddingLeft: 25,
              paddingRight: 10,
              marginTop: 10,
            }}
          >
            <Grid xs={9} item container alignItems="center">
              <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
                <span
                  role="img"
                  aria-label="writing hand"
                  style={{ marginRight: 8 }}
                >
                  👑
                </span>
                basalt 라이센스 추가/편집
              </Typography>
            </Grid>
            <Grid xs={3} item container alignItems="center" justify="flex-end">
              <IconButton
                onClick={() => {
                  onClose();
                }}
              >
                <CloseIcon style={{ color: "black" }} />
              </IconButton>
            </Grid>
          </Grid>

          <Grid
            xs={12}
            container
            item
            style={{ height: 800 - 80 - 100, overflow: "scroll" }}
          >
            <Grid
              xs={12}
              container
              item
              style={{ marginTop: 25, paddingLeft: 25, paddingRight: 25 }}
            >
              <Grid xs={12} container item>
                <Typography
                  style={{
                    fontSize: 15,
                    fontWeight: 550,
                    textDecoration: "underline #fadcdc 5px",
                  }}
                >
                  라이센스 정보
                </Typography>
              </Grid>
              <Grid
                xs={6}
                container
                item
                alignItems="center"
                style={{ height: 40, marginTop: 10 }}
              >
                <Typography
                  style={{
                    width: "30%",
                    fontSize: 14,
                    fontWeight: 550,
                    color: "#495057",
                  }}
                >
                  기관명
                </Typography>
                <InputBase
                  value={this.state.institution_name}
                  placeholder={"기관명을 입력해주세요"}
                  onChange={(evt) => {
                    this.setState({ institution_name: evt.target.value });
                  }}
                  style={{
                    width: "65%",
                    borderBottom: "1px solid #f1f3f5",
                    fontSize: 15,
                    fontWeight: 550,
                  }}
                />
              </Grid>
              <Grid xs={6} container item alignItems="center">
                <Typography
                  style={{
                    width: "15%",
                    fontSize: 14,
                    fontWeight: 550,
                    color: "#495057",
                  }}
                >
                  플랜
                </Typography>
                <div
                  style={{
                    width: "25%",
                    marginRight: "10%",
                    borderBottom: "1px solid #f1f3f5",
                  }}
                >
                  <Button
                    style={{ width: "100%" }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(evt) => {
                      this.setState({ anchorElPlan: evt.currentTarget });
                    }}
                  >
                    <Typography style={{ fontSize: 15, fontWeight: 550 }}>
                      {this.state.plan.length < 0
                        ? "플랜 선택하세요"
                        : getPlanName(this.state.plan)}
                    </Typography>
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorElPlan}
                    keepMounted
                    style={{ width: this.state.anchorElPlan?.clientWidth }}
                    open={Boolean(this.state.anchorElPlan)}
                    onClose={() => {
                      this.setState({ anchorElPlan: null });
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        this.setState({
                          plan: LicensePlan.FREE,
                          anchorElPlan: null,
                        });
                      }}
                      value={LicensePlan.FREE}
                      style={{
                        fontSize: 14,
                        width: this.state.anchorElPlan?.clientWidth,
                      }}
                    >
                      {getPlanName(LicensePlan.FREE)}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.setState({
                          plan: LicensePlan.STANDARD,
                          anchorElPlan: null,
                        });
                      }}
                      value={LicensePlan.STANDARD}
                      style={{
                        fontSize: 14,
                        width: this.state.anchorElPlan?.clientWidth,
                      }}
                    >
                      {getPlanName(LicensePlan.STANDARD)}
                    </MenuItem>
                  </Menu>
                </div>
                <Typography
                  style={{
                    width: "15%",
                    fontSize: 14,
                    fontWeight: 550,
                    color: "#495057",
                  }}
                >
                  타입
                </Typography>
                <div
                  style={{
                    width: "25%",
                    marginRight: "10%",
                    borderBottom: "1px solid #f1f3f5",
                  }}
                >
                  <Button
                    style={{ width: "100%" }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(evt) => {
                      this.setState({ anchorElType: evt.currentTarget });
                    }}
                  >
                    <Typography style={{ fontSize: 15, fontWeight: 550 }}>
                      {this.state.plan.length < 0
                        ? "타입 선택하세요"
                        : getTypeName(this.state.type)}
                    </Typography>
                  </Button>
                  <Menu
                    id="set-type"
                    anchorEl={this.state.anchorElType}
                    keepMounted
                    style={{ width: this.state.anchorElType?.clientWidth }}
                    open={Boolean(this.state.anchorElType)}
                    onClose={() => {
                      this.setState({ anchorElType: null });
                    }}
                  >
                    {this.state.plan === LicensePlan.FREE && (
                      <MenuItem
                        onClick={() => {
                          this.setState({
                            type: LicenseType.TRIAL,
                            anchorElType: null,
                          });
                        }}
                        value={LicenseType.TRIAL}
                        style={{
                          fontSize: 14,
                          width: this.state.anchorElType?.clientWidth,
                        }}
                      >
                        {getTypeName(LicenseType.TRIAL)}
                      </MenuItem>
                    )}
                    {this.state.plan === LicensePlan.STANDARD && (
                      <MenuItem
                        onClick={() => {
                          this.setState({
                            type: LicenseType.PERSONAL,
                            anchorElType: null,
                          });
                        }}
                        value={LicenseType.PERSONAL}
                        style={{
                          fontSize: 14,
                          width: this.state.anchorElType?.clientWidth,
                        }}
                      >
                        {getTypeName(LicenseType.PERSONAL)}
                      </MenuItem>
                    )}
                    {this.state.plan === LicensePlan.STANDARD && (
                      <MenuItem
                        onClick={() => {
                          this.setState({
                            type: LicenseType.INSTITUTION,
                            anchorElType: null,
                          });
                        }}
                        value={LicenseType.INSTITUTION}
                        style={{
                          fontSize: 14,
                          width: this.state.anchorElType?.clientWidth,
                        }}
                      >
                        {getTypeName(LicenseType.INSTITUTION)}
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              container
              item
              style={{ marginTop: 25, paddingLeft: 25, paddingRight: 25 }}
            >
              <Grid xs={6} container item style={{ height: 40, marginTop: 10 }}>
                <Typography
                  style={{
                    width: "30%",
                    fontSize: 14,
                    fontWeight: 550,
                    color: "#495057",
                  }}
                >
                  구매 개수
                </Typography>
                <InputBase
                  style={{
                    width: "65%",
                    borderBottom: "1px solid #f1f3f5",
                    fontSize: 15,
                    fontWeight: 550,
                  }}
                  value={this.state.seats}
                  onChange={(evt) => {
                    this.onChangeSeats(evt);
                  }}
                />
              </Grid>
              <Grid xs={6} container item style={{ height: 40, marginTop: 10 }}>
                <Typography
                  style={{
                    width: "30%",
                    fontSize: 14,
                    fontWeight: 550,
                    color: "#495057",
                  }}
                >
                  라이센스 기간
                </Typography>
                <DatePicker
                  defaultDate={this.state.start}
                  getDate={(date) => {
                    this.setState({ start: moment(date).toISOString() });
                  }}
                />
                ~{" "}
                <DatePicker
                  defaultDate={this.state.end}
                  getDate={(date) => {
                    this.setState({ end: moment(date).toISOString() });
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              xs={12}
              container
              item
              style={{ marginTop: 25, paddingLeft: 25, paddingRight: 25 }}
            >
              <Typography
                style={{
                  width: "15%",
                  fontSize: 14,
                  fontWeight: 550,
                  color: "#495057",
                }}
              >
                메모 :{" "}
              </Typography>
              <InputBase
                value={this.state.description}
                placeholder="메모를 입력하세요"
                style={{
                  borderBottom: "1px solid #f1f3f5",
                  width: "85%",
                  fontSize: 15,
                  fontWeight: 550,
                }}
                onChange={(evt) => {
                  this.setState({ description: evt.target.value });
                }}
              />
            </Grid>
            <Grid
              xs={12}
              container
              item
              style={{
                height: 450,
                marginTop: 25,
                paddingLeft: 25,
                paddingRight: 25,
              }}
            >
              <Grid
                alignContent="flex-start"
                alignItems="center"
                xs={12}
                container
                item
                style={{ height: 50 }}
              >
                {this.state.type === LicenseType.INSTITUTION &&
                  this.state.plan !== LicensePlan.FREE && (
                    <Button
                      onClick={() => {
                        this.setState({
                          open_select_users: LicenseRoleTypes.ADMIN,
                          select_temp_users: this.state.select_users,
                          select_temp_admins: this.state.select_admins,
                        });
                      }}
                      style={{
                        borderRadius: 10,
                        marginRight: 10,
                        border: "1px solid #dee2e6",
                      }}
                    >
                      <Typography style={{ color: "#343a40", fontSize: 14 }}>
                        관리자 추가하기
                      </Typography>
                    </Button>
                  )}
                {this.state.type === LicenseType.PERSONAL &&
                  this.state.plan !== LicensePlan.FREE && (
                    <Button
                      onClick={() => {
                        this.setState({
                          open_select_users: LicenseRoleTypes.ADMIN,
                          select_temp_users: this.state.select_users,
                          select_temp_admins: this.state.select_admins,
                        });
                      }}
                      style={{
                        borderRadius: 10,
                        marginRight: 10,
                        border: "1px solid #dee2e6",
                      }}
                    >
                      <Typography style={{ color: "#343a40", fontSize: 14 }}>
                        매니저 추가하기
                      </Typography>
                    </Button>
                  )}
                <Button
                  onClick={() => {
                    this.setState({
                      open_select_users: LicenseRoleTypes.ASSIGEND,
                      select_temp_users: this.state.select_users,
                      select_temp_admins: this.state.select_admins,
                    });
                  }}
                  style={{ borderRadius: 10, border: "1px solid #dee2e6" }}
                >
                  <Typography style={{ color: "#343a40", fontSize: 14 }}>
                    사용자 추가하기
                  </Typography>
                </Button>
              </Grid>
              <Grid
                alignContent="flex-start"
                container
                item
                style={{
                  width: "100%",
                  backgroundColor: "#f8f9fa",
                  borderRadius: 10,
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                {this.state.type === LicenseType.INSTITUTION &&
                  this.state.plan !== LicensePlan.FREE && (
                    <Grid xs={6} style={{ height: 400 }}>
                      <Typography
                        style={{
                          margin: 10,
                          fontWeight: "bold",
                          fontSize: 14,
                          textDecoration: "underline #c7b9f3 5px",
                        }}
                      >
                        추가된 관리자
                      </Typography>
                      {this.renderSelectUsersTable(
                        this.state.select_admins,
                        LicenseRoleTypes.ADMIN
                      )}
                    </Grid>
                  )}
                {this.state.type === LicenseType.PERSONAL &&
                  this.state.plan !== LicensePlan.FREE && (
                    <Grid xs={6} style={{ height: 400 }}>
                      <Typography
                        style={{
                          margin: 10,
                          fontWeight: "bold",
                          fontSize: 14,
                          textDecoration: "underline #c7b9f3 5px",
                        }}
                      >
                        추가된 매니저
                      </Typography>
                      {this.renderSelectUsersTable(
                        this.state.select_admins,
                        LicenseRoleTypes.ADMIN
                      )}
                    </Grid>
                  )}
                <Grid xs={6} style={{ height: 400 }}>
                  <Typography
                    style={{
                      margin: 10,
                      fontWeight: "bold",
                      fontSize: 14,
                      textDecoration: "underline #c7b9f3 5px",
                    }}
                  >
                    추가된 사용자
                  </Typography>
                  {this.renderSelectUsersTable(
                    this.state.select_users,
                    LicenseRoleTypes.ASSIGEND
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            container
            justify="flex-end"
            item
            style={{ marginTop: 15 }}
          >
            <Button
              onClick={() => {
                this.onCreateLicense();
              }}
              style={{
                backgroundColor: "#2f9e44",
                paddingLeft: 40,
                paddingRight: 40,
                height: 45,
                borderRadius: 8,
                marginRight: 25,
              }}
            >
              <Typography style={{ color: "white", fontSize: 14 }}>
                완료하기
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Dialog
          fullWidth={true}
          maxWidth={"xl"}
          onClose={() => {
            this.setState({
              open_select_users: undefined,
              select_temp_admins: this.state.select_admins,
              select_temp_users: this.state.select_users,
            });
          }}
          aria-labelledby="simple-dialog-title"
          open={this.state.open_select_users !== undefined}
        >
          <Grid xs={12} container item direction="row">
            <DialogTitle id="simple-dialog-title">회원 추가하기</DialogTitle>
          </Grid>
          {this.state.open_select_users !== undefined &&
            this.modalBody(this.state.open_select_users)}
          <Button
            onClick={() => {
              this.addUser();
            }}
            style={{
              backgroundColor: "black",
              color: "white",
              width: 200,
              height: 50,
              margin: 20,
            }}
          >
            완료
          </Button>
        </Dialog>
      </Grid>
    );
  }
}

export default connector(withRouter(LicenseEditScreen));
