import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../store/reducers";
import {
  TokenState,
  TokenAction,
  TokenStateStatus,
  TokenStateMachineType,
  TokenActionKind,
  tokenStateMachine,
} from "../../store/reducers/token";
import Services from "@basalt-react-commons/services";
import { goonoLoginType } from "@basalt-react-commons/services/user";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";

const { UserService, AdminService } = Services;
const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.token,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type TokenContainerProps = PropsFromRedux & {
  stateMachine: TokenStateMachineType;
};

class TokenContainer extends ReduxStateComponent3<TokenContainerProps> {
  static defaultProps = {
    stateMachine: tokenStateMachine,
  };
  constructor(props: TokenContainerProps) {
    super(props);
  }

  protected async onAction(
    prevState: TokenState,
    action: TokenAction
  ): Promise<TokenState> {
    switch (action.kind) {
      case TokenActionKind.TRY_LOGIN: {
        if (prevState.status !== TokenStateStatus.INIT) {
          return { ...prevState };
        }
        const { email, pw } = action;
        // TODO:: 플랫폼 체크 롤백하기
        const ret = await this.guardAwait(() =>
          UserService.goonoLogin({
            type: goonoLoginType.EMAIL,
            args: { email, password: pw, platform: "email" },
          })
        );
        if (ret.needRegister === true) {
          // 더이상 register하지 않습니다
          return { status: TokenStateStatus.INIT };
        }
        console.log(ret.token);
        console.log(ret.errorMessage);
        const profile = await this.guardAwait(() =>
          UserService.goonoProfile(ret.token)
        );
        return {
          status: TokenStateStatus.SUCCESS,
          token: ret.token,
          email,
          name: profile.response.name,
          id: profile.response.id,
        };
      }
      case TokenActionKind.TRY_LOGIN_GSUITE: {
        if (prevState.status !== TokenStateStatus.INIT) {
          return { ...prevState };
        }
        const { email, token } = action;
        const ret = await this.guardAwait(() =>
          UserService.goonoLogin({
            type: goonoLoginType.SNS,
            args: { token, platform: "google" },
          })
        );
        if (ret.needRegister === true) {
          // 더이상 register하지 않습니다
          return { status: TokenStateStatus.INIT };
        }
        const profile = await this.guardAwait(() =>
          UserService.goonoProfile(ret.token)
        );
        return {
          status: TokenStateStatus.SUCCESS,
          token: ret.token,
          email,
          name: profile.response.name,
          id: profile.response.id,
        };
      }
      case TokenActionKind.TRY_CHECK_LOGIN: {
        if (prevState.status !== TokenStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: TokenActionKind.TRY_CHECK_LOGIN,
            msg: "prevState is unvalid",
          });
        const { email, pw } = action;
        const ret = await this.guardAwait(() =>
          UserService.goonoLogin({
            type: goonoLoginType.EMAIL,
            args: { email, password: pw, platform: "email" },
          })
        );
        if (ret.needRegister === true)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: TokenActionKind.TRY_CHECK_LOGIN,
            msg: "no registered user 의 접근",
          });
        await this.guardAwait(() =>
          AdminService.getExtenstionsLogs(ret.token, {})
        );
        return {
          status: TokenStateStatus.SUCCESS_CHECK,
          token: ret.token,
          email,
          name: prevState.name,
          id: prevState.id,
        };
      }
      case TokenActionKind.TRY_BACK_LOGIN: {
        if (prevState.status !== TokenStateStatus.SUCCESS_CHECK) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: TokenActionKind.TRY_BACK_LOGIN,
            msg: "prevState is unvalid",
          });
        }
        return { ...prevState, status: TokenStateStatus.SUCCESS };
      }
      case TokenActionKind.TRY_LOGOUT: {
        return { status: TokenStateStatus.INIT };
      }
    }
  }
}

export default connector(TokenContainer);
