import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";

export enum TokenStateStatus {
  INIT = "TokenState::INIT",
  SUCCESS = "TokenState::SUCCESS",
  SUCCESS_CHECK = "TokenState::SUCCESS_CHECK",
}

export enum TokenActionKind {
  TRY_LOGIN = "TokenAction::TRY_LOGIN",
  TRY_LOGIN_GSUITE = "TokenAction::TRY_LOGIN_GSUITE",
  TRY_LOGOUT = "TokenAction::TRY_LOGOUT",
  TRY_CHECK_LOGIN = "TokenAction::TRY_CHECK_LOGIN",
  TRY_BACK_LOGIN = "TokenAction::TRY_BACK_LOGIN",
}

export type TokenState =
  | {
      readonly status: TokenStateStatus.INIT;
    }
  | {
      readonly status: TokenStateStatus.SUCCESS;
      readonly token: string;
      readonly email: string;
      readonly name: string;
      readonly id: string;
    }
  | {
      readonly status: TokenStateStatus.SUCCESS_CHECK;
      readonly token: string;
      readonly email: string;
      readonly name: string;
      readonly id: string;
    };

export type TokenAction =
  | {
      readonly kind: TokenActionKind.TRY_LOGIN;
      readonly email: string;
      readonly pw: string;
    }
  | {
      readonly kind: TokenActionKind.TRY_LOGOUT;
    }
  | {
      readonly kind: TokenActionKind.TRY_CHECK_LOGIN;
      readonly email: string;
      readonly pw: string;
    }
  | {
      readonly kind: TokenActionKind.TRY_BACK_LOGIN;
    }
  | {
      readonly kind: TokenActionKind.TRY_LOGIN_GSUITE;
      readonly token: string;
      readonly email: string;
    };

export type TokenError = never;

const smid = "TOKEN_STATE_MACHINE3";
export type TokenStateMachineType = StateMachine3<
  TokenStateStatus,
  TokenState,
  TokenActionKind,
  TokenAction,
  TokenError
>;
export const tokenStateMachine: TokenStateMachineType = new StateMachine3<
  TokenStateStatus,
  TokenState,
  TokenActionKind,
  TokenAction,
  TokenError
>(smid, { status: TokenStateStatus.INIT }, [
  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.SUCCESS,
    TokenActionKind.TRY_LOGIN
  ),
  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.INIT,
    TokenActionKind.TRY_LOGIN
  ),
  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.SUCCESS,
    TokenActionKind.TRY_LOGIN_GSUITE
  ),
  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.INIT,
    TokenActionKind.TRY_LOGIN_GSUITE
  ),
  transition(
    TokenStateStatus.SUCCESS,
    TokenStateStatus.INIT,
    TokenActionKind.TRY_LOGOUT
  ), // fail 되는 경우
  transition(
    TokenStateStatus.SUCCESS,
    TokenStateStatus.SUCCESS_CHECK,
    TokenActionKind.TRY_CHECK_LOGIN
  ),
  transition(
    TokenStateStatus.SUCCESS_CHECK,
    TokenStateStatus.SUCCESS,
    TokenActionKind.TRY_BACK_LOGIN
  ),
]);

export type DispatchTokenAction = Dispatch<
  StateMachineAction<
    TokenStateStatus,
    TokenState,
    TokenActionKind,
    TokenAction,
    TokenError
  >
>;
export default mkReducer<
  TokenStateStatus,
  TokenState,
  TokenActionKind,
  TokenAction,
  TokenError
>(tokenStateMachine);
export const doTokenAction = (
  dispatch: DispatchTokenAction,
  nextAction: TokenAction,
  onResolve: () => void = () => {},
  onReject: (err: TokenError | InternalError) => void = () => {}
) => {
  dispatch(tokenStateMachine.newTryAction(nextAction, onResolve, onReject));
};

export const doTokenActionAsync = (
  dispatch: DispatchTokenAction,
  nextAction: TokenAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(tokenStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetToken = (dispatch: DispatchTokenAction) => {
  dispatch(tokenStateMachine.newResetAction());
};
