import React from "react";
import { Grid, Button, Typography, IconButton } from "@material-ui/core";
import Theme from "../../../styles/Theme";
import { ReactComponent as LogoIcon } from "../../../assets/images/logo.svg";
// import ScreenURL from "../../../routes/path";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ScreenURL from "../../../routes/path";
import { PublicRounded } from "@material-ui/icons";
// import SubHeader from "./SubHeader";
// import { GOONOAdminLogType } from "@goono-commons/api/object/user_log";

type AdminHeaderProps = {
  name: string;
  curr?: "user" | "story" | "access" | "board" | "noti" | "workspace";
  onLogout: () => void;
  onReplace: (url: string) => void;
  // onMakeLog: ( type: GOONOAdminLogType ) => void,
  onToggleMode: () => void;
  firstTitle: string;
  secondTitle: string;
  currMode: string;
  modes: string[];
  rightButtonTitle?: string;
  onRightButtonClick?: () => void;
};

class AdminHeader extends React.PureComponent<AdminHeaderProps> {
  static defaultProps = {
    onToggleMode: () => {},
    firstTitle: "",
    secondTitle: "",
    currMode: "",
    modes: [],
  };
  constructor(props: AdminHeaderProps) {
    super(props);
  }

  getCurrStyle(
    icon: "user" | "story" | "access" | "board" | "noti" | "workspace"
  ) {
    const { curr } = this.props;
    if (curr === icon) return { backgroundColor: "#edf3fd", color: "#3578ea" };
    else return { backgroundColor: "transparent", color: "black" };
  }

  render() {
    const { onLogout, name } = this.props;
    const userStyle = this.getCurrStyle("user");
    const accessStyle = this.getCurrStyle("access");
    const boardStyle = this.getCurrStyle("board");
    const workspaceStyle = this.getCurrStyle("workspace");
    const boxStyle =
      this.props.curr === "user" || this.props.curr === "story"
        ? { borderBottom: "1px solid #f1f3f5" }
        : { ...Theme.box_shadow };
    return (
      <Grid xs={12} container item>
        <Grid
          xs={12}
          container
          item
          style={{ height: 90, ...boxStyle, backgroundColor: "white" }}
        >
          <Grid xs={3} alignItems="center" container item>
            <Button
              style={{ marginLeft: 20 }}
              onClick={() => {
                this.props.onReplace(ScreenURL.MAIN);
              }}
            >
              <LogoIcon style={{ width: 103, height: 22 }} />
            </Button>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="center">
            {this.props.curr !== undefined ? (
              <>
                <IconButton
                  onClick={() => {
                    // this.props.onMakeLog( GOONOAdminLogType.VIEW_LICENSE );
                    this.props.onReplace(ScreenURL.USER_MAIN);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 60,
                    height: 60,
                    borderRadius: 8,
                    margin: 5,
                    backgroundColor: userStyle.backgroundColor,
                  }}
                >
                  <Grid
                    container
                    item
                    direction="column"
                    alignItems="center"
                    style={{ width: "100%" }}
                  >
                    <AccountCircleIcon
                      style={{ fontSize: 30, color: userStyle.color }}
                    />
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: 550,
                        color: userStyle.color,
                      }}
                    >
                      사용자
                    </Typography>
                  </Grid>
                </IconButton>
                <IconButton
                  onClick={() => {
                    // this.props.onMakeLog( GOONOAdminLogType.VIEW_USER_TIME );
                    this.props.onReplace(ScreenURL.ACCESS);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 60,
                    height: 60,
                    borderRadius: 8,
                    margin: 5,
                    backgroundColor: accessStyle.backgroundColor,
                  }}
                >
                  <Grid
                    container
                    item
                    direction="column"
                    alignItems="center"
                    style={{ width: "100%" }}
                  >
                    <AccessTimeIcon
                      style={{ fontSize: 30, color: accessStyle.color }}
                    />
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: 550,
                        color: accessStyle.color,
                      }}
                    >
                      접속
                    </Typography>
                  </Grid>
                </IconButton>
                <IconButton
                  onClick={() => {
                    // this.props.onMakeLog( GOONOAdminLogType.VIEW_DASHBOARD );
                    this.props.onReplace(ScreenURL.DASHBOARD);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 60,
                    height: 60,
                    borderRadius: 8,
                    margin: 5,
                    backgroundColor: boardStyle.backgroundColor,
                  }}
                >
                  <Grid
                    container
                    item
                    direction="column"
                    alignItems="center"
                    style={{ width: "100%" }}
                  >
                    <EqualizerIcon
                      style={{ fontSize: 30, color: boardStyle.color }}
                    />
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: 550,
                        color: boardStyle.color,
                      }}
                    >
                      그래프
                    </Typography>
                  </Grid>
                </IconButton>
                <IconButton
                  onClick={() => {
                    // this.props.onMakeLog( GOONOAdminLogType.VIEW_DASHBOARD );
                    this.props.onReplace(ScreenURL.WORKSPACE);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 60,
                    height: 60,
                    borderRadius: 8,
                    margin: 5,
                    backgroundColor: workspaceStyle.backgroundColor,
                  }}
                >
                  <Grid
                    container
                    item
                    direction="column"
                    alignItems="center"
                    style={{ width: "100%" }}
                  >
                    <PublicRounded
                      style={{ fontSize: 30, color: workspaceStyle.color }}
                    />
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: 550,
                        color: workspaceStyle.color,
                      }}
                    >
                      W.S
                    </Typography>
                  </Grid>
                </IconButton>
              </>
            ) : (
              <Typography style={{ fontWeight: 550, fontSize: 15 }}>
                환영합니다! 오늘도 화이팅{" "}
                <span
                  role="img"
                  aria-label="writing hand"
                  style={{ marginLeft: 8 }}
                >
                  👋
                </span>
              </Typography>
            )}
          </Grid>
          <Grid xs={3} justify="flex-end" alignItems="center" container item>
            <Typography
              style={{ color: "black", fontSize: 14, fontWeight: 550 }}
            >
              {name}
            </Typography>
            <Typography style={{ color: "black", fontSize: 14 }}>
              님
              <span
                role="img"
                aria-label="writing hand"
                style={{ marginLeft: 3, marginRight: 8 }}
              >
                🌸
              </span>
            </Typography>
            <Button
              onClick={() => {
                onLogout();
              }}
              style={{ marginRight: 20 }}
            >
              <Typography style={{ color: "black", fontSize: 14 }}>
                로그아웃
              </Typography>
            </Button>
          </Grid>
        </Grid>
        {/* {( this.props.curr === "user" || this.props.curr === "story" ) && <SubHeader { ...this.props } subCurr={ this.props.curr } /> } */}
      </Grid>
    );
  }
}

export default AdminHeader;
