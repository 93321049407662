import {
  ODeleteAccount,
  ODeleteAccountSchema,
} from "@basalt-commons/api/response/user";
import { doAPIPostRequest } from "@basalt-react-commons/services/api";
import T from "@redwit-commons/utils/typecheck";

const validateODeleteTestAccount =
  T.mkValidator<ODeleteAccount>(ODeleteAccountSchema);

export const deleteTestUser = async (
  email: string
): Promise<ODeleteAccount> => {
  const obj = await doAPIPostRequest(`/user/account`, undefined, { email });
  const ret = validateODeleteTestAccount(obj);
  return ret;
};
