import { combineReducers } from "redux";
import token from "./token";
import file from "./file";
import admin from "./admin";
import log from "./log";
/**
 * root reducer
 */
const rootReducer = combineReducers({
  token,
  file,
  admin,
  log,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
