import React from "react";
import { WorkspaceWithPaymentInfo } from "@basalt-commons/global-api/object/admin";
import WorkspaceItem, { WorkspaceItemProps } from "./items/WorkspaceItem";

type WorkspaceListProps = {
  workspaces: WorkspaceWithPaymentInfo[];
} & WorkspaceItemProps;

type WorkspaceListState = {};

class WorkspaceList2 extends React.PureComponent<
  WorkspaceListProps,
  WorkspaceListState
> {
  constructor(props: WorkspaceListProps) {
    super(props);
  }

  render() {
    const { workspaces } = this.props;

    return (
      <>
        {workspaces.map((workspace) => {
          return <WorkspaceItem workspace={workspace} {...this.props} />;
        })}
      </>
    );
  }
}

export default WorkspaceList2;
