import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { TokenStateStatus, resetToken } from "../store/reducers/token";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ScreenURL from "../routes/path";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import { FutureComponent } from "@redwit-react-commons/template/FutureComponent";
import Services from "@basalt-react-commons/services";
import { delay } from "@redwit-commons/utils/function";
import ScreenLayout from "../utils/templates/ScreenLayout";
import Theme from "../styles/Theme";

const { VersionService } = Services;

const mapStateToProps = (state: RootState) => {
  return {
    token: state.token,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {};
type LoginScreenProps = PropsFromRedux & RouteComponentProps<Props>;
type LoginScreenState = {
  msg: string;
};

class LoginScreen extends FutureComponent<LoginScreenProps, LoginScreenState> {
  constructor(props: LoginScreenProps) {
    super(props);
    this.state = {
      msg: "로딩 중 ...",
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.scheduleTask(async () => {
      console.log("Loading 시작, 1초 쉬어봄");
      await delay(500);
    });
    this.scheduleTask(async () => {
      await new Promise<void>((resolve) => {
        this.setState({ msg: "서버 접속 테스트중..." }, () => resolve());
      });
      await delay(500);
      const oversion = await VersionService.checkConnection();

      await new Promise<void>((resolve) => {
        this.setState(
          { msg: "확인된 서버 버전: " + oversion.response.version },
          () => resolve()
        );
      });
    });
    this.scheduleTask(async () => {
      await delay(500);
      if (this.props.token.state.status === TokenStateStatus.SUCCESS) {
        this.props.history.replace(ScreenURL.MAIN);
      } else if (this.props.token.state.status === TokenStateStatus.INIT) {
        this.props.history.replace(ScreenURL.LOGIN);
      } else {
        resetToken(this.props.dispatch);
        this.props.history.replace(ScreenURL.LOGIN);
      }
    });
  }

  render() {
    return (
      <ScreenLayout>
        <Grid
          xs={12}
          direction="column"
          alignItems="center"
          justify="center"
          container
          item
          style={{ height: "100%", ...Theme.background_etc_color }}
        >
          <CircularProgress style={{ ...Theme.main_color }} />
          <Typography
            style={{ marginTop: 10, fontWeight: "bold", marginBottom: 20 }}
          >
            {this.state.msg}
          </Typography>
        </Grid>
      </ScreenLayout>
    );
  }
}

export default connector(withRouter(LoginScreen));
