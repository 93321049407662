import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  Grid,
  Container,
  Button,
  Typography,
  Tooltip,
  InputBase,
  IconButton,
} from "@material-ui/core";
import ScreenURL from "../routes/path";
import {
  doTokenAction,
  TokenActionKind,
  TokenStateStatus,
} from "../store/reducers/token";
import ScreenLayout from "../utils/templates/ScreenLayout";
import Theme from "../styles/Theme";
import { Alert } from "@material-ui/lab";
import Zoom from "@material-ui/core/Zoom";
import Header from "../component/pure/headers/Header";
import { doLogAction, LogActionKind } from "../store/reducers/log";
import moment from "moment";
import CalendarButton from "../component/pure/calendar/CalendarButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloseIcon from "@material-ui/icons/Close";
const mapStateToProps = (state: RootState) => {
  return {
    log: state.log,
    token: state.token,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {};
type MainScreenProps = PropsFromRedux & RouteComponentProps<Props>;
type MainScreenState = {
  alert: undefined | string;
  hover: number | undefined;
  // logTypeMode: undefined | GOONOAdminLogType,
  logEmailMode: undefined | string;
  logSearchText: string;
  logDate: Date;
};

class MainScreen extends React.Component<MainScreenProps, MainScreenState> {
  constructor(props: MainScreenProps) {
    super(props);
    this.state = {
      alert: undefined,
      hover: undefined,
      // logTypeMode: undefined,
      logEmailMode: undefined,
      logSearchText: "",
      logDate: new Date(),
    };
  }

  componentDidMount() {
    const { token, history } = this.props;
    if (token.state.status === TokenStateStatus.INIT)
      history.replace(ScreenURL.LOADING);
    doLogAction(this.props.dispatch, {
      kind: LogActionKind.TRY_GET_ADMIN_LOGS,
      afterAt: moment().startOf("day").toISOString(),
      beforeAt: moment().endOf("day").toISOString(),
    });
    return;
  }

  onLogout() {
    const { history } = this.props;
    doTokenAction(
      this.props.dispatch,
      {
        kind: TokenActionKind.TRY_LOGOUT,
      },
      () => {
        history.replace(ScreenURL.LOADING);
        return;
      },
      () => {
        this.setState({ alert: "로그아웃에 실패하였습니다" });
      }
    );
  }

  onClickBoard(screen: string) {
    const { history } = this.props;
    history.push(screen);
  }

  getHoverStyle(index: number) {
    return this.state.hover === index
      ? { marginTop: -2, boxShadow: "2px 3px 10px 2px rgba(0, 0, 0, 0.1)" }
      : { ...Theme.box_shadow, marginTop: 0 };
  }

  getColorMember(userMail?: string) {
    if (userMail === undefined) {
      if (this.state.logEmailMode === undefined) return "#212529";
      else return "#21252980";
    } else {
      if (
        this.state.logEmailMode === undefined ||
        this.state.logEmailMode === userMail
      )
        return "#212529";
      else return "#21252980";
    }
  }

  render() {
    const { token } = this.props;
    const name =
      token.state.status !== TokenStateStatus.SUCCESS
        ? "undefined"
        : token.state.name;

    return (
      <ScreenLayout alignContent="flex-start" style={Theme.background_color}>
        <Header
          onLogout={() => {
            this.onLogout();
          }}
          name={name}
          onReplace={(url) => {
            this.props.history.replace(url);
          }}
        />

        {this.state.alert !== undefined && (
          <Alert
            style={{ width: "100%" }}
            variant="filled"
            severity="error"
            onClose={() => {
              this.setState({ alert: undefined });
            }}
          >
            {this.state.alert}
          </Alert>
        )}

        <Container>
          <Grid
            xs={12}
            justify="flex-start"
            direction="row"
            alignContent="flex-start"
            alignItems="flex-start"
            container
            item
            style={{}}
          >
            <Grid
              container
              item
              style={{
                width: 300,
                padding: 10,
                marginTop: 20,
                position: "relative",
              }}
            >
              <Tooltip
                TransitionComponent={Zoom}
                title="전체 사용자/라이센스를 관리할 수 있습니다"
                placement="bottom"
                arrow
                style={{ fontSize: 16 }}
              >
                <Button
                  onMouseEnter={() => {
                    this.setState({ hover: 0 });
                  }}
                  onMouseLeave={() => {
                    this.setState({ hover: undefined });
                  }}
                  style={{
                    width: "100%",
                    height: 60,
                    margin: 10,
                    borderRadius: 10,
                    backgroundColor: "white",
                    ...this.getHoverStyle(0),
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                  onClick={() => {
                    this.onClickBoard(ScreenURL.USER_MAIN);
                  }}
                >
                  <span role="img" style={{ fontSize: 20, marginRight: 6 }}>
                    😇
                  </span>
                  <Typography style={{ fontSize: 16 }}>사용자 관리</Typography>
                </Button>
              </Tooltip>
              <Tooltip
                TransitionComponent={Zoom}
                title="baSalt 접속자에 대한 확인을 합니다"
                placement="bottom"
                arrow
                style={{ fontSize: 16 }}
              >
                <Button
                  onMouseEnter={() => {
                    this.setState({ hover: 2 });
                  }}
                  onMouseLeave={() => {
                    this.setState({ hover: undefined });
                  }}
                  onClick={() => {
                    this.props.history.push(ScreenURL.ACCESS);
                  }}
                  style={{
                    width: "100%",
                    height: 60,
                    margin: 10,
                    borderRadius: 10,
                    backgroundColor: "white",
                    ...this.getHoverStyle(2),
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                >
                  <span role="img" style={{ fontSize: 20, marginRight: 6 }}>
                    🕐
                  </span>
                  <Typography style={{ fontSize: 16 }}>접속자 확인</Typography>
                </Button>
              </Tooltip>
              <Tooltip
                TransitionComponent={Zoom}
                title="사용자들의 이용 현황을 확인 합니다"
                placement="bottom"
                arrow
                style={{ fontSize: 16 }}
              >
                <Button
                  onMouseEnter={() => {
                    this.setState({ hover: 3 });
                  }}
                  onMouseLeave={() => {
                    this.setState({ hover: undefined });
                  }}
                  onClick={() => {
                    this.onClickBoard(ScreenURL.DASHBOARD);
                  }}
                  style={{
                    width: "100%",
                    height: 60,
                    margin: 10,
                    borderRadius: 10,
                    backgroundColor: "white",
                    ...this.getHoverStyle(3),
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                >
                  <span role="img" style={{ fontSize: 20, marginRight: 6 }}>
                    📊
                  </span>
                  <Typography style={{ fontSize: 16 }}>대시보드</Typography>
                </Button>
              </Tooltip>
              <Tooltip
                TransitionComponent={Zoom}
                title="플랜 이용중인 워크스페이스들을 확인합니다"
                placement="bottom"
                arrow
                style={{ fontSize: 16 }}
              >
                <Button
                  onMouseEnter={() => {
                    this.setState({ hover: 3 });
                  }}
                  onMouseLeave={() => {
                    this.setState({ hover: undefined });
                  }}
                  onClick={() => {
                    this.onClickBoard(ScreenURL.WORKSPACE);
                  }}
                  style={{
                    width: "100%",
                    height: 60,
                    margin: 10,
                    borderRadius: 10,
                    backgroundColor: "white",
                    ...this.getHoverStyle(3),
                    justifyContent: "flex-start",
                    paddingLeft: 20,
                  }}
                >
                  <span role="img" style={{ fontSize: 20, marginRight: 6 }}>
                    ✨
                  </span>
                  <Typography style={{ fontSize: 16 }}>
                    워크스페이스 관리
                  </Typography>
                </Button>
              </Tooltip>
            </Grid>

            {/* LOG 내역 */}
            <Grid container item alignContent="flex-start" style={{ flex: 1 }}>
              <Grid
                xs={12}
                container
                item
                direction="row"
                alignItems="center"
                style={{
                  width: "100%",
                  marginTop: 20,
                  paddingLeft: 80,
                  position: "relative",
                }}
              >
                <InputBase
                  onChange={(evt) => {
                    const text = evt.target.value;
                    this.setState({ logSearchText: text });
                  }}
                  value={this.state.logSearchText}
                  placeholder="로그 검색어를 입력하세요"
                  style={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: 10,
                    width: "100%",
                    fontSize: 14,
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                />
                {this.state.logSearchText.length > 0 && (
                  <IconButton
                    onClick={() => {
                      this.setState({ logSearchText: "" });
                    }}
                    style={{
                      width: 40,
                      height: 40,
                      position: "absolute",
                      right: 10,
                    }}
                  >
                    <CloseIcon style={{ color: "#212529" }} />
                  </IconButton>
                )}
              </Grid>
              <Grid
                xs={12}
                container
                item
                style={{
                  width: "100%",
                  height: 40,
                  paddingLeft: 80,
                  marginTop: 10,
                }}
              >
                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  style={{ width: "100%", height: 40 }}
                >
                  <Button
                    onClick={() => {
                      const date = new Date(
                        moment(this.state.logDate)
                          .subtract(1, "day")
                          .toISOString()
                      );
                      this.setState({ logDate: date }, () => {
                        doLogAction(this.props.dispatch, {
                          kind: LogActionKind.TRY_GET_ADMIN_LOGS,
                          beforeAt: moment(date).endOf("day").toISOString(),
                          afterAt: moment(date).startOf("day").toISOString(),
                        });
                      });
                    }}
                  >
                    <ArrowBackIosIcon style={{ color: "#212529" }} />
                  </Button>
                  <CalendarButton
                    time_zone={moment.tz.guess()}
                    lang="ko"
                    value={this.state.logDate}
                    onClickDate={(date) => {
                      this.setState({ logDate: date }, () => {
                        doLogAction(this.props.dispatch, {
                          kind: LogActionKind.TRY_GET_ADMIN_LOGS,
                          beforeAt: moment(date).endOf("day").toISOString(),
                          afterAt: moment(date).startOf("day").toISOString(),
                        });
                      });
                    }}
                  />
                  <Button
                    onClick={() => {
                      const date = new Date(
                        moment(this.state.logDate).add(1, "day").toISOString()
                      );
                      this.setState({ logDate: date }, () => {
                        doLogAction(this.props.dispatch, {
                          kind: LogActionKind.TRY_GET_ADMIN_LOGS,
                          beforeAt: moment(date).endOf("day").toISOString(),
                          afterAt: moment(date).startOf("day").toISOString(),
                        });
                      });
                    }}
                  >
                    <ArrowForwardIosIcon style={{ color: "#212529" }} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </ScreenLayout>
    );
  }
}

export default connector(withRouter(MainScreen));
