const styles = {
  background_color: { backgroundColor: "#f8f9fa" },

  background_main_color: { backgroundColor: "#c72e45" },
  background_sub_color: { backgroundColor: "#dddfe6" },
  background_black_color: { backgroundColor: "#1e2022" },
  background_etc_color: { backgroundColor: "#f4f5f9" },

  white_color: { color: "#ffffff" },
  main_color: { color: "#c72e45" },
  sub_color: { color: "#dddfe6" },
  black_color: { color: "#1e2022" },
  etc_color: { color: "#f4f5f9" },

  box_shadow: {
    boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.04)",
  },
};

export default styles;
