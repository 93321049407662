import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Grid, Container, Typography } from "@material-ui/core";
import ScreenURL from "../routes/path";
import {
  doTokenAction,
  TokenActionKind,
  TokenStateStatus,
} from "../store/reducers/token";
import ScreenLayout from "../utils/templates/ScreenLayout";
import Theme from "../styles/Theme";
import Header from "../component/pure/headers/Header";
import moment from "moment";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import {
  AdminActionKind,
  AdminStateStatus,
  doAdminAction,
  doAdminActionAsync,
} from "../store/reducers/admin";
import { RefinedUserWithLicense } from "@basalt-commons/api/object/license";
import SimpleMenu from "../component/pure/SimpleMenu";
import { UserLogObject } from "@basalt-commons/api/object/user_log";
import { WorkspaceWithPaymentInfo } from "@basalt-commons/global-api/object/admin";
import { WorkspacePlanType } from "@basalt-commons/global-api/object/workspace_plan";

type GraphDataOption = {
  backgroundColor: string[] | string;
  borderColor: string[] | string;
  borderWidth: number;
  hoverBackgroundColor: string | string[];
  hoverBorderColor: string | string[];
  data: number[];
  fill?: boolean;
};
type GraphDataType = {
  labels: string[];
  datasets: GraphDataOption[];
};
const mapStateToProps = (state: RootState) => {
  return {
    token: state.token,
    admin: state.admin,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {};
type DashBoardScreenProps = PropsFromRedux & RouteComponentProps<Props>;
type DashBoardScreenState = {
  registerMode: "day" | "week" | "month";
  validRetensionMode: "day" | "week" | "month";
  activeMode: "all" | "visit" | "content";
  visitMode: "day" | "week" | "month";
  createdTimeMode: "hour" | "day";
  registerRateMode: "day" | "week" | "month";
  openRegisterMode: undefined | { top: number; left: number };
};

class DashBoardScreen extends React.Component<
  DashBoardScreenProps,
  DashBoardScreenState
> {
  constructor(props: DashBoardScreenProps) {
    super(props);
    this.state = {
      registerMode: "day",
      validRetensionMode: "week",
      activeMode: "all",
      visitMode: "day",
      createdTimeMode: "hour",
      registerRateMode: "day",
      openRegisterMode: undefined,
    };
  }

  async componentDidMount() {
    const { token, history, admin } = this.props;
    if (token.state.status === TokenStateStatus.INIT)
      history.replace(ScreenURL.LOADING);
    if (admin.state.status !== AdminStateStatus.SUCCESS) {
      await doAdminActionAsync(this.props.dispatch, {
        kind: AdminActionKind.TRY_GET_ALL_USERS,
      });
      await doAdminActionAsync(this.props.dispatch, {
        kind: AdminActionKind.TRY_GET_ALL_WORKSPACES,
      });
      await doAdminActionAsync(this.props.dispatch, {
        kind: AdminActionKind.TRY_GET_INFO,
        afterAt: moment().subtract(4, "months").toISOString(),
      });
    } else {
      doAdminAction(this.props.dispatch, {
        kind: AdminActionKind.TRY_GET_INFO,
        afterAt: moment().subtract(4, "months").toISOString(),
      });
    }
  }

  onLogout() {
    const { history } = this.props;
    doTokenAction(
      this.props.dispatch,
      {
        kind: TokenActionKind.TRY_LOGOUT,
      },
      () => {
        history.replace(ScreenURL.LOADING);
        return;
      },
      () => {
        alert("로그아웃 실패하였습니다");
      }
    );
  }

  onBack() {
    this.props.history.goBack();
  }

  makeGenderDate(users: RefinedUserWithLicense[]): GraphDataType {
    let male: RefinedUserWithLicense[] = [];
    let female: RefinedUserWithLicense[] = [];
    let unknown: RefinedUserWithLicense[] = [];

    for (const user of users) {
      if (user.gender === "male") male.push(user);
      else if (user.gender === "female") female.push(user);
      else unknown.push(user);
    }
    const genderColor = ["#ff7473", "#379392", "#dddfe6"];
    const genderHoverColor = ["#ff747380", "#37939280", "#dddfe680"];
    const genderData: GraphDataType = {
      labels: ["여성", "남성", "표시 하지 않음"],
      datasets: [
        {
          backgroundColor: genderColor,
          borderColor: genderColor,
          hoverBackgroundColor: genderHoverColor,
          hoverBorderColor: genderHoverColor,
          borderWidth: 1,
          data: [female.length, male.length, unknown.length],
        },
      ],
    };
    return genderData;
  }

  makeLicenseData(users: RefinedUserWithLicense[]) {
    let wLicense: RefinedUserWithLicense[] = [];
    let woLicense: RefinedUserWithLicense[] = [];

    for (const user of users) {
      const licenses = user.Licenses;
      let hasLicense = false;
      for (const ul of licenses) {
        if (moment(ul.end) > moment() && ul.description.includes("유료")) {
          hasLicense = true;
          wLicense.push(user);
          break;
        }
      }
      if (hasLicense === false) woLicense.push(user);
    }
    const licenseColor = ["#fdc23e", "#dddfe6"];
    const licenseHoverColor = ["#fdc23e80", "#dddfe680"];
    const licenseData: GraphDataType = {
      labels: ["유료 회원", "무료 회원"],
      datasets: [
        {
          backgroundColor: licenseColor,
          borderColor: licenseColor,
          hoverBackgroundColor: licenseHoverColor,
          hoverBorderColor: licenseHoverColor,
          borderWidth: 1,
          data: [wLicense.length, woLicense.length],
        },
      ],
    };
    return licenseData;
  }

  convertWeekOfMonth(m: moment.Moment, year_opt?: boolean) {
    const week = m.week() - m.startOf("month").week() + 1;
    const year = m.format("YYYY");
    const month = m.format("MM");
    if (m.day(7).format("MM") !== m.day(1).format("MM") && week > 4) {
      if (year_opt === false) return `${m.day(6).format("MM")}월 1주차`;
      return `${year}년 ${m.day(6).format("MM")}월 1주차`;
    }
    if (week < 0 && month === "12")
      return `${year}년 ${m.day(6).format("MM")}월 5주차`;
    if (year_opt === false) return `${month}월 ${week}주차`;
    return `${year}년 ${month}월 ${week}주차`;
  }

  getDataLabel(
    column: number,
    unit: "days" | "months" | "weeks",
    format: string
  ) {
    let label = [];
    for (let i = 0; i < column; i++) {
      if (unit === "weeks")
        label[column - 1 - i] = this.convertWeekOfMonth(moment().add(-i, unit));
      else label[column - 1 - i] = moment().add(-i, unit).format(format);
    }
    return label;
  }

  makePlatformData(users: RefinedUserWithLicense[]) {
    let kakaoUsers: RefinedUserWithLicense[] = [];
    let googleUsers: RefinedUserWithLicense[] = [];
    let appleUsers: RefinedUserWithLicense[] = [];
    let emailUsers: RefinedUserWithLicense[] = [];
    for (const user of users) {
      if (user.platform === "kakao") kakaoUsers.push(user);
      else if (user.platform === "google") googleUsers.push(user);
      else if (user.platform === "apple") appleUsers.push(user);
      else emailUsers.push(user);
    }

    const platformColor = ["#EFDC05", "#E53A40", "#1F2124", "#E0E3DA"];
    const platformHoverColor = [
      "#EFDC0580",
      "#E53A4080",
      "#1F212480",
      "#E0E3DA80",
    ];
    const platfomrData: GraphDataType = {
      labels: ["Kakao", "Google", "Apple", "Email"],
      datasets: [
        {
          backgroundColor: platformColor,
          borderColor: platformColor,
          hoverBackgroundColor: platformHoverColor,
          hoverBorderColor: platformHoverColor,
          borderWidth: 1,
          data: [
            kakaoUsers.length,
            googleUsers.length,
            appleUsers.length,
            emailUsers.length,
          ],
        },
      ],
    };
    return platfomrData;
  }

  makeRegisterRateData(users: RefinedUserWithLicense[]) {
    const { registerRateMode } = this.state;
    let format = "YYYY-MM-DD";
    let labelData = this.getDataLabel(30, "days", format);
    if (registerRateMode === "day") {
      format = "YYYY-MM-DD";
      labelData = this.getDataLabel(30, "days", format);
    } else if (registerRateMode === "month") {
      format = "YYYY-MM";
      labelData = this.getDataLabel(12, "months", format);
    } else if (registerRateMode === "week") {
      format = "YYYY-MM-DD";
      labelData = this.getDataLabel(12, "weeks", format);
    }

    let createData = labelData.map((_) => 0);
    const createdAts = users.map((user) => {
      return registerRateMode !== "week"
        ? moment(user.createdAt).format(format)
        : this.convertWeekOfMonth(moment(user.createdAt));
    });
    for (const createdAt of createdAts) {
      labelData.map((label, index) => {
        if (label === createdAt) {
          createData[index] = createData[index] + 1;
        }
      });
    }
    const checkRate = createData.map((d, i) => {
      if (i > 0) {
        const prevState = createData[i - 1];
        if (prevState === 0) return 0;
        const data = ((d - prevState) / prevState) * 100;
        return data;
      } else return 0;
    });
    const Data: GraphDataType = {
      labels: labelData,
      datasets: [
        {
          backgroundColor: "#fdc23e",
          borderColor: "#fdc23e",
          hoverBackgroundColor: "#ff776180",
          hoverBorderColor: "#ff776180",
          borderWidth: 3,
          data: checkRate,
          fill: false,
        },
      ],
    };
    return Data;
  }

  makeGetPlatformData(visitLog?: UserLogObject[]) {
    const logs = visitLog === undefined ? [] : visitLog;
    const platformLabel = ["ios", "android", "web"];
    let browserLabel: string[] = [];
    let deviceLabel: string[] = [
      "universal",
      "sdm",
      "iPhone",
      "exynos",
      "iPad",
      "kona",
      "merlin",
      "msmnile",
      "msm",
      "mt",
      "lito",
    ];
    let platformData = platformLabel.map((_) => 0);
    let browserData: number[] = [];
    let deviceData: number[] = deviceLabel.map((_) => 0);
    let totalWeb = 0;
    let totalApp = 0;

    for (const log of logs) {
      platformLabel.map((label, index) => {
        if (label === log.platformOS)
          platformData[index] = platformData[index] + 1;
      });
      const deviceInfo = log.device === undefined ? "unknown" : log.device;
      if (log.platformOS === "web") {
        totalWeb = totalWeb + 1;
        const labelIndex = browserLabel.findIndex((lbl) => {
          return lbl === deviceInfo;
        });
        if (labelIndex < 0) {
          browserLabel.push(deviceInfo);
          browserData.push(1);
        }
        browserData[labelIndex] = browserData[labelIndex] + 1;
      } else if (log.platformOS !== undefined) {
        totalApp = totalApp + 1;
        const labelIndex = deviceLabel.findIndex((lbl) => {
          if (deviceInfo.includes(lbl) === true) {
            return true;
          }
          return lbl === deviceInfo;
        });
        if (labelIndex < 0) {
          deviceLabel = [...deviceLabel, deviceInfo];
          deviceData.push(1);
        } else {
          deviceData[labelIndex] = deviceData[labelIndex] + 1;
        }
      }
    }
    const platformRateData = platformData.map((pd) => (pd / logs.length) * 100);
    const browserRateData = browserData.map((bd) => (bd / totalWeb) * 100);
    const deviceRateData = deviceData.map((dd) => (dd / totalApp) * 100);
    const platformColor = ["#ff7473", "#379392", "#dddfe6"];
    const platformHoverColor = ["#ff747380", "#37939280", "#dddfe680"];
    const PlatformGraphData: GraphDataType = {
      labels: platformLabel,
      datasets: [
        {
          backgroundColor: platformColor,
          data: platformRateData,
          borderColor: platformColor,
          hoverBackgroundColor: platformHoverColor,
          hoverBorderColor: platformHoverColor,
          borderWidth: 1,
        },
      ],
    };
    const browserColor = [
      "#AAB59E",
      "#D48155",
      "#F6C552",
      "#9FAFCO",
      "#F3C568",
      "#D7CD6A",
      "#D8D6D1",
      "#D9CCB9",
      "#E3D7D1",
      "#D9BFA0",
      "#D4CBC4",
      "#C8A27A",
    ];
    const browserHoverColor = [
      "#AAB59E80",
      "#D4815580",
      "#F6C55280",
      "#9FAFCO80",
      "#F3C56880",
      "#D7CD6A80",
      "#D8D6D180",
      "#D9CCB980",
      "#E3D7D180",
      "#D9BFA080",
      "#D4CBC480",
      "#C8A27A80",
    ];
    const BrowserGraphData: GraphDataType = {
      labels: browserLabel,
      datasets: [
        {
          backgroundColor: browserColor,
          borderColor: browserColor,
          hoverBackgroundColor: browserHoverColor,
          hoverBorderColor: browserHoverColor,
          data: browserRateData,
          borderWidth: 1,
        },
      ],
    };

    const DeviceGraphData: GraphDataType = {
      labels: deviceLabel,
      datasets: [
        {
          backgroundColor: browserColor,
          borderColor: browserColor,
          hoverBackgroundColor: browserHoverColor,
          hoverBorderColor: browserHoverColor,
          data: deviceRateData,
          borderWidth: 1,
        },
      ],
    };

    return { PlatformGraphData, BrowserGraphData, DeviceGraphData };
  }

  makeVersionData(total: number, visitLogs?: UserLogObject[]) {
    let versionTable: string[] = [];
    let versionData: number[] = [];
    let userIds: string[] = [];
    const logs = visitLogs === undefined ? [] : visitLogs;
    let logTotal = 0;
    logs.map((info) => {
      if (
        info.UserId !== undefined &&
        userIds.find((u) => u === info.UserId) === undefined
      ) {
        userIds.push(info.UserId);
        if (info.app_version !== undefined) {
          logTotal += 1;
          const findVersionIndex = versionTable.findIndex(
            (lbl) => lbl === info.app_version
          );
          if (findVersionIndex < 0) {
            versionTable.push(info.app_version);
            versionData.push(1);
          } else {
            versionData[findVersionIndex] = versionData[findVersionIndex] + 1;
          }
        }
      }
    });
    versionTable.push("확인되지 않음");
    versionData.push(total - logTotal);
    const versionColor = [
      "#AAB59E",
      "#D48155",
      "#F6C552",
      "#9FAFCO",
      "#F3C568",
      "#D7CD6A",
      "#D8D6D1",
      "#D9CCB9",
      "#E3D7D1",
      "#D9BFA0",
      "#D4CBC4",
      "#C8A27A",
    ];
    const versionHoverColor = [
      "#AAB59E80",
      "#D4815580",
      "#F6C55280",
      "#9FAFCO80",
      "#F3C56880",
      "#D7CD6A80",
      "#D8D6D180",
      "#D9CCB980",
      "#E3D7D180",
      "#D9BFA080",
      "#D4CBC480",
      "#C8A27A80",
    ];
    const VersionGraphData: GraphDataType = {
      labels: versionTable,
      datasets: [
        {
          backgroundColor: versionColor,
          borderColor: versionColor,
          hoverBackgroundColor: versionHoverColor,
          hoverBorderColor: versionHoverColor,
          data: versionData,
          borderWidth: 1,
        },
      ],
    };
    return VersionGraphData;
  }

  makeVisitData(mode: "day" | "week" | "month", visitLogs?: UserLogObject[]) {
    const logs = visitLogs === undefined ? [] : visitLogs;

    let format = "YYYY-MM-DD";
    let labelData = this.getDataLabel(30, "days", format);
    if (mode === "day") {
      format = "YYYY-MM-DD";
      labelData = this.getDataLabel(30, "days", format);
    } else if (mode === "month") {
      format = "YYYY-MM";
      labelData = this.getDataLabel(12, "months", format);
    } else if (mode === "week") {
      format = "YYYY-MM-DD";
      labelData = this.getDataLabel(12, "weeks", format);
    }
    let visitData = labelData.map((_) => 0);
    let userIds: string[] = [];
    logs.map((info) => {
      if (
        info.UserId !== undefined &&
        userIds.find((u) => u === info.UserId) === undefined
      ) {
        userIds.push(info.UserId);
        const createAt =
          mode === "week"
            ? this.convertWeekOfMonth(moment(info.createdAt))
            : moment(info.createdAt).format(format);
        const findIndex = labelData.findIndex((l) => createAt === l);
        visitData[findIndex] = visitData[findIndex] + 1;
      }
    });
    return { visitData };
  }

  makeVisitUsers(visitLogs?: UserLogObject[]) {
    const infos = visitLogs === undefined ? [] : visitLogs;

    const { visitMode } = this.state;
    let format = "YYYY-MM-DD";
    let labelData = this.getDataLabel(30, "days", format);
    if (visitMode === "day") {
      format = "YYYY-MM-DD";
      labelData = this.getDataLabel(30, "days", format);
    } else if (visitMode === "month") {
      format = "YYYY-MM";
      labelData = this.getDataLabel(12, "months", format);
    } else if (visitMode === "week") {
      format = "YYYY-MM-DD";
      labelData = this.getDataLabel(12, "weeks", format);
    }
    const { visitData } = this.makeVisitData(this.state.visitMode, infos);

    const Data: GraphDataType = {
      labels: labelData,
      datasets: [
        {
          backgroundColor: "#3578ea",
          borderColor: "#3578ea",
          hoverBackgroundColor: "#3578ea80",
          hoverBorderColor: "#3578ea80",
          borderWidth: 3,
          data: visitData,
          fill: false,
        },
      ],
    };
    return Data;
  }

  makeGetActiveUserData(
    total: number,
    userInfos?: UserLogObject[],
    visitLog?: UserLogObject[],
    contentLog?: UserLogObject[]
  ) {
    const infos = userInfos === undefined ? [] : userInfos;
    const visitInfos = visitLog === undefined ? [] : visitLog;
    const format = "YYYY-MM-DD";
    const labelData: string[] = this.getDataLabel(12, "weeks", format);
    let userIdList: string[] = [];
    let userData = labelData.map((_) => 0);
    const { visitData } = this.makeVisitData("week", visitInfos);
    const contentData = this.makeVisitData("week", contentLog).visitData;
    for (const info of infos) {
      const date = this.convertWeekOfMonth(moment(info.createdAt));
      labelData.map((label, index) => {
        if (label === date) {
          if (userIdList.find((u) => u === info.UserId)) return;
          if (info.UserId !== undefined) {
            userIdList.push(info.UserId);
            userData[index] = userData[index] + 1;
          }
        }
      });
    }
    const data = userData.map((d, index) => {
      if (this.state.activeMode === "all") return (d / total) * 100;
      else if (this.state.activeMode === "content")
        return (d / contentData[index]) * 100;
      else return (d / visitData[index]) * 100;
    });
    const Data: GraphDataType = {
      labels: labelData,
      datasets: [
        {
          backgroundColor: "#ff7761",
          borderColor: "#ff7761",
          hoverBackgroundColor: "#ff776180",
          hoverBorderColor: "#ff776180",
          borderWidth: 3,
          data: data,
          fill: false,
        },
      ],
    };
    return Data;
  }

  makeTimeCreateData(users: RefinedUserWithLicense[]) {
    const { createdTimeMode } = this.state;
    let labelData: string[] = [];
    let format = "";
    if (createdTimeMode === "hour") {
      format = "HH";
      for (let i = 0; i < 24; i++) {
        labelData.push(`${i}`);
      }
    } else {
      labelData = ["일", "월", "화", "수", "목", "금", "토"];
    }

    let userData = labelData.map((_) => 0);
    for (const user of users) {
      const createdAt =
        createdTimeMode === "day"
          ? labelData[moment(user.createdAt).day()]
          : moment(user.createdAt).format(format);
      labelData.map((label, index) => {
        if (label === createdAt) {
          userData[index] = userData[index] + 1;
        }
      });
    }

    const Data: GraphDataType = {
      labels: labelData,
      datasets: [
        {
          backgroundColor: "#379392",
          borderColor: "#379392",
          hoverBackgroundColor: "#37939280",
          hoverBorderColor: "#37939280",
          borderWidth: 3,
          data: userData,
          fill: false,
        },
      ],
    };
    return Data;
  }

  makeNoteUploadData(info?: UserLogObject[]) {
    const uploadData = info === undefined ? [] : info;
    const labelData = ["갤러리", "카메라", "파일"];
    let data = labelData.map((_) => 0);
    let total = 0;
    for (const upload of uploadData) {
      if (upload.uploadType !== undefined) total = total + 1;
      if (upload.uploadType === "gallery") data[0] = data[0] + 1;
      else if (upload.uploadType === "camera") data[1] = data[1] + 1;
      else if (upload.uploadType === "file") data[2] = data[2] + 1;
    }

    const uploadBackgroundColor = ["#ff7473", "#379392", "#dddfe6"];
    const uploadHoverColor = ["#ff747380", "#37939280", "#dddfe680"];
    const Data: GraphDataType = {
      labels: labelData,
      datasets: [
        {
          data: data.map((d) => (d / total) * 100),
          backgroundColor: uploadBackgroundColor,
          borderColor: uploadBackgroundColor,
          hoverBackgroundColor: uploadHoverColor,
          hoverBorderColor: uploadHoverColor,
          borderWidth: 1,
          fill: false,
        },
      ],
    };
    return Data;
  }

  makeValidRetentsionData(
    users: RefinedUserWithLicense[],
    visitLogs?: UserLogObject[]
  ) {
    const logs = visitLogs === undefined ? [] : visitLogs;
    const { validRetensionMode } = this.state;
    let labelData: string[] = [];
    let format = "";

    if (validRetensionMode === "day") {
      format = "YYYY-MM-DD";
      labelData = this.getDataLabel(30, "days", format);
    } else if (validRetensionMode === "month") {
      format = "YYYY-MM";
      labelData = this.getDataLabel(12, "months", format);
    } else if (validRetensionMode === "week") {
      format = "YYYY-MM-DD";
      labelData = this.getDataLabel(12, "weeks", format);
    }

    let registerData = labelData.map((_) => 0);
    let visitData = labelData.map((_) => 0);

    for (const user of users) {
      const targetLogs = logs.filter((log) => log.UserId === user.id);
      const target = targetLogs.find(
        (log) =>
          moment(log.createdAt).isAfter(user.createdAt) &&
          moment(log.createdAt).isSameOrBefore(
            moment(user.createdAt).add(14, "days")
          )
      );
      const registerLabel =
        validRetensionMode !== "week"
          ? moment(user.createdAt).add(7, "days").format(format)
          : this.convertWeekOfMonth(moment(user.createdAt).add(7, "days"));
      const findIndex = labelData.findIndex((label) => label === registerLabel);
      if (findIndex >= 0) registerData[findIndex] = registerData[findIndex] + 1;
      if (target !== undefined) {
        const targetLabel =
          validRetensionMode !== "week"
            ? moment(target.createdAt).format(format)
            : this.convertWeekOfMonth(moment(target.createdAt));
        const findIndex = labelData.findIndex((label) => label === targetLabel);
        if (findIndex >= 0) visitData[findIndex] = visitData[findIndex] + 1;
      }
    }

    const data = registerData.map((rd, index) => (visitData[index] / rd) * 100);

    const Data: GraphDataType = {
      labels: labelData,
      datasets: [
        {
          backgroundColor: "#339af0",
          borderColor: "#339af0",
          hoverBackgroundColor: "#339af080",
          hoverBorderColor: "#339af080",
          borderWidth: 3,
          data: data,
          fill: false,
        },
      ],
    };
    return Data;
  }

  makeRegisterData(users: RefinedUserWithLicense[]) {
    const { registerMode } = this.state;
    let labelData: string[] = [];
    let format = "";

    if (registerMode === "day") {
      format = "YYYY-MM-DD";
      labelData = this.getDataLabel(30, "days", format);
    } else if (registerMode === "month") {
      format = "YYYY-MM";
      labelData = this.getDataLabel(12, "months", format);
    } else if (registerMode === "week") {
      format = "YYYY-MM-DD";
      labelData = this.getDataLabel(12, "weeks", format);
    }

    let userData = labelData.map((_) => 0);

    for (const user of users) {
      const createdAt =
        registerMode !== "week"
          ? moment(user.createdAt).format(format)
          : this.convertWeekOfMonth(moment(user.createdAt));
      labelData.map((label, index) => {
        if (label === createdAt) {
          userData[index] = userData[index] + 1;
        }
      });
    }

    const Data: GraphDataType = {
      labels: labelData,
      datasets: [
        {
          backgroundColor: "#339af0",
          borderColor: "#339af0",
          hoverBackgroundColor: "#339af080",
          hoverBorderColor: "#339af080",
          borderWidth: 3,
          data: userData,
          fill: false,
        },
      ],
    };
    return Data;
  }

  getRegisterTitle() {
    switch (this.state.registerMode) {
      case "day":
        return "(일별)";
      case "month":
        return "(월별)";
      case "week":
        return "(주별)";
    }
  }

  getRegisterRateTitle() {
    switch (this.state.registerRateMode) {
      case "day":
        return "(일별)";
      case "month":
        return "(월별)";
      case "week":
        return "(주별)";
    }
  }

  makeExtensionsInfo(info?: string[]) {
    const logs = info === undefined ? [] : info;
    let labelData: string[] = [];
    let data: number[] = [];

    for (const log of logs) {
      // todo
      const index = labelData.findIndex((l) => l === log);
      if (index < 0) {
        labelData.push(log);
        data.push(1);
      } else {
        data[index] = data[index] + 1;
      }
    }

    const extensionColor = [
      "#AAB59E",
      "#D48155",
      "#F6C552",
      "#9FAFCO",
      "#F3C568",
      "#D7CD6A",
      "#D8D6D1",
      "#D9CCB9",
      "#E3D7D1",
      "#D9BFA0",
      "#D4CBC4",
      "#C8A27A",
    ];
    const extensionHoverColor = [
      "#AAB59E80",
      "#D4815580",
      "#F6C55280",
      "#9FAFCO80",
      "#F3C56880",
      "#D7CD6A80",
      "#D8D6D180",
      "#D9CCB980",
      "#E3D7D180",
      "#D9BFA080",
      "#D4CBC480",
      "#C8A27A80",
    ];
    const Data: GraphDataType = {
      labels: labelData,
      datasets: [
        {
          backgroundColor: extensionColor,
          borderColor: extensionColor,
          hoverBackgroundColor: extensionHoverColor,
          hoverBorderColor: extensionHoverColor,
          data: data,
          borderWidth: 1,
        },
      ],
    };

    return Data;
  }

  makeUserSignData(user: RefinedUserWithLicense[]) {
    const labelData = ["서명 함", "서명 안함"];
    let data = labelData.map((_) => 0);
    user.map((u) => {
      if (u.user_sign_cid === undefined) data[1] = data[1] + 1;
      else data[0] = data[0] + 1;
    });

    const backgroundColor = ["#7251e1", "#dddfe6"];
    const hoverColor = ["#7251e180", "#dddfe680"];
    const Data: GraphDataType = {
      labels: labelData,
      datasets: [
        {
          backgroundColor: backgroundColor,
          borderColor: backgroundColor,
          hoverBackgroundColor: hoverColor,
          hoverBorderColor: hoverColor,
          data: data,
          borderWidth: 1,
        },
      ],
    };
    return Data;
  }

  makePDFLog(total: number, pdfLog?: UserLogObject[]) {
    const logs = pdfLog === undefined ? [] : pdfLog;
    const labelData = ["다운받은 사용자", "다운받은 적 없는 사용자"];
    let data = labelData.map((_) => 0);
    let userData: string[] = [];
    for (const log of logs) {
      if (log.UserId !== undefined) {
        if (userData.find((d) => d === log.UserId) === undefined) {
          userData.push(log.UserId);
          data[0] = data[0] + 1;
        }
      }
    }
    data[1] = total - data[0];
    const backgroundColor = ["#1860dc", "#dddfe6"];
    const hoverColor = ["#1860dc80", "#dddfe680"];
    const Data: GraphDataType = {
      labels: labelData,
      datasets: [
        {
          backgroundColor: backgroundColor,
          borderColor: backgroundColor,
          hoverBackgroundColor: hoverColor,
          hoverBorderColor: hoverColor,
          data: data,
          borderWidth: 1,
        },
      ],
    };
    return Data;
  }

  makeActivePerPerson(
    total: number,
    info?: UserLogObject[],
    visitLog?: UserLogObject[],
    pdfLog?: UserLogObject[],
    noteLog?: UserLogObject[],
    linkLog?: UserLogObject[]
  ) {
    const pdfLogs = pdfLog === undefined ? [] : pdfLog;
    const noteLogs = noteLog === undefined ? [] : noteLog;
    const linkLogs = linkLog === undefined ? [] : linkLog;

    let pdfUserIds: string[] = [];
    let pdfDatas: number[] = [];
    let noteUserIds: string[] = [];
    let noteDatas: number[] = [];

    let linkUserIds: string[] = [];
    let linkDatas: number[] = [];

    pdfLogs.map((log) => {
      if (log.UserId !== undefined) {
        const userFindIndex = pdfUserIds.findIndex((u) => u === log.UserId);
        if (userFindIndex > 0) {
          pdfDatas[userFindIndex] = pdfDatas[userFindIndex] + 1;
        } else {
          pdfUserIds.push(log.UserId);
          pdfDatas.push(1);
        }
      }
    });
    noteLogs.map((log) => {
      if (log.UserId !== undefined) {
        const userFindIndex = noteUserIds.findIndex((u) => u === log.UserId);
        if (userFindIndex > 0) {
          noteDatas[userFindIndex] = noteDatas[userFindIndex] + 1;
        } else {
          noteUserIds.push(log.UserId);
          noteDatas.push(1);
        }
      }
    });
    linkLogs.map((log) => {
      if (log.UserId !== undefined) {
        const userFindIndex = linkUserIds.findIndex((u) => u === log.UserId);
        if (userFindIndex > 0) {
          linkDatas[userFindIndex] = linkDatas[userFindIndex] + 1;
        } else {
          linkUserIds.push(log.UserId);
          linkDatas.push(1);
        }
      }
    });
    let activeUsers: string[] = [];
    const actionUser = info === undefined ? [] : info;
    actionUser.map((u) => {
      if (
        u.UserId !== undefined &&
        activeUsers.find((au) => au === u.UserId) === undefined
      )
        activeUsers.push(u.UserId);
    });
    let visitUsers: string[] = [];
    const visitLogs = visitLog === undefined ? [] : visitLog;
    visitLogs.map((u) => {
      if (
        u.UserId !== undefined &&
        visitUsers.find((au) => au === u.UserId) === undefined
      )
        visitUsers.push(u.UserId);
    });
    const totalPDFRate = pdfLogs.length / total;
    const perPDFRate = pdfLogs.length / pdfUserIds.length;
    const activePDFRate = pdfLogs.length / activeUsers.length;
    const visitPDFRate = pdfLogs.length / visitUsers.length;

    const totalNoteRate = noteLogs.length / total;
    const perNoteRate = noteLogs.length / noteUserIds.length;
    const activeNoteRate = noteLogs.length / activeUsers.length;
    const visitNoteRate = noteLogs.length / visitUsers.length;

    const totalLinkRate = linkLogs.length / total;
    const perLinkRate = linkLogs.length / noteUserIds.length;
    const activeLinkRate = linkLogs.length / activeUsers.length;
    const visitLinkRate = linkLogs.length / visitUsers.length;

    const PDFBackgroundColor = ["#1450b8", "#3578ea", "#598fee", "#7ea8f1"];
    const PDFHoverColor = ["#1450b880", "#3578ea80", "#598fee80", "#7ea8f180"];
    const PDFData: GraphDataType = {
      labels: [
        "전체 사용자 대비",
        "활성 사용자 대비",
        "방문자 대비",
        "PDF 사용자 대비",
      ],
      datasets: [
        {
          backgroundColor: PDFBackgroundColor,
          borderColor: PDFBackgroundColor,
          hoverBackgroundColor: PDFHoverColor,
          hoverBorderColor: PDFHoverColor,
          data: [totalPDFRate, activePDFRate, visitPDFRate, perPDFRate],
          borderWidth: 1,
        },
      ],
    };
    const NoteBackgroundColor = ["#7251e1", "#7251e1", "#886be6", "#c7b9f3"];
    const NoteHoverColor = ["#7251e180", "#7251e180", "#886be680", "#c7b9f380"];
    const NoteData: GraphDataType = {
      labels: [
        "전체 사용자 대비",
        "활성 사용자 대비",
        "방문자 대비",
        "전체 노트 업로드 대비",
      ],
      datasets: [
        {
          backgroundColor: NoteBackgroundColor,
          borderColor: NoteBackgroundColor,
          hoverBackgroundColor: NoteHoverColor,
          hoverBorderColor: NoteHoverColor,
          data: [totalNoteRate, activeNoteRate, visitNoteRate, perNoteRate],
          borderWidth: 1,
        },
      ],
    };

    const LinkBackgroundColor = ["#7251e1", "#7251e1", "#886be6", "#c7b9f3"];
    const LinkHoverColor = ["#7251e180", "#7251e180", "#886be680", "#c7b9f380"];
    const LinkData: GraphDataType = {
      labels: [
        "전체 사용자 대비",
        "활성 사용자 대비",
        "방문자 대비",
        "전체 노트 업로드 대비",
      ],
      datasets: [
        {
          backgroundColor: LinkBackgroundColor,
          borderColor: LinkBackgroundColor,
          hoverBackgroundColor: LinkHoverColor,
          hoverBorderColor: LinkHoverColor,
          data: [totalLinkRate, perLinkRate, activeLinkRate, visitLinkRate],
          borderWidth: 1,
        },
      ],
    };

    return { PDFData, NoteData, LinkData };
  }

  makeTimezoneData(users: RefinedUserWithLicense[]) {
    const timezones = users.map((user) => user.time_zone);
    let timezone_list = ["unknown"];
    let timezone_data = [0];
    timezones.map((zone) => {
      if (zone === undefined) {
        timezone_data[0] = timezone_data[0] + 1;
      } else {
        const findIndex = timezone_list.findIndex((tz) => tz === zone);
        if (findIndex > 0) {
          timezone_data[findIndex] = timezone_data[findIndex] + 1;
        } else {
          timezone_list = [...timezone_list, zone];
          timezone_data = [...timezone_data, 1];
        }
      }
    });
    const TimeZoneData: GraphDataType = {
      labels: timezone_list,
      datasets: [
        {
          backgroundColor: "#7251e1",
          borderColor: "#7251e1",
          hoverBackgroundColor: "#7251e180",
          hoverBorderColor: "#7251e180",
          data: timezone_data,
          borderWidth: 1,
        },
      ],
    };
    return TimeZoneData;
  }

  getAvgUsersInWorkspaces(workspaces: WorkspaceWithPaymentInfo[]) {
    let totals = 0;
    workspaces.map((workspace) => {
      totals = totals + workspace.Users.length;
    });
    return (totals / workspaces.length).toFixed(1);
  }

  getWorkspacePlanRate(workspaces: WorkspaceWithPaymentInfo[]) {
    const labelData = ["No Plan", "TRIAL", "INDIVIDUAL", "TEAM", "Enterprise"];
    let data = labelData.map((_) => 0);
    workspaces.map((workspace) => {
      if (workspace.WorkspacePlan?.plan === WorkspacePlanType.INDIVIDUAL)
        data[2] = data[2] + 1;
      else if (workspace.WorkspacePlan?.plan === WorkspacePlanType.TRIAL)
        data[1] = data[1] + 1;
      else if (workspace.WorkspacePlan?.plan === WorkspacePlanType.TEAM)
        data[3] = data[2] + 1;
      else if (workspace.WorkspacePlan?.plan === WorkspacePlanType.ENTERPRISE)
        data[4] = data[3] + 1;
      else data[0] = data[0] + 1;
    });

    const backgroundColor = [
      "#e9ecef",
      "#ff922b",
      "#ffe066",
      "#c0eb75",
      "#63e6be",
    ];
    const hoverColor = [
      "#e9ecef80",
      "#ff922b80",
      "#ffe06680",
      "#c0eb7580",
      "#63e6be80",
    ];
    const Data: GraphDataType = {
      labels: labelData,
      datasets: [
        {
          backgroundColor: backgroundColor,
          borderColor: backgroundColor,
          hoverBackgroundColor: hoverColor,
          hoverBorderColor: hoverColor,
          data: data,
          borderWidth: 1,
        },
      ],
    };
    return Data;
  }

  render() {
    const { token, admin } = this.props;
    const name =
      token.state.status !== TokenStateStatus.SUCCESS
        ? "undefined"
        : token.state.name;
    const users =
      admin.state.status !== AdminStateStatus.SUCCESS ? [] : admin.state.users;
    const workspaces =
      admin.state.status !== AdminStateStatus.SUCCESS
        ? []
        : admin.state.workspaces;

    const info =
      admin.state.status !== AdminStateStatus.SUCCESS
        ? undefined
        : admin.state.info;
    const visitLogs =
      admin.state.status !== AdminStateStatus.SUCCESS
        ? undefined
        : admin.state.visitLog;
    const extendsionsLog =
      admin.state.status !== AdminStateStatus.SUCCESS
        ? undefined
        : admin.state.extensionInfo;
    const pdfLog =
      admin.state.status !== AdminStateStatus.SUCCESS
        ? undefined
        : admin.state.pdfInfo;
    const linkLog =
      admin.state.status !== AdminStateStatus.SUCCESS
        ? undefined
        : admin.state.linkInfo;
    const noteLog =
      admin.state.status !== AdminStateStatus.SUCCESS
        ? undefined
        : admin.state.noteInfo;
    // const licenseData = this.makeLicenseData( users );
    const registerData = this.makeRegisterData(users);
    const registerTitle = this.getRegisterTitle();
    // const registerRateTitle = this.getRegisterRateTitle();
    // const platformData = this.makePlatformData( users );
    // const regsiterRateData = this.makeRegisterRateData( users );
    const activeUserData = this.makeGetActiveUserData(
      users.length,
      info,
      visitLogs
    );
    // const createdTimeData = this.makeTimeCreateData( users );
    // const { PlatformGraphData, BrowserGraphData } = this.makeGetPlatformData( visitLogs );
    const { BrowserGraphData } = this.makeGetPlatformData(visitLogs);
    // const uploadData = this.makeNoteUploadData( info );
    const extensionData = this.makeExtensionsInfo(extendsionsLog);
    // const userSignData = this.makeUserSignData( users );
    // const pdfData = this.makePDFLog( users.length, pdfLog );
    // const visitData = this.makeVisitUsers( visitLogs );
    const { PDFData, NoteData, LinkData } = this.makeActivePerPerson(
      users.length,
      info,
      visitLogs,
      pdfLog,
      noteLog,
      linkLog
    );
    const timeZoneData = this.makeTimezoneData(users);
    const workspacePlanRate = this.getWorkspacePlanRate(workspaces);
    const validRetensiondata = this.makeValidRetentsionData(users, visitLogs);
    // const checkVersion = this.makeVersionData( users.length, visitLogs );

    return (
      <ScreenLayout alignContent="flex-start" style={Theme.background_color}>
        <Header
          // onMakeLog={ ( type ) => {
          //   doLogAction( this.props.dispatch, {
          //     kind: LogActionKind.TRY_MAKE_LOGS,
          //     userType: "basalt-admin",
          //     type,
          //     adminEmail: token.state.status === TokenStateStatus.SUCCESS ? token.state.email : undefined
          //   } );
          // } }
          curr="board"
          name={name}
          onLogout={() => {
            this.onLogout();
          }}
          onReplace={(url) => {
            this.props.history.replace(url);
          }}
        />

        <Container>
          <Grid
            xs={12}
            justify="center"
            container
            item
            style={{ marginTop: 20 }}
          >
            {/* 가입자 */}
            <Grid
              xs={12}
              justify="center"
              container
              item
              style={{ marginBottom: 10, padding: 5 }}
            >
              <Grid xs={4} container item style={{ padding: 10 }}>
                <Grid
                  xs={12}
                  justify="center"
                  container
                  item
                  style={{
                    border: "1px solid #dddfe6",
                    borderRadius: 10,
                    paddingBottom: 25,
                    paddingLeft: 10,
                    paddingRight: 10,
                    boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)",
                  }}
                >
                  <Grid
                    xs={12}
                    alignItems="center"
                    justify="center"
                    container
                    item
                    style={{ height: 40, marginTop: 10, marginBottom: 10 }}
                  >
                    <Typography style={{ margin: 5, fontSize: 14 }}>
                      <span
                        role="img"
                        aria-label="writing hand"
                        style={{ marginRight: 8 }}
                      >
                        🙍
                      </span>
                      총 가입자 수
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    alignItems="center"
                    justify="center"
                    container
                    item
                    style={{ height: 40, marginTop: 10, marginBottom: 10 }}
                  >
                    <Typography style={{ fontSize: 40, fontWeight: "bold" }}>
                      {users.length}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        marginLeft: 8,
                      }}
                    >
                      명
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={4} container item style={{ padding: 10 }}>
                <Grid
                  xs={12}
                  justify="center"
                  container
                  item
                  style={{
                    border: "1px solid #dddfe6",
                    borderRadius: 10,
                    paddingBottom: 25,
                    paddingLeft: 10,
                    paddingRight: 10,
                    boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)",
                  }}
                >
                  <Grid
                    xs={12}
                    alignItems="center"
                    justify="center"
                    container
                    item
                    style={{ height: 40, marginTop: 10, marginBottom: 10 }}
                  >
                    <Typography style={{ margin: 5, fontSize: 14 }}>
                      <span
                        role="img"
                        aria-label="writing hand"
                        style={{ marginRight: 8 }}
                      >
                        👪
                      </span>
                      총 워크스페이스 수
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    alignItems="center"
                    justify="center"
                    container
                    item
                    style={{ height: 40, marginTop: 10, marginBottom: 10 }}
                  >
                    <Typography style={{ fontSize: 40, fontWeight: "bold" }}>
                      {workspaces.length}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        marginLeft: 8,
                      }}
                    >
                      개
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={4} container item style={{ padding: 10 }}>
                <Grid
                  xs={12}
                  justify="center"
                  container
                  item
                  style={{
                    border: "1px solid #dddfe6",
                    borderRadius: 10,
                    paddingBottom: 25,
                    paddingLeft: 10,
                    paddingRight: 10,
                    boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)",
                  }}
                >
                  <Grid
                    xs={12}
                    alignItems="center"
                    justify="center"
                    container
                    item
                    style={{ height: 40, marginTop: 10, marginBottom: 10 }}
                  >
                    <Typography style={{ margin: 5, fontSize: 14 }}>
                      <span
                        role="img"
                        aria-label="writing hand"
                        style={{ marginRight: 8 }}
                      >
                        🎸
                      </span>
                      워크스페이스 당 평균 유저 수
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    alignItems="center"
                    justify="center"
                    container
                    item
                    style={{ height: 40, marginTop: 10, marginBottom: 10 }}
                  >
                    <Typography style={{ fontSize: 40, fontWeight: "bold" }}>
                      {this.getAvgUsersInWorkspaces(workspaces)}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        marginLeft: 8,
                      }}
                    >
                      명
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              justify="center"
              container
              item
              style={{ marginBottom: 10, padding: 5 }}
            >
              <Grid
                xs={12}
                justify="center"
                container
                item
                style={{
                  border: "1px solid #dddfe6",
                  borderRadius: 10,
                  paddingBottom: 25,
                  paddingLeft: 10,
                  paddingRight: 10,
                  boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)",
                }}
              >
                <Grid
                  xs={12}
                  alignItems="center"
                  justify="center"
                  container
                  item
                  style={{ height: 40, marginTop: 10, marginBottom: 10 }}
                >
                  <Grid xs={3} container item></Grid>
                  <Grid
                    xs={6}
                    container
                    item
                    alignItems="center"
                    justify="center"
                  >
                    <Typography style={{ margin: 5, fontSize: 14 }}>
                      <span
                        role="img"
                        aria-label="writing hand"
                        style={{ marginRight: 8 }}
                      >
                        🎖
                      </span>
                      활성 사용자 비율(%)
                    </Typography>
                  </Grid>
                  <Grid
                    xs={3}
                    container
                    item
                    alignItems="center"
                    justify="flex-end"
                  >
                    <SimpleMenu
                      titles={["전체 사용자 대비", "접속자 대비"]}
                      onClicks={[
                        () => {
                          this.setState({ activeMode: "all" });
                        },
                        () => {
                          this.setState({ activeMode: "visit" });
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Line
                  height={80}
                  data={activeUserData}
                  options={{
                    legend: { display: false },
                    scales: { yAxes: [{ ticks: { min: 0, stepSize: 100 } }] },
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              xs={12}
              justify="center"
              container
              item
              style={{ marginBottom: 10, padding: 5 }}
            >
              <Grid
                xs={12}
                justify="center"
                container
                item
                style={{
                  border: "1px solid #dddfe6",
                  borderRadius: 10,
                  paddingBottom: 25,
                  paddingLeft: 10,
                  paddingRight: 10,
                  boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)",
                }}
              >
                <Grid
                  xs={12}
                  alignItems="center"
                  justify="center"
                  container
                  item
                  style={{ height: 40, marginTop: 10, marginBottom: 10 }}
                >
                  <Grid xs={3} container item></Grid>
                  <Grid
                    xs={6}
                    container
                    item
                    alignItems="center"
                    justify="center"
                  >
                    <Typography style={{ margin: 5, fontSize: 14 }}>
                      <span
                        role="img"
                        aria-label="writing hand"
                        style={{ marginRight: 8 }}
                      >
                        🏆
                      </span>
                      유효 활동 리텐션 비율(%)
                    </Typography>
                  </Grid>
                  <Grid
                    xs={3}
                    container
                    item
                    alignItems="center"
                    justify="flex-end"
                  >
                    {/* <SimpleMenu
                      titles={ [ "전체 사용자 대비", "접속자 대비" ] }
                      onClicks={ [ () => { this.setState( { activeMode: "all" } ) }, () => { this.setState( { activeMode: "visit" } ) } ] }
                    /> */}
                  </Grid>
                </Grid>
                <Line
                  height={80}
                  data={validRetensiondata}
                  options={{
                    legend: { display: false },
                    scales: { yAxes: [{ ticks: { min: 0, stepSize: 100 } }] },
                  }}
                />
              </Grid>
            </Grid>

            {/* <Grid xs={ 12 } justify="center" container item style={ { marginBottom: 10, padding: 5 } }>
              <Grid xs={ 12 } justify="center" container item style={ { border: "1px solid #dddfe6", borderRadius: 10, paddingBottom: 25, paddingLeft: 10, paddingRight: 10, boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)" } }>
                <Grid xs={ 12 } alignItems="center" justify="center" container item style={ { height: 40, marginTop: 10, marginBottom: 10 } }>
                  <Grid xs={ 3 } container item />
                  <Grid xs={ 6 } container item alignItems="center" justify="center" >
                    <Typography style={ { margin: 5, fontWeight: "bold", fontSize: 15 } }>방문자 수</Typography>

                  </Grid>
                  <Grid xs={ 3 } container item alignItems="center" justify="flex-end">
                    <SimpleMenu
                      titles={ [ "Day", "Week", "Month" ] }
                      onClicks={ [ () => { this.setState( { visitMode: "day" } ) }, () => { this.setState( { visitMode: "week" } ) }, () => { this.setState( { visitMode: "month" } ) } ] }
                    />
                  </Grid>
                </Grid>
                <Line height={ 80 } data={ visitData } options={ { legend: { display: false }, scales: { yAxes: [ { ticks: { min: 0, stepSize: 100 } } ] } } } />
              </Grid>
            </Grid> */}
            {/* <Grid xs={ 12 } justify="center" container item style={ { marginBottom: 10, padding: 5 } }>
              <Grid xs={ 12 } justify="center" container item style={ { border: "1px solid #dddfe6", borderRadius: 10, paddingBottom: 25, paddingLeft: 10, paddingRight: 10, boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)" } }>
                <Grid xs={ 12 } alignItems="center" justify="center" container item style={ { height: 40, marginTop: 10, marginBottom: 10 } }>
                  <Grid xs={ 3 } container item>

                  </Grid>
                  <Grid xs={ 6 } container item alignItems="center" justify="center" >
                    <Typography style={ { margin: 5, fontWeight: "bold", fontSize: 15 } }>가입자 증감율 | { registerRateTitle }</Typography>

                  </Grid>
                  <Grid xs={ 3 } container item alignItems="center" justify="flex-end">
                    <SimpleMenu
                      titles={ [ "Day", "Week", "Month" ] }
                      onClicks={ [ () => { this.setState( { registerRateMode: "day" } ) }, () => { this.setState( { registerRateMode: "week" } ) }, () => { this.setState( { registerRateMode: "month" } ) } ] }
                    />
                  </Grid>

                </Grid>
                <Line height={ 80 } data={ regsiterRateData } options={ { legend: { display: false }, scales: { yAxes: [ { ticks: { min: -500, stepSize: 500 } } ] } } } />
              </Grid>
            </Grid> */}

            <Grid
              xs={12}
              justify="center"
              container
              item
              style={{ marginBottom: 10, padding: 5 }}
            >
              <Grid
                xs={12}
                justify="center"
                container
                item
                style={{
                  border: "1px solid #dddfe6",
                  borderRadius: 10,
                  paddingBottom: 25,
                  paddingLeft: 10,
                  paddingRight: 10,
                  boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)",
                }}
              >
                <Grid
                  xs={12}
                  alignItems="center"
                  justify="center"
                  container
                  item
                  style={{ height: 40, marginTop: 10, marginBottom: 10 }}
                >
                  <Grid xs={3} container item></Grid>
                  <Grid
                    xs={6}
                    container
                    item
                    alignItems="center"
                    justify="center"
                  >
                    <Typography style={{ margin: 5, fontSize: 14 }}>
                      가입자 추세 | {registerTitle}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={3}
                    container
                    item
                    alignItems="center"
                    justify="flex-end"
                  >
                    <SimpleMenu
                      titles={["Day", "Week", "Month"]}
                      onClicks={[
                        () => {
                          this.setState({ registerMode: "day" });
                        },
                        () => {
                          this.setState({ registerMode: "week" });
                        },
                        () => {
                          this.setState({ registerMode: "month" });
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Line
                  height={80}
                  data={registerData}
                  options={{
                    legend: { display: false },
                    scales: { yAxes: [{ ticks: { min: 0, stepSize: 10 } }] },
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              xs={12}
              justify="center"
              container
              item
              style={{ marginBottom: 10, padding: 5 }}
            >
              <Grid
                xs={12}
                justify="center"
                container
                item
                style={{
                  border: "1px solid #dddfe6",
                  borderRadius: 10,
                  paddingBottom: 25,
                  paddingLeft: 10,
                  paddingRight: 10,
                  boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)",
                }}
              >
                <Grid
                  xs={12}
                  alignItems="center"
                  justify="center"
                  container
                  item
                  style={{ height: 40, marginTop: 10, marginBottom: 10 }}
                >
                  <Grid xs={3} container item></Grid>
                  <Grid
                    xs={6}
                    container
                    item
                    alignItems="center"
                    justify="center"
                  >
                    <Typography style={{ margin: 5, fontSize: 14 }}>
                      이용 Timezone
                    </Typography>
                  </Grid>
                  <Grid
                    xs={3}
                    container
                    item
                    alignItems="center"
                    justify="flex-end"
                  >
                    <SimpleMenu
                      titles={["Day", "Week", "Month"]}
                      onClicks={[
                        () => {
                          this.setState({ registerMode: "day" });
                        },
                        () => {
                          this.setState({ registerMode: "week" });
                        },
                        () => {
                          this.setState({ registerMode: "month" });
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Bar
                  height={80}
                  data={timeZoneData}
                  options={{
                    legend: { display: false },
                    scales: { yAxes: [{ ticks: { min: 0, stepSize: 10 } }] },
                  }}
                />
              </Grid>
            </Grid>

            {/* <Grid xs={ 12 } justify="center" container item style={ { marginBottom: 10, padding: 5 } }>
              <Grid xs={ 12 } justify="center" container item style={ { border: "1px solid #dddfe6", borderRadius: 10, paddingBottom: 25, paddingLeft: 10, paddingRight: 10, boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)" } }>
                <Grid xs={ 12 } alignItems="center" justify="center" container item style={ { height: 40, marginTop: 10, marginBottom: 10 } }>
                  <Grid xs={ 3 } container item>

                  </Grid>
                  <Grid xs={ 6 } container item alignItems="center" justify="center" >
                    <Typography style={ { margin: 5, fontWeight: "bold", fontSize: 15 } }>가입 시간대</Typography>

                  </Grid>
                  <Grid xs={ 3 } container item alignItems="center" justify="flex-end">
                    <SimpleMenu
                      titles={ [ "Hours", "Days" ] }
                      onClicks={ [ () => { this.setState( { createdTimeMode: "hour" } ) }, () => { this.setState( { createdTimeMode: "day" } ) } ] }
                    />
                  </Grid>

                </Grid>
                <Bar height={ 80 } data={ createdTimeData } options={ { legend: { display: false }, scales: { yAxes: [ { ticks: { min: 0, stepSize: 10 } } ] } } } />
              </Grid>
            </Grid> */}

            {/* <Grid xs={ 12 } justify="center" container item>


              <Grid xs={ 6 } container item justify="center" style={ { padding: 5 } }>
                <Grid xs={ 12 } container item justify="center" style={ { border: "1px solid #dddfe6", borderRadius: 10, paddingBottom: 25, paddingLeft: 10, paddingRight: 10, boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)" } }>
                  <Grid xs={ 12 } alignItems="center" container item style={ { height: 40, borderBottom: "2px solid #dddfe6", marginTop: 10 } }>
                    <Typography style={ { margin: 5, fontWeight: "bold", fontSize: 15 } }>접속 플랫폼 비율 (%)</Typography>
                  </Grid>
                  <Doughnut data={ PlatformGraphData } />
                </Grid>
              </Grid>
              <Grid xs={ 6 } container item justify="center" style={ { padding: 5 } }>
                <Grid xs={ 12 } container item justify="center" style={ { border: "1px solid #dddfe6", borderRadius: 10, paddingBottom: 25, paddingLeft: 10, paddingRight: 10, boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)" } }>
                  <Grid xs={ 12 } alignItems="center" container item style={ { height: 40, borderBottom: "2px solid #dddfe6", marginTop: 10 } }>
                    <Typography style={ { margin: 5, fontWeight: "bold", fontSize: 15 } }>접속 브라우저 비율 (%)</Typography>
                  </Grid>
                  <Doughnut data={ BrowserGraphData } />
                </Grid>
              </Grid>
            </Grid> */}
            <Grid xs={12} justify="center" container item>
              <Grid
                xs={6}
                container
                item
                justify="center"
                style={{ padding: 5 }}
              >
                <Grid
                  xs={12}
                  container
                  item
                  justify="center"
                  style={{
                    border: "1px solid #dddfe6",
                    borderRadius: 10,
                    paddingBottom: 25,
                    paddingLeft: 10,
                    paddingRight: 10,
                    boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)",
                  }}
                >
                  <Grid
                    xs={12}
                    alignItems="center"
                    container
                    item
                    style={{
                      height: 40,
                      borderBottom: "2px solid #dddfe6",
                      marginTop: 10,
                    }}
                  >
                    <Typography style={{ margin: 5, fontSize: 14 }}>
                      전체 워크스페이스 대비 결제율
                    </Typography>
                  </Grid>
                  <Doughnut data={workspacePlanRate} />
                </Grid>
              </Grid>
              <Grid
                xs={6}
                container
                item
                justify="center"
                style={{ padding: 5 }}
              >
                <Grid
                  xs={12}
                  container
                  item
                  justify="center"
                  style={{
                    border: "1px solid #dddfe6",
                    borderRadius: 10,
                    paddingBottom: 25,
                    paddingLeft: 10,
                    paddingRight: 10,
                    boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)",
                  }}
                >
                  <Grid
                    xs={12}
                    alignItems="center"
                    container
                    item
                    style={{
                      height: 40,
                      borderBottom: "2px solid #dddfe6",
                      marginTop: 10,
                    }}
                  >
                    <Typography style={{ margin: 5, fontSize: 14 }}>
                      인당 평균 링크 생성 수
                    </Typography>
                  </Grid>
                  <Bar data={LinkData} />
                </Grid>
              </Grid>
            </Grid>

            <Grid xs={12} justify="center" container item>
              <Grid
                xs={6}
                container
                item
                justify="center"
                style={{ padding: 5 }}
              >
                <Grid
                  xs={12}
                  container
                  item
                  justify="center"
                  style={{
                    border: "1px solid #dddfe6",
                    borderRadius: 10,
                    paddingBottom: 25,
                    paddingLeft: 10,
                    paddingRight: 10,
                    boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)",
                  }}
                >
                  <Grid
                    xs={12}
                    alignItems="center"
                    container
                    item
                    style={{
                      height: 40,
                      borderBottom: "2px solid #dddfe6",
                      marginTop: 10,
                    }}
                  >
                    <Typography style={{ margin: 5, fontSize: 14 }}>
                      파일 확장자 (최근 100개)
                    </Typography>
                  </Grid>
                  <Doughnut data={extensionData} />
                </Grid>
              </Grid>
              {/* <Grid xs={ 6 } container item justify="center" style={ { padding: 5 } }>
                <Grid xs={ 12 } container item justify="center" style={ { border: "1px solid #dddfe6", borderRadius: 10, paddingBottom: 25, paddingLeft: 10, paddingRight: 10, boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)" } }>
                  <Grid xs={ 12 } alignItems="center" container item style={ { height: 40, borderBottom: "2px solid #dddfe6", marginTop: 10 } }>
                    <Typography style={ { margin: 5, fontSize: 14 } }>전체 사용자 중 PDF 다운 사용자</Typography>
                  </Grid>
                  <Doughnut data={ pdfData } />
                </Grid>
              </Grid> */}
              <Grid
                xs={6}
                container
                item
                justify="center"
                style={{ padding: 5 }}
              >
                <Grid
                  xs={12}
                  container
                  item
                  justify="center"
                  style={{
                    border: "1px solid #dddfe6",
                    borderRadius: 10,
                    paddingBottom: 25,
                    paddingLeft: 10,
                    paddingRight: 10,
                    boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)",
                  }}
                >
                  <Grid
                    xs={12}
                    alignItems="center"
                    container
                    item
                    style={{
                      height: 40,
                      borderBottom: "2px solid #dddfe6",
                      marginTop: 10,
                    }}
                  >
                    <Typography style={{ margin: 5, fontSize: 14 }}>
                      접속 기기 비율
                    </Typography>
                  </Grid>
                  <Doughnut data={BrowserGraphData} />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} justify="center" container item>
              <Grid
                xs={6}
                container
                item
                justify="center"
                style={{ padding: 5 }}
              >
                <Grid
                  xs={12}
                  container
                  item
                  justify="center"
                  style={{
                    border: "1px solid #dddfe6",
                    borderRadius: 10,
                    paddingBottom: 25,
                    paddingLeft: 10,
                    paddingRight: 10,
                    boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)",
                  }}
                >
                  <Grid
                    xs={12}
                    alignItems="center"
                    container
                    item
                    style={{
                      height: 40,
                      borderBottom: "2px solid #dddfe6",
                      marginTop: 10,
                    }}
                  >
                    <Typography style={{ margin: 5, fontSize: 14 }}>
                      PDF 인당 평균 다운로드
                    </Typography>
                  </Grid>
                  <Bar data={PDFData} />
                </Grid>
              </Grid>
              <Grid
                xs={6}
                container
                item
                justify="center"
                style={{ padding: 5 }}
              >
                <Grid
                  xs={12}
                  container
                  item
                  justify="center"
                  style={{
                    border: "1px solid #dddfe6",
                    borderRadius: 10,
                    paddingBottom: 25,
                    paddingLeft: 10,
                    paddingRight: 10,
                    boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.16)",
                  }}
                >
                  <Grid
                    xs={12}
                    alignItems="center"
                    container
                    item
                    style={{
                      height: 40,
                      borderBottom: "2px solid #dddfe6",
                      marginTop: 10,
                    }}
                  >
                    <Typography style={{ margin: 5, fontSize: 14 }}>
                      인당 평균 업로드 횟수
                    </Typography>
                  </Grid>
                  <Bar data={NoteData} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <div style={{ width: "100%", height: 50 }}></div>
      </ScreenLayout>
    );
  }
}

export default connector(withRouter(DashBoardScreen));
