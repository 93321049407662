const styles = {
  xl_input_base: {
    width: "100%",
    height: 56,
    borderRadius: 10,
    backgroundColor: "#f1f3f5",
    border: "1px solid #dee2e6",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  xs_input_base: {
    width: "100%",
    height: 40,
    maxHeight: 40,
    borderRadius: 10,
    backgroundColor: "#f1f3f5",
    border: "1px solid #dee2e6",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  xl_icon: { marginRight: 4 },
  xs_icon: { marginRight: 4, width: 16, height: 16 },
  xl_text: {
    fontSize: 16,
    letterSpacing: -0.6,
    color: "#495057",
    marginRight: 8,
  },
  xs_text: {
    fontSize: 13,
    letterSpacing: -0.6,
    color: "#495057",
    marginRight: 6,
  },

  calender: {
    padding: 10,
    width: 260,
    height: 260,
    zIndex: 999,
    backgroundColor: "white",
    boxShadow: "0 12px 28px 0 rgba(33, 37, 41, 0.12)",
    borderRadius: 10,
  },
};

export default styles;
