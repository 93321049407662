import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  Grid,
  Container,
  Typography,
  InputBase,
  CircularProgress,
  IconButton,
  Button,
} from "@material-ui/core";
import ScreenURL from "../routes/path";
import {
  doTokenAction,
  TokenActionKind,
  TokenStateStatus,
} from "../store/reducers/token";
import ScreenLayout from "../utils/templates/ScreenLayout";
import Theme from "../styles/Theme";
import { Alert } from "@material-ui/lab";
import {
  AdminActionKind,
  doAdminAction,
  AdminStateStatus,
  resetAdmin,
  doAdminActionAsync,
} from "../store/reducers/admin";
import {
  RefinedUserWithLicense,
  LicenseObject,
  LicenseType,
  LicensePlan,
} from "@basalt-commons/api/object/license";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { includesString } from "@redwit-commons/utils/function";
import UserList2 from "../component/pure/UserList2";

import LicenseEditScreen, { LicenseCreateArgs } from "./LicenseEditScreen";
import { LicenseRoleTypes } from "@basalt-commons/api/object/user_license_map";
import XLSX from "xlsx";
import UserInfoView from "../component/pure/UserInfoView";
import Header from "../component/pure/headers/Header";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import { CloseRounded } from "@material-ui/icons";
import CalendarButton from "src/component/pure/calendar/CalendarButton";

type UserXlxsOptions = {
  only_marketing_user: boolean;
  now_user: boolean;
  name: boolean;
  email: boolean;
  school: boolean;
  create_at: boolean;
  time_zone: boolean;
  marketing_term: boolean;
  count_workspaces: boolean;
  search_from?: Date;
  search_end?: Date;
};
const defaultXlsxOptions = {
  only_marketing_user: false,
  now_user: false,
  name: true,
  email: true,
  school: false,
  create_at: false,
  time_zone: false,
  marketing_term: false,
  count_workspaces: false,
  search_from: undefined,
  search_end: undefined,
};

const mapStateToProps = (state: RootState) => {
  return {
    token: state.token,
    admin: state.admin,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {};
type UserMainScreenProps = PropsFromRedux & RouteComponentProps<Props>;
type UserMainScreenState = {
  alert: undefined | string;
  users: RefinedUserWithLicense[];
  licenses: LicenseObject[];
  search_word: string;
  edit: boolean;
  check_marketing: boolean;
  edit_target_license: undefined | LicenseObject;
  detail_user: undefined | RefinedUserWithLicense;

  // use
  xlsx_opt?: UserXlxsOptions;
};

class UserMainScreen extends React.Component<
  UserMainScreenProps,
  UserMainScreenState
> {
  constructor(props: UserMainScreenProps) {
    super(props);
    this.state = {
      alert: undefined,
      users: [],
      licenses: [],
      search_word: "",
      edit: false,
      edit_target_license: undefined,
      check_marketing: false,
      detail_user: undefined,

      xlsx_opt: undefined,
    };
  }

  /* -------------------------------- Screen LifeCycle Action -------------------------------- */

  // license가 바뀌는 것을 catch하여 setState를 해줍니다
  updateUsersnLicenses() {
    const { admin } = this.props;
    const users =
      admin.state.status === AdminStateStatus.SUCCESS ? admin.state.users : [];
    const licenses =
      admin.state.status === AdminStateStatus.SUCCESS
        ? admin.state.licenses
        : [];
    return { users, licenses };
  }

  componentDidMount() {
    const { token, history } = this.props;
    if (token.state.status === TokenStateStatus.INIT)
      history.replace(ScreenURL.LOADING);
    doAdminAction(
      this.props.dispatch,
      { kind: AdminActionKind.TRY_GET_ALL_USERS },
      () => {
        doAdminAction(
          this.props.dispatch,
          { kind: AdminActionKind.TRY_GET_ALL_WORKSPACES },
          () => {
            const { users, licenses } = this.updateUsersnLicenses();
            this.setState({ users, licenses });
          }
        );
      }
    );
  }

  /* -------------------------------- navigation -------------------------------- */

  onBack() {
    resetAdmin(this.props.dispatch);
    this.props.history.goBack();
  }

  /* -------------------------------- Token 관련 action -------------------------------- */

  onLogout() {
    const { history } = this.props;
    doTokenAction(
      this.props.dispatch,
      {
        kind: TokenActionKind.TRY_LOGOUT,
      },
      () => {
        history.replace(ScreenURL.LOADING);
        return;
      },
      () => {
        this.setState({ alert: "로그아웃에 실패하였습니다" });
      }
    );
  }

  /* -------------------------------- User 관련 action -------------------------------- */

  getXLSXFiles() {
    let users =
      this.state.xlsx_opt?.only_marketing_user === true
        ? this.state.users.filter((user) => user.marketing_term === true)
        : this.state.users;
    users = this.filterUsersInPeriod(users);
    if (users.length < 1) return alert(`필터링 된 유저 목록: ${users.length}`);
    const users_infos = users.map((user) => {
      const { email, name, school, marketing_term, createdAt, time_zone } =
        user;
      let info = {};
      if (this.state.xlsx_opt?.name === true) {
        info = { ...info, name };
      }
      if (this.state.xlsx_opt?.email === true) {
        info = { ...info, email };
      }
      if (this.state.xlsx_opt?.school === true) {
        info = { ...info, school };
      }
      if (this.state.xlsx_opt?.create_at === true) {
        info = { ...info, createAt: moment(createdAt).format("YYYY-MM-DD") };
      }
      if (this.state.xlsx_opt?.time_zone === true) {
        info = { ...info, timezone: time_zone };
      }
      if (this.state.xlsx_opt?.marketing_term === true) {
        info = { ...info, marketing_term };
      }
      if (this.state.xlsx_opt?.count_workspaces === true) {
        const reuslt = this.getUserWorkspaces(user.id);
        info = { ...info, workspaces: reuslt.length };
      }
      return info;
    });
    const data = XLSX.utils.json_to_sheet(users_infos);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, "users");
    XLSX.writeFile(wb, "basalt_user.xlsx");
    return;
  }
  filterUsersInPeriod(users: RefinedUserWithLicense[]) {
    let filterUsers = users;
    if (
      this.state.xlsx_opt?.search_from !== undefined &&
      this.state.xlsx_opt?.search_end !== undefined
    ) {
      const search_from = this.convertLocalDateToUTC(
        this.state.xlsx_opt?.search_from
      );
      const search_end = this.convertLocalDateToUTC(
        this.state.xlsx_opt?.search_end
      );
      return (filterUsers = filterUsers.filter((user) => {
        const user_created_date = this.convertUserCreatedDate(user.createdAt);
        return (
          user_created_date >= search_from && user_created_date <= search_end
        );
      }));
    } else if (this.state.xlsx_opt?.search_from !== undefined) {
      const search_from = this.convertLocalDateToUTC(
        this.state.xlsx_opt?.search_from
      );
      filterUsers = filterUsers.filter((user) => {
        const user_created_date = this.convertUserCreatedDate(user.createdAt);
        return user_created_date >= search_from;
      });
    } else if (this.state.xlsx_opt?.search_end !== undefined) {
      const search_end = this.convertLocalDateToUTC(
        this.state.xlsx_opt?.search_end
      );
      filterUsers = filterUsers.filter((user) => {
        const user_created_date = this.convertUserCreatedDate(user.createdAt);
        return user_created_date <= search_end;
      });
    }
    return filterUsers;
  }
  convertLocalDateToUTC(date: Date) {
    return moment.utc(date).toDate().setHours(0, 0, 0, 0);
  }
  convertUserCreatedDate(date: string) {
    return moment(date).toDate().setHours(0, 0, 0, 0);
  }
  /* -------------------------------- License 관련 action -------------------------------- */

  onDeleteLicenseAll(id: string) {
    doAdminAction(
      this.props.dispatch,
      {
        kind: AdminActionKind.TRY_DELETE_LICENSE,
        licenseId: id,
      },
      () => {
        const { users, licenses } = this.updateUsersnLicenses();
        this.setState({ edit_target_license: undefined, users, licenses });
        alert("라이센스가 삭제되었습니다");
      },
      () => {
        alert("라이센스 삭제에 실패했습니다");
      }
    );
  }

  onCreateLicense(args: LicenseCreateArgs) {
    const {
      institution_name,
      plan,
      type,
      start,
      end,
      seats,
      description,
      select_admins,
      select_users,
    } = args;
    doAdminAction(
      this.props.dispatch,
      {
        kind: AdminActionKind.TRY_CREATE_LICENSE,
        args: {
          plan: plan.trim() as LicensePlan,
          type: type.trim() as LicenseType,
          seats: seats,
          start: start,
          end: end,
          institution_name: institution_name.trim(),
          description: description.trim(),
        },
        users: select_users,
        admins: select_admins,
      },
      () => {
        const { users, licenses } = this.updateUsersnLicenses();
        this.setState({
          edit: false,
          edit_target_license: undefined,
          users,
          licenses,
        });
        alert("라이센스 생성 완료했습니다");
        return;
      },
      () => {
        this.setState({ edit: false, edit_target_license: undefined });
        alert("라이센스 생성에 실패했습니다");
        return;
      }
    );
    return;
  }

  onEditLicense(args: LicenseCreateArgs, licenseId: string) {
    const {
      institution_name,
      plan,
      type,
      start,
      end,
      seats,
      description,
      select_admins,
      select_users,
    } = args;
    doAdminAction(
      this.props.dispatch,
      {
        kind: AdminActionKind.TRY_UPDATE_LICENSE,
        users: select_users,
        admins: select_admins,
        args: {
          plan: plan.trim() as LicensePlan,
          type: type.trim() as LicenseType,
          seats: seats,
          start: start,
          end: end,
          institution_name: institution_name.trim(),
          description: description.trim(),
          id: licenseId,
        },
      },
      () => {
        const { users, licenses } = this.updateUsersnLicenses();
        this.setState({
          edit: false,
          edit_target_license: undefined,
          users,
          licenses,
        });
        alert("라이센스 업데이트 완료했습니다");
        return;
      },
      () => {
        this.setState({ edit: false, edit_target_license: undefined });
        alert("라이센스 업데이트에 실패했습니다");
        return;
      }
    );
  }

  onDeleteLicense(UserId: string, LicenseId: string, role: LicenseRoleTypes) {
    const { admin } = this.props;
    if (admin.state.status !== AdminStateStatus.SUCCESS) return;
    doAdminAction(
      this.props.dispatch,
      {
        kind: AdminActionKind.TRY_DELETE_LICENSE_USER,
        UserId,
        LicenseId,
        role,
      },
      () => {
        const { users, licenses } = this.updateUsersnLicenses();
        this.setState({ users, licenses });
        alert("라이센스 삭제 완료되었습니다");
        return;
      },
      () => {
        alert("라이센스 삭제에 실패하였습니다");
        return;
      }
    );
  }

  onCompletedEditLicense(args: LicenseCreateArgs) {
    if (this.state.edit_target_license === undefined)
      this.onCreateLicense(args);
    else this.onEditLicense(args, this.state.edit_target_license.id);
  }

  /* -------------------------------- 검색 관련 logic -------------------------------- */
  onChangeSearchWord(word: string) {
    this.setState({ search_word: word });
    return;
  }

  /**
   * 검색어를 보고 user list를 필터링 합니다
   */
  getFilterUser() {
    // 검색 결과 word를 보고 user를 필터링합니다
    const { users, search_word, check_marketing } = this.state;
    if (check_marketing === true) {
      return users.filter(
        (u) => u.marketing_term !== undefined && u.marketing_term === true
      );
    }
    if (search_word.length === 0) {
      return users;
    }
    let filtered_word = search_word.trim().split(" ");
    let target_users = users;
    for (const key of filtered_word) {
      target_users = target_users.filter((row) => {
        if (includesString(row, key)) return true;
        for (const lisc of row.Licenses) {
          if (includesString(lisc, key)) return true;
        }
        return false;
      });
    }
    return target_users;
  }

  /**
   * 검색어를 보고 licenses list를 필터링 합니다
   */
  getFilterLicenses() {
    const { licenses, search_word } = this.state;
    if (search_word.length === 0) {
      return licenses;
    }
    let filtered_word = search_word.trim().split(" ");
    let target_licenses = licenses;
    for (const key of filtered_word) {
      target_licenses = target_licenses.filter((row) => {
        if (includesString(row, key)) return true;
        for (const lisc of row.Users) {
          if (includesString(lisc, key)) return true;
        }
        return false;
      });
    }
    return target_licenses;
  }

  /* -------------------------------- Render Part -------------------------------- */
  onToggleMode() {
    this.props.history.replace(ScreenURL.LICENSE_LIST);
  }

  // 나중에 사용할지 몰라서 생성
  async deleteTestAccount(email: string) {
    await doAdminActionAsync(this.props.dispatch, {
      kind: AdminActionKind.TRY_DELETE_USER,
      email,
    });
    return;
  }

  getUserVisitLogs(userId: string) {
    const { admin } = this.props;
    if (admin.state.status === AdminStateStatus.INIT) return [];
    if (admin.state.visitLog === undefined) return [];
    const userLogs = admin.state.visitLog.filter(
      (log) => log.UserId === userId
    );
    return userLogs;
  }

  getUserNoteLogs(userId: string) {
    const { admin } = this.props;
    if (admin.state.status === AdminStateStatus.INIT) return [];
    if (admin.state.noteInfo === undefined) return [];
    const noteLogs = admin.state.noteInfo.filter(
      (log) => log.UserId === userId
    );
    return noteLogs;
  }

  getUserPDFLogs(userId: string) {
    const { admin } = this.props;
    if (admin.state.status === AdminStateStatus.INIT) return [];
    if (admin.state.pdfInfo === undefined) return [];
    const pdfLogs = admin.state.pdfInfo.filter((log) => log.UserId === userId);
    return pdfLogs;
  }

  getUserLinkLogs(userId: string) {
    const { admin } = this.props;
    if (admin.state.status === AdminStateStatus.INIT) return [];
    if (admin.state.linkInfo === undefined) return [];
    const linkLogs = admin.state.linkInfo.filter(
      (log) => log.UserId === userId
    );
    return linkLogs;
  }

  selectOptStyle(isSelect: boolean) {
    if (isSelect === true)
      return {
        backgroundColor: "#edf2ff",
        border: `1px solid #4263eb`,
        color: "#4263eb",
      };
    else
      return {
        backgroundColor: "white",
        border: "1px solid #adb5bd",
        color: "#adb5bd",
      };
  }

  getUserWorkspaces(userId: string) {
    const { admin } = this.props;
    const workspaces =
      admin.state.status === AdminStateStatus.SUCCESS
        ? admin.state.workspaces
        : [];
    const userWorkspaces = workspaces.filter(
      (workspace) =>
        workspace.Users.find((user) => user.id === userId) !== undefined
    );
    return userWorkspaces;
  }

  render() {
    const { token, admin } = this.props;
    const name =
      token.state.status !== TokenStateStatus.SUCCESS
        ? "undefined"
        : token.state.name;
    const users = this.getFilterUser();
    const workspaces =
      admin.state.status === AdminStateStatus.INIT
        ? []
        : admin.state.workspaces;
    return (
      <ScreenLayout
        alignContent="flex-start"
        style={Theme.background_etc_color}
      >
        <Header
          curr={"user"}
          onLogout={() => {
            this.onLogout();
          }}
          name={name}
          onReplace={(url) => {
            this.props.history.replace(url);
          }}
          onToggleMode={() => {
            this.onToggleMode();
          }}
          firstTitle={"전체 사용자 보기"}
          secondTitle="전체 라이센스 보기"
          currMode={"users"}
          modes={["users", "licenses"]}
        />
        {this.state.alert !== undefined && (
          <Alert
            style={{ width: "100%" }}
            variant="filled"
            severity="error"
            onClose={() => {
              this.setState({ alert: undefined });
            }}
          >
            {this.state.alert}
          </Alert>
        )}

        <Container>
          <Grid
            xs={12}
            container
            item
            style={{ height: 40, marginBottom: 10, marginTop: 20 }}
          >
            <Grid xs={6} container item direction="row" alignItems="center">
              <SearchIcon style={{ fontSize: 25, color: "#495057" }} />
              <InputBase
                style={{
                  width: "85%",
                  paddingLeft: 10,
                  fontSize: 14,
                  fontWeight: 500,
                }}
                placeholder="사용자 검색하기"
                value={this.state.search_word}
                onChange={(evt) => {
                  this.onChangeSearchWord(evt.target.value);
                }}
              />
              {this.state.search_word.length > 0 && (
                <IconButton
                  style={{ width: 40, height: 40 }}
                  onClick={() => {
                    this.setState({ search_word: "" });
                  }}
                >
                  <HighlightOffIcon
                    style={{ fontSize: 25, color: "#495057" }}
                  />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Container>

        <Grid
          xs={12}
          justify="center"
          container
          item
          style={{ paddingBottom: 30 }}
        >
          <Container>
            <UserList2
              users={users}
              workspaces={workspaces}
              onClick={(user) => {
                this.setState({ detail_user: user });
              }}
              getXLSXFile={() => {
                this.setState({ xlsx_opt: defaultXlsxOptions });
                return;
              }}
            />
          </Container>
        </Grid>

        {/* License Edit Screen */}
        {this.state.detail_user !== undefined && (
          <UserInfoView
            workspaces={this.getUserWorkspaces(this.state.detail_user.id)}
            visitLogs={this.getUserVisitLogs(this.state.detail_user.id)}
            noteLogs={this.getUserNoteLogs(this.state.detail_user.id)}
            pdfLogs={this.getUserPDFLogs(this.state.detail_user.id)}
            linkLogs={this.getUserLinkLogs(this.state.detail_user.id)}
            onClose={() => {
              this.setState({ detail_user: undefined });
            }}
            user={this.state.detail_user}
          />
        )}
        {this.state.edit === true && (
          <LicenseEditScreen
            users={
              admin.state.status === AdminStateStatus.SUCCESS
                ? admin.state.users
                : []
            }
            visible={this.state.edit}
            defaultLicense={this.state.edit_target_license}
            onComplete={(args: LicenseCreateArgs) => {
              this.onCompletedEditLicense(args);
            }}
            onClose={() => {
              this.setState({ edit: false, edit_target_license: undefined });
            }}
          />
        )}
        {admin.action !== undefined && (
          <Grid
            xs={12}
            direction="column"
            alignItems="center"
            justify="center"
            container
            item
            style={{
              backgroundColor: "#00000080",
              position: "absolute",
              height: "100%",
            }}
          >
            <CircularProgress style={{ color: "white", fontSize: 30 }} />
            <Typography
              style={{ color: "white", marginTop: 10, marginBottom: 40 }}
            >
              로딩 중...
            </Typography>
          </Grid>
        )}
        {this.state.xlsx_opt !== undefined && (
          <Grid
            container
            item
            xs={12}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#00000060",
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              item
              alignContent="flex-start"
              style={{
                width: 800,
                backgroundColor: "white",
                borderRadius: 10,
                paddingTop: 12,
                paddingBottom: 24,
              }}
            >
              <Grid
                container
                item
                xs={12}
                style={{ width: "100%" }}
                justify="flex-end"
              >
                <IconButton
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    this.setState({ xlsx_opt: undefined });
                  }}
                >
                  <CloseRounded style={{ color: "#495057", fontSize: 20 }} />
                </IconButton>
              </Grid>
              <Grid
                container
                item
                xs={12}
                style={{
                  width: "100%",
                  paddingLeft: 24,
                  borderBottom: "1px solid #dee2e6",
                  paddingBottom: 16,
                }}
              >
                <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                  엑셀 파일 추출 옵션
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{
                  width: "100%",
                  paddingLeft: 24,
                  paddingRight: 24,
                  marginBottom: 16,
                  marginTop: 24,
                  paddingBottom: 120,
                }}
              >
                <Grid container item xs={12}>
                  <Typography
                    style={{
                      fontSize: 12,
                      color: "#495057",
                      fontWeight: "bold",
                    }}
                  >
                    포함할 내용
                  </Typography>
                </Grid>
                <Grid container item xs={12} style={{ marginTop: 8 }}>
                  <Button
                    onClick={() => {
                      if (this.state.xlsx_opt === undefined)
                        return this.setState({
                          xlsx_opt: { ...defaultXlsxOptions, name: true },
                        });
                      this.setState({
                        xlsx_opt: {
                          ...this.state.xlsx_opt,
                          name: !this.state.xlsx_opt.name,
                        },
                      });
                      return;
                    }}
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                      borderRadius: 4,
                      paddingTop: 8,
                      paddingBottom: 8,
                      ...this.selectOptStyle(
                        this.state.xlsx_opt?.name === true
                      ),
                      marginRight: 8,
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                      이름
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => {
                      if (this.state.xlsx_opt === undefined)
                        return this.setState({
                          xlsx_opt: { ...defaultXlsxOptions, email: true },
                        });
                      this.setState({
                        xlsx_opt: {
                          ...this.state.xlsx_opt,
                          email: !this.state.xlsx_opt.email,
                        },
                      });
                      return;
                    }}
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                      borderRadius: 4,
                      paddingTop: 8,
                      paddingBottom: 8,
                      ...this.selectOptStyle(
                        this.state.xlsx_opt?.email === true
                      ),
                      marginRight: 8,
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                      이메일
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => {
                      if (this.state.xlsx_opt === undefined)
                        return this.setState({
                          xlsx_opt: { ...defaultXlsxOptions, school: true },
                        });
                      this.setState({
                        xlsx_opt: {
                          ...this.state.xlsx_opt,
                          school: !this.state.xlsx_opt.school,
                        },
                      });
                      return;
                    }}
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                      borderRadius: 4,
                      paddingTop: 8,
                      paddingBottom: 8,
                      ...this.selectOptStyle(
                        this.state.xlsx_opt?.school === true
                      ),
                      marginRight: 8,
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                      소속
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => {
                      if (this.state.xlsx_opt === undefined)
                        return this.setState({
                          xlsx_opt: { ...defaultXlsxOptions, time_zone: true },
                        });
                      this.setState({
                        xlsx_opt: {
                          ...this.state.xlsx_opt,
                          time_zone: !this.state.xlsx_opt.time_zone,
                        },
                      });
                      return;
                    }}
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                      borderRadius: 4,
                      paddingTop: 8,
                      paddingBottom: 8,
                      ...this.selectOptStyle(
                        this.state.xlsx_opt?.time_zone === true
                      ),
                      marginRight: 8,
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                      Timezone
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => {
                      if (this.state.xlsx_opt === undefined)
                        return this.setState({
                          xlsx_opt: {
                            ...defaultXlsxOptions,
                            marketing_term: true,
                          },
                        });
                      this.setState({
                        xlsx_opt: {
                          ...this.state.xlsx_opt,
                          marketing_term: !this.state.xlsx_opt.marketing_term,
                        },
                      });
                      return;
                    }}
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                      borderRadius: 4,
                      paddingTop: 8,
                      paddingBottom: 8,
                      ...this.selectOptStyle(
                        this.state.xlsx_opt?.marketing_term === true
                      ),
                      marginRight: 8,
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                      마케팅 동의 여부
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => {
                      if (this.state.xlsx_opt === undefined)
                        return this.setState({
                          xlsx_opt: { ...defaultXlsxOptions, create_at: true },
                        });
                      this.setState({
                        xlsx_opt: {
                          ...this.state.xlsx_opt,
                          create_at: !this.state.xlsx_opt.create_at,
                        },
                      });
                      return;
                    }}
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                      borderRadius: 4,
                      paddingTop: 8,
                      paddingBottom: 8,
                      ...this.selectOptStyle(
                        this.state.xlsx_opt?.create_at === true
                      ),
                      marginRight: 8,
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                      가입일
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => {
                      if (this.state.xlsx_opt === undefined)
                        return this.setState({
                          xlsx_opt: {
                            ...defaultXlsxOptions,
                            count_workspaces: true,
                          },
                        });
                      this.setState({
                        xlsx_opt: {
                          ...this.state.xlsx_opt,
                          count_workspaces:
                            !this.state.xlsx_opt.count_workspaces,
                        },
                      });
                      return;
                    }}
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                      borderRadius: 4,
                      paddingTop: 8,
                      paddingBottom: 8,
                      ...this.selectOptStyle(
                        this.state.xlsx_opt?.count_workspaces === true
                      ),
                      marginRight: 8,
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                      워크스페이스 갯수
                    </Typography>
                  </Button>
                </Grid>
                <Grid container item xs={12} style={{ marginTop: 24 }}>
                  <Typography
                    style={{
                      fontSize: 12,
                      color: "#495057",
                      fontWeight: "bold",
                    }}
                  >
                    사용자 분류
                  </Typography>
                </Grid>
                <Grid container item xs={12} style={{ marginTop: 8 }}>
                  <Button
                    onClick={() => {
                      if (this.state.xlsx_opt === undefined)
                        return this.setState({
                          xlsx_opt: {
                            ...defaultXlsxOptions,
                            only_marketing_user: true,
                          },
                        });
                      this.setState({
                        xlsx_opt: {
                          ...this.state.xlsx_opt,
                          only_marketing_user: true,
                        },
                      });
                      return;
                    }}
                    style={{
                      padding: 16,
                      borderRadius: 0,
                      borderTopLeftRadius: 4,
                      borderBottomLeftRadius: 4,
                      ...this.selectOptStyle(
                        this.state.xlsx_opt?.only_marketing_user === true
                      ),
                      borderRight: `0px solid #ffffff`,
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                      마케팅 동의 수신자만
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => {
                      if (this.state.xlsx_opt === undefined)
                        return this.setState({
                          xlsx_opt: {
                            ...defaultXlsxOptions,
                            only_marketing_user: false,
                          },
                        });
                      this.setState({
                        xlsx_opt: {
                          ...this.state.xlsx_opt,
                          only_marketing_user: false,
                        },
                      });
                      return;
                    }}
                    style={{
                      padding: 16,
                      borderRadius: 0,
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4,
                      ...this.selectOptStyle(
                        this.state.xlsx_opt?.only_marketing_user === false
                      ),
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                      마케팅 동의 수신 여부 관계 없이 모든 사용자
                    </Typography>
                  </Button>
                </Grid>

                <Grid container item xs={12} style={{ marginTop: 24 }}>
                  <Typography
                    style={{
                      fontSize: 12,
                      color: "#495057",
                      fontWeight: "bold",
                    }}
                  >
                    기간 검색
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justify="space-evenly"
                  xs={12}
                  style={{ marginTop: 8 }}
                >
                  <CalendarButton
                    time_zone={moment.tz.guess()}
                    lang="ko"
                    value={this.state.xlsx_opt.search_from}
                    max_date={new Date()}
                    onClickDate={(date) => {
                      this.setState({
                        xlsx_opt:
                          this.state.xlsx_opt === undefined
                            ? {
                                ...defaultXlsxOptions,
                                search_from: date,
                              }
                            : {
                                ...this.state.xlsx_opt,
                                search_from: date,
                              },
                      });
                    }}
                  />
                  <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                    ~
                  </Typography>
                  <CalendarButton
                    time_zone={moment.tz.guess()}
                    lang="ko"
                    value={this.state.xlsx_opt.search_end}
                    max_date={new Date()}
                    onClickDate={(date) => {
                      this.setState({
                        xlsx_opt:
                          this.state.xlsx_opt === undefined
                            ? {
                                ...defaultXlsxOptions,
                                search_end: date,
                              }
                            : {
                                ...this.state.xlsx_opt,
                                search_end: date,
                              },
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justify="flex-end"
                style={{
                  width: "100%",
                  paddingRight: 24,
                  borderTop: "1px solid #dee2e6",
                  paddingTop: 16,
                  paddingBottom: 16,
                }}
              >
                <Button
                  onClick={() => {
                    this.getXLSXFiles();
                  }}
                  style={{
                    height: 48,
                    borderRadius: 10,
                    backgroundColor: "#4263eb",
                    width: 120,
                  }}
                >
                  <Typography
                    style={{ fontSize: 16, color: "white", fontWeight: "bold" }}
                  >
                    추출하기
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </ScreenLayout>
    );
  }
}

export default connector(withRouter(UserMainScreen));
