import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";

export enum FileStateStatus {
  INIT = "FileState::INIT",
  SUCCESS_UPLOAD_IPFS = "FileState::SUCCESS_UPLOAD_IPFS",
}

export enum FileActionKind {
  TRY_UPLOAD_IPFS = "FileAction::TRY_UPLOAD_IPFS",
}

export type FileState =
  | {
      readonly status: FileStateStatus.INIT;
    }
  | {
      readonly status: FileStateStatus.SUCCESS_UPLOAD_IPFS;
      readonly cid: string;
    };
export type FileAction = {
  readonly kind: FileActionKind.TRY_UPLOAD_IPFS;
  readonly file: File;
};

export type FileError = never;

const smid = "File_STATE_MACHINE3";
export type FileStateMachineType = StateMachine3<
  FileStateStatus,
  FileState,
  FileActionKind,
  FileAction,
  FileError
>;
export const fileStateMachine: StateMachine3<
  FileStateStatus,
  FileState,
  FileActionKind,
  FileAction,
  FileError
> = new StateMachine3<
  FileStateStatus,
  FileState,
  FileActionKind,
  FileAction,
  FileError
>(smid, { status: FileStateStatus.INIT }, [
  transition(
    FileStateStatus.INIT,
    FileStateStatus.SUCCESS_UPLOAD_IPFS,
    FileActionKind.TRY_UPLOAD_IPFS
  ),
  transition(
    FileStateStatus.SUCCESS_UPLOAD_IPFS,
    FileStateStatus.SUCCESS_UPLOAD_IPFS,
    FileActionKind.TRY_UPLOAD_IPFS
  ),
]);

export type DispatchFileAction = Dispatch<
  StateMachineAction<
    FileStateStatus,
    FileState,
    FileActionKind,
    FileAction,
    FileError
  >
>;
export default mkReducer<
  FileStateStatus,
  FileState,
  FileActionKind,
  FileAction,
  FileError
>(fileStateMachine);
export const doFileAction = (
  dispatch: DispatchFileAction,
  nextAction: FileAction,
  onResolve: () => void = () => {},
  onReject: (err: FileError | InternalError) => void = () => {}
) => {
  dispatch(fileStateMachine.newTryAction(nextAction, onResolve, onReject));
};

export const doFileActionAsync = (
  dispatch: DispatchFileAction,
  nextAction: FileAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(fileStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetFile = (dispatch: DispatchFileAction) => {
  dispatch(fileStateMachine.newResetAction());
};
