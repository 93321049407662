import React from "react";
import { Grid } from "@material-ui/core";

type ScreenLayoutProps = {
  resizeScreen: (screenWidth: number, screenHeight: number) => void;
  alignContent?:
    | "stretch"
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | undefined;
  justify?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | undefined;
  style: object;
};
type ScreenLayoutState = {
  width: number;
  height: number;
};

class ScreenLayout extends React.Component<
  ScreenLayoutProps,
  ScreenLayoutState
> {
  static defaultProps = {
    resizeScreen: () => {},
    style: {},
  };
  private unmounted: boolean = false;
  constructor(props: ScreenLayoutProps) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  handleResize() {
    if (this.unmounted === true) return;
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (this.state.width === width && this.state.height === height) return;

    let newWidth = width !== this.state.width ? width : this.state.width;
    let newHeight = height !== this.state.height ? height : this.state.height;
    this.setState({ width: newWidth, height: newHeight });
    return;
  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.handleResize();
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {
      this.handleResize();
    });
    this.unmounted = true;
  }

  render() {
    const { style, alignContent, justify } = this.props;
    return (
      <Grid
        alignContent={alignContent}
        justify={justify}
        item
        container
        style={{
          width: this.state.width,
          height: this.state.height,
          overflowX: "hidden",
          overflowY: "scroll",
          ...style,
        }}
      >
        {this.props.children}
      </Grid>
    );
  }
}

export default ScreenLayout;
