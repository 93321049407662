const styles = {
  container: { borderRadius: 20, paddingBottom: 50, marginTop: 200 },
  banner_img: {
    width: "100%",
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
  text: { fontSize: 15, marginTop: 20 },
  email_field: {
    width: "70%",
    fontSize: 15,
    marginTop: 30,
    height: 50,
    borderRadius: 8,
    border: "1px solid #e9ecef",
    padding: 10,
  },
  pw_field: {
    width: "70%",
    fontSize: 15,
    marginTop: 15,
    height: 50,
    borderRadius: 8,
    border: "1px solid #e9ecef",
    padding: 10,
  },
  login_btn: {
    backgroundColor: "#5c7cfa",
    color: "white",
    width: "70%",
    marginTop: 50,
    height: 50,
    borderRadius: 8,
  },
};

export default styles;
