import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  Grid,
  Container,
  Typography,
  InputBase,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import ScreenURL from "../routes/path";
import {
  doTokenAction,
  TokenActionKind,
  TokenStateStatus,
} from "../store/reducers/token";
import ScreenLayout from "../utils/templates/ScreenLayout";
import Theme from "../styles/Theme";
import { Alert } from "@material-ui/lab";
import {
  AdminActionKind,
  AdminStateStatus,
  resetAdmin,
  doAdminActionAsync,
} from "../store/reducers/admin";
import {
  RefinedUserWithLicense,
  LicenseObject,
} from "@basalt-commons/api/object/license";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Header from "../component/pure/headers/Header";
import SearchIcon from "@material-ui/icons/Search";
import WorkspaceList2 from "../component/pure/WorkspaceList2";

import { WorkspaceWithPaymentInfo } from "@basalt-commons/global-api/object/admin";
import { WorkspacePlanType } from "@basalt-commons/global-api/object/workspace_plan";
import WorkspaceDetailModal from "../component/pure/WorkspaceDetailModal";
import {
  ArrowForwardIosRounded,
  ArrowBackIosRounded,
} from "@material-ui/icons";
import { isErr, InternalErrorKind } from "@redwit-commons/utils/exception2";
import { AdminErrorType } from "@basalt-commons/global-api/response/admin";

const mapStateToProps = (state: RootState) => {
  return {
    token: state.token,
    admin: state.admin,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {};
type WorkspaceScreenProps = PropsFromRedux & RouteComponentProps<Props>;
type WorkspaceScreenState = {
  alert: undefined | string;
  users: RefinedUserWithLicense[];
  licenses: LicenseObject[];
  search_word: string;
  check_marketing: boolean;
  isSelect: boolean;
  startIndex: number;

  // use state
  openDetailWorkspace?: WorkspaceWithPaymentInfo;
};

class WorkspaceScreen extends React.Component<
  WorkspaceScreenProps,
  WorkspaceScreenState
> {
  constructor(props: WorkspaceScreenProps) {
    super(props);
    this.state = {
      alert: undefined,
      users: [],
      licenses: [],
      search_word: "",
      check_marketing: false,
      isSelect: false,
      startIndex: 0,
    };
  }

  /* -------------------------------- Screen LifeCycle Action -------------------------------- */

  async componentDidMount() {
    const { token, history } = this.props;
    if (token.state.status === TokenStateStatus.INIT)
      history.replace(ScreenURL.LOADING);
    await doAdminActionAsync(this.props.dispatch, {
      kind: AdminActionKind.TRY_GET_ALL_WORKSPACES,
    });
  }

  /* -------------------------------- navigation -------------------------------- */

  onBack() {
    resetAdmin(this.props.dispatch);
    this.props.history.goBack();
  }

  /* -------------------------------- Token 관련 action -------------------------------- */

  onLogout() {
    const { history } = this.props;
    doTokenAction(
      this.props.dispatch,
      {
        kind: TokenActionKind.TRY_LOGOUT,
      },
      () => {
        history.replace(ScreenURL.LOADING);
        return;
      },
      () => {
        this.setState({ alert: "로그아웃에 실패하였습니다" });
      }
    );
  }

  /* -------------------------------- 검색 관련 logic -------------------------------- */
  onChangeSearchWord(word: string) {
    this.setState({ search_word: word });
    return;
  }
  /* -------------------------------- 워크스페이스 logic -------------------------------- */
  async handlePlan(
    workspace: WorkspaceWithPaymentInfo,
    plan?: WorkspacePlanType,
    start?: string,
    end?: string
  ) {
    try {
      // 플랜 생성
      if (workspace.WorkspacePlan === undefined) {
        if (plan !== undefined) {
          await doAdminActionAsync(this.props.dispatch, {
            kind: AdminActionKind.TRY_CREATE_PLAN,
            args: {
              WorkspaceId: workspace.id,
              plan,
            },
          });
        }
        alert("플랜 생성에 실패했습니다");
        return;
      }
      // 플랜 업데이트
      else {
        await doAdminActionAsync(this.props.dispatch, {
          kind: AdminActionKind.TRY_UPDATE_PLAN,
          args: {
            WorkspaceId: workspace.id,
            plan,
            start,
            end,
          },
        });
      }
      alert("성공적으로 변경되었습니다");
      return;
    } catch (err) {
      if (isErr(err) && err.kind === InternalErrorKind.ResponseCode) {
        if (err.code === 400) {
          if (err.msg === AdminErrorType.ALREADY_HAVE_PLAN) {
            alert("워크스페이스 하나당 플랜 하나만 가질 수 있습니다");
          }
          return;
        }
        if (err.code === 404) {
          if (err.msg === AdminErrorType.NOT_FOUND_PLAN) {
            alert("워크스페이스의 플랜을 찾을 수 없습니다");
          }
          return;
        }
      }
      alert("알 수 없는 오류가 발생했습니다");
      return;
    }
  }
  getWorkspaces() {
    return this.props.admin.state.status === AdminStateStatus.SUCCESS
      ? this.props.admin.state.workspaces
      : [];
  }
  /* -------------------------------- Render Part -------------------------------- */
  onToggleMode() {
    this.props.history.replace(ScreenURL.LICENSE_LIST);
  }

  filteredWorkspace(workspaces: WorkspaceWithPaymentInfo[]) {
    const word = this.state.search_word.trim();
    if (word.length > 0) {
      const filtered = workspaces.filter(
        (workspace) =>
          workspace.name.includes(word) ||
          workspace.Users.find(
            (user) => user.name.includes(word) || user.email.includes(word)
          ) !== undefined
      );
      return filtered;
    }
    return workspaces;
  }

  onPrevIndex() {
    if (this.state.startIndex <= 0) return;
    this.setState({ startIndex: this.state.startIndex - 20 });
  }

  onNextIndex(max: number) {
    if (this.state.startIndex + 21 >= max) return;
    this.setState({ startIndex: this.state.startIndex + 20 });
    return;
  }

  render() {
    const { token, admin } = this.props;
    const { startIndex } = this.state;
    const name =
      token.state.status !== TokenStateStatus.SUCCESS
        ? "undefined"
        : token.state.name;
    const workspace_list = this.getWorkspaces();
    const workspaces = this.filteredWorkspace(workspace_list);
    const view_workspace =
      this.state.startIndex + 20 > workspaces.length
        ? workspaces.slice(this.state.startIndex, workspaces.length)
        : workspaces.slice(this.state.startIndex, 20 + this.state.startIndex);
    return (
      <ScreenLayout
        alignContent="flex-start"
        style={Theme.background_etc_color}
      >
        <Header
          curr={"workspace"}
          onLogout={() => {
            this.onLogout();
          }}
          name={name}
          onReplace={(url) => {
            this.props.history.replace(url);
          }}
          onToggleMode={() => {
            this.onToggleMode();
          }}
          firstTitle={"전체 사용자 보기"}
          secondTitle="전체 라이센스 보기"
          currMode={"users"}
          modes={["users", "licenses"]}
        />
        {this.state.alert !== undefined && (
          <Alert
            style={{ width: "100%" }}
            variant="filled"
            severity="error"
            onClose={() => {
              this.setState({ alert: undefined });
            }}
          >
            {this.state.alert}
          </Alert>
        )}

        <Container>
          <Grid
            xs={12}
            container
            item
            style={{ height: 40, marginBottom: 10, marginTop: 20 }}
          >
            <Grid xs={6} container item direction="row" alignItems="center">
              <SearchIcon style={{ fontSize: 25, color: "#495057" }} />
              <InputBase
                style={{
                  width: "85%",
                  paddingLeft: 10,
                  fontSize: 14,
                  fontWeight: 500,
                }}
                placeholder="워크스페이스 이름 혹은 사용자 이름/이메일로 검색하기"
                value={this.state.search_word}
                onChange={(evt) => {
                  this.onChangeSearchWord(evt.target.value);
                }}
              />
              {this.state.search_word.length > 0 && (
                <IconButton
                  style={{ width: 40, height: 40 }}
                  onClick={() => {
                    this.setState({ search_word: "" });
                  }}
                >
                  <HighlightOffIcon
                    style={{ fontSize: 25, color: "#495057" }}
                  />
                </IconButton>
              )}
            </Grid>
            <Grid xs={6} container item justify="flex-end" alignItems="center">
              <IconButton
                onClick={() => {
                  this.onPrevIndex();
                }}
                style={{ width: 32, height: 32, marginRight: 8 }}
              >
                <ArrowBackIosRounded style={{ color: "#495057" }} />
              </IconButton>
              <Typography style={{ fontSize: 12, color: "#495057" }}>
                {`${startIndex + 1} ~ ${
                  startIndex + 20 > workspaces.length
                    ? workspaces.length
                    : startIndex + 20
                } / ${workspaces.length}`}
              </Typography>
              <IconButton
                onClick={() => {
                  this.onNextIndex(workspaces.length);
                }}
                style={{ width: 32, height: 32, marginLeft: 8 }}
              >
                <ArrowForwardIosRounded style={{ color: "#495057" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
        <Grid xs={12} container item style={{ paddingBottom: 40 }}>
          <Container>
            <WorkspaceList2
              workspaces={view_workspace}
              onClickWorkspace={(workspace) => {
                this.setState({ openDetailWorkspace: workspace });
              }}
              onUpdatePlan={async (w, plan, start, end) => {
                await this.handlePlan(w, plan, start, end);
                return;
              }}
            />
          </Container>
        </Grid>
        {admin.action !== undefined && (
          <Grid
            xs={12}
            direction="column"
            alignItems="center"
            justify="center"
            container
            item
            style={{
              backgroundColor: "#00000080",
              position: "absolute",
              height: "100%",
            }}
          >
            <CircularProgress style={{ color: "white", fontSize: 30 }} />
            <Typography
              style={{ color: "white", marginTop: 10, marginBottom: 40 }}
            >
              로딩 중...
            </Typography>
          </Grid>
        )}
        {/* 워크스페읏 디테일한 정보 */}
        {this.state.openDetailWorkspace !== undefined && (
          <WorkspaceDetailModal
            workspace={this.state.openDetailWorkspace}
            onClose={() => {
              this.setState({ openDetailWorkspace: undefined });
            }}
          />
        )}
      </ScreenLayout>
    );
  }
}

export default connector(withRouter(WorkspaceScreen));
