import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import {
  doTokenAction,
  TokenActionKind,
  TokenStateStatus,
} from "../store/reducers/token";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ScreenURL from "../routes/path";
import {
  Button,
  Container,
  Grid,
  Typography,
  InputBase,
} from "@material-ui/core";

import CachedIcon from "@material-ui/icons/Cached";
import Theme from "../styles/Theme";
import ScreenLayout from "../utils/templates/ScreenLayout";
import Header from "../component/pure/headers/Header";
import {
  AdminActionKind,
  AdminStateStatus,
  doAdminActionAsync,
} from "../store/reducers/admin";
import { RefinedUserWithLicense } from "@basalt-commons/api/object/license";
import { UserLogObject } from "@basalt-commons/api/object/user_log";
import moment from "moment";
import DatePickers from "../component/pure/DatePicker";
import SimpleMenu from "../component/pure/SimpleMenu";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";
import WebAssetIcon from "@material-ui/icons/WebAsset";

type AccessLog = {
  name: string;
  email: string;
  profie_cid: string;
  access_time?: string;
  app_version?: string;
  platform?: string;
  device?: string;
};

const mapStateToProps = (state: RootState) => {
  return {
    token: state.token,
    admin: state.admin,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {};
type AccessScreenProps = PropsFromRedux & RouteComponentProps<Props>;
type AccessScreenState = {
  accessInfo: AccessLog[];
  beforeDate?: string;
  beforeTime?: string;
  afterDate?: string;
  afterTime?: string;
  version?: string;
  platform?: string;
};

class AccessScreen extends React.Component<
  AccessScreenProps,
  AccessScreenState
> {
  constructor(props: AccessScreenProps) {
    super(props);
    this.state = {
      accessInfo: [],
      beforeDate: undefined,
      beforeTime: undefined,
      afterDate: undefined,
      afterTime: undefined,
      platform: undefined,
      version: undefined,
    };
  }

  async componentDidMount() {
    const { token, history } = this.props;
    if (token.state.status === TokenStateStatus.INIT) {
      history.replace(ScreenURL.LOADING);
      return;
    }
    await doAdminActionAsync(this.props.dispatch, {
      kind: AdminActionKind.TRY_GET_ALL_USERS,
    });
    const users =
      this.props.admin.state.status === AdminStateStatus.SUCCESS
        ? this.props.admin.state.users
        : [];
    const infos =
      this.props.admin.state.status === AdminStateStatus.SUCCESS
        ? this.props.admin.state.visitLog
        : undefined;
    const logs = this.getAccessInfo(users, infos);
    this.setState({ accessInfo: logs });
  }

  onLogout() {
    const { history } = this.props;
    doTokenAction(
      this.props.dispatch,
      {
        kind: TokenActionKind.TRY_LOGOUT,
      },
      () => {
        history.replace(ScreenURL.LOADING);
        return;
      },
      () => {
        alert("로그아웃 실패");
      }
    );
  }

  getAccessInfo(
    users: RefinedUserWithLicense[],
    info?: UserLogObject[]
  ): AccessLog[] {
    const logs = info === undefined ? [] : info;
    let accessLogs: AccessLog[] = [];
    logs.map((l) => {
      const user = users.find((u) => u.id === l.UserId);
      if (user !== undefined) {
        const log: AccessLog = {
          name: user.name,
          email: user.email,
          profie_cid: user.profile_cid,
          app_version: l.app_version,
          device: l.device,
          platform: l.platformOS,
          access_time: l.createdAt,
        };
        accessLogs.push(log);
      }
    });
    return accessLogs.reverse();
  }

  filteredUsersInfo() {
    const {
      accessInfo,
      beforeTime,
      beforeDate,
      afterTime,
      afterDate,
      platform,
    } = this.state;
    let filteredInfo = accessInfo;
    if (beforeDate !== undefined) {
      const before =
        beforeTime === undefined
          ? moment(beforeDate)
          : moment(`${beforeDate}T${beforeTime}`);
      filteredInfo = filteredInfo.filter((i) =>
        moment(i.access_time).isAfter(before)
      );
    }
    if (afterDate !== undefined) {
      const after =
        afterTime === undefined
          ? moment(afterDate)
          : moment(`${afterDate}T${afterTime}`);
      filteredInfo = filteredInfo.filter((i) =>
        moment(i.access_time).isBefore(after)
      );
    }
    if (platform !== undefined) {
      filteredInfo = filteredInfo.filter((i) => {
        return i.platform === platform;
      });
    }

    return filteredInfo;
  }

  render() {
    const { token, admin, history } = this.props;
    if (admin.action !== undefined) {
      return <div>LOADING</div>;
    }
    const name =
      token.state.status === TokenStateStatus.SUCCESS
        ? token.state.name
        : "Temp";
    const data = this.filteredUsersInfo();

    return (
      <ScreenLayout
        alignContent="flex-start"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <Header
          curr="access"
          name={name}
          onLogout={() => {
            this.onLogout();
          }}
          // onMakeLog={ ( type ) => {
          //   doLogAction( this.props.dispatch, {
          //     kind: LogActionKind.TRY_MAKE_LOGS,
          //     userType: "goono-admin",
          //     type,
          //     adminEmail: token.state.status === TokenStateStatus.SUCCESS ? token.state.email : undefined
          //   } );
          // } }
          onReplace={(url) => {
            history.replace(url);
          }}
        />
        <Container>
          <Grid
            xs={12}
            container
            item
            style={{ height: 70 }}
            alignItems="center"
            justify="center"
          >
            <Typography
              style={{
                fontWeight: "bold",
                color: "#7251e1",
                fontSize: 14,
                marginRight: 10,
              }}
            >
              접속시간 검색
            </Typography>
            <DatePickers
              defaultDate={moment().subtract(7, "days").toISOString()}
              getDate={(data) => {
                this.setState({
                  beforeDate: moment(data).format("YYYY-MM-DD"),
                });
              }}
            />
            <InputBase
              type="time"
              style={{ fontSize: 14 }}
              value={this.state.beforeTime}
              onChange={(evt) => {
                this.setState({ beforeTime: evt.target.value });
              }}
            />
            <Typography style={{ marginRight: 25, marginLeft: 25 }}>
              {" "}
              ~{" "}
            </Typography>
            <DatePickers
              defaultDate={moment().toISOString()}
              getDate={(data) => {
                this.setState({ afterDate: moment(data).format("YYYY-MM-DD") });
              }}
            />
            <InputBase
              type="time"
              value={this.state.afterTime}
              onChange={(evt) => {
                this.setState({ afterTime: evt.target.value });
              }}
            />
            <Typography
              style={{
                fontWeight: "bold",
                color: "#7251e1",
                marginLeft: 50,
                fontSize: 14,
                marginRight: 10,
              }}
            >
              플랫폼
            </Typography>
            <Typography style={{ marginLeft: 10, fontSize: 14 }}>
              {this.state.platform === undefined ? "전부" : this.state.platform}
            </Typography>
            <SimpleMenu
              titles={["전부", "android", "ios", "web"]}
              onClicks={[
                () => {
                  this.setState({ platform: undefined });
                },
                () => {
                  this.setState({ platform: "android" });
                },
                () => {
                  this.setState({ platform: "ios" });
                },
                () => {
                  this.setState({ platform: "web" });
                },
              ]}
            />
            <Button
              onClick={() => {
                this.setState({
                  beforeDate: undefined,
                  beforeTime: undefined,
                  afterDate: undefined,
                  afterTime: undefined,
                  platform: undefined,
                });
              }}
              style={{ backgroundColor: "#7251e1" }}
            >
              <CachedIcon
                style={{ color: "#e9ecef", fontSize: 14, marginRight: 6 }}
              />
              <Typography style={{ color: "#e9ecef", fontSize: 14 }}>
                검색 초기화
              </Typography>
            </Button>
          </Grid>
          <Grid
            xs={12}
            container
            item
            alignContent="flex-start"
            justify="center"
            style={{
              backgroundColor: "white",
              paddingLeft: 20,
              borderRadius: 10,
              paddingTop: 20,
              ...Theme.box_shadow,
            }}
          >
            {data.map((log) => {
              return (
                <Grid
                  key={log.access_time}
                  xs={12}
                  container
                  item
                  direction="row"
                  style={{ height: 45 }}
                  alignItems="center"
                >
                  <Typography style={{ width: "15%", fontSize: 14 }}>
                    <span
                      role="img"
                      aria-label="writing hand"
                      style={{ marginRight: 8 }}
                    >
                      🕐
                    </span>
                    {moment(log.access_time).format("YYYY. MM. DD HH:mm")}
                  </Typography>
                  <Typography style={{ width: "20%", fontSize: 14 }}>
                    {log.name}
                  </Typography>

                  <Typography style={{ width: "25%", fontSize: 14 }}>
                    {log.email}
                  </Typography>
                  <Typography style={{ width: "15%", fontSize: 14 }}>
                    {log.platform === "android" && (
                      <AndroidIcon
                        style={{
                          fontSize: 16,
                          marginRight: 6,
                          color: "#2f9e44",
                        }}
                      />
                    )}
                    {log.platform === "ios" && (
                      <AppleIcon
                        style={{
                          fontSize: 16,
                          marginRight: 6,
                          color: "#868e96",
                        }}
                      />
                    )}
                    {log.platform === "web" && (
                      <WebAssetIcon
                        style={{
                          fontSize: 16,
                          marginRight: 6,
                          color: "#3578ea",
                        }}
                      />
                    )}
                    {log.platform}
                  </Typography>
                  <Typography style={{ width: "15%", fontSize: 14 }}>
                    {log.device}
                  </Typography>
                  <Typography style={{ width: "10%", fontSize: 14 }}>
                    {log.app_version}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Container>
        <div style={{ width: "100%", height: 50 }} />
      </ScreenLayout>
    );
  }
}

export default connector(withRouter(AccessScreen));
