import { Grid, IconButton, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export type ItemColorType = {
  selected?: string;
  background?: string;
};

export type GoonoCalendarProps = {
  lang: "ko" | "en";
  time_zone: string;
  id?: string;
  item_size: number | string;
  item_color: ItemColorType;
  max_date: Date;
  min_date?: Date;
  value: Date;
  onClickDate: (date: Date) => void;
  style?: React.CSSProperties;
};

type GoonoCalendarState = {
  now: Date;
  select: Date;
  month: number;
};

class GoonoCalendar extends React.Component<
  GoonoCalendarProps,
  GoonoCalendarState
> {
  static defaultProps = {
    onClickDate: () => {},
    max_date: undefined,
    value: new Date(),
    lang: "ko",
    item_size: 27,
    item_color: {
      hover: "#f1f3f5",
      selected: "#3578ea",
      background: "white",
    },
  };
  constructor(props: GoonoCalendarProps) {
    super(props);
    this.state = {
      now: moment.tz(props.time_zone).toDate(),
      select: this.props.value,
      month: 0,
    };
  }

  onPrevMonth() {
    this.setState({ month: this.state.month + 1 });
    return;
  }

  onNextMonth() {
    this.setState({ month: this.state.month - 1 });
    return;
  }

  getFormat() {
    let timezone = this.props.time_zone;
    let ymdCountries = ["CN", "JP", "KR", "KP", "TW", "HU", "MN", "LT", "BT"];

    for (let i = 0; i < ymdCountries.length; i++) {
      if (moment.tz.zonesForCountry(ymdCountries[i]).includes(timezone)) {
        return "YYYY.MM";
      }
    }

    return "MM.YYYY";
  }

  getCalendarInfo() {
    const today = moment
      .tz(this.state.now, this.props.time_zone)
      .subtract(this.state.month, "month");
    const startWeek = today.clone().startOf("month").week();
    const endWeek =
      today.clone().endOf("month").week() === 1
        ? 53
        : today.clone().endOf("month").week();

    let calendar: {
      date: moment.Moment;
      day: string;
      isBefored: boolean;
      isToday: boolean;
      isSelected: boolean;
      isAllowed: boolean;
    }[][] = [];
    for (let week = startWeek; week <= endWeek; week++) {
      calendar.push(
        Array(7)
          .fill(0)
          .map((n, i) => {
            const current = today
              .clone()
              .week(week)
              .startOf("week")
              .add(n + i, "day");
            const isToday =
              today.format("YYYYMMDD") === current.format("YYYYMMDD");
            const isSelected =
              moment
                .tz(this.state.select, this.props.time_zone)
                .format("YYYYMMDD") === current.format("YYYYMMDD");
            const isBefored =
              current.get("month") !== today.get("month") ? true : false;
            let isAllowed = true;
            if (this.props.max_date !== undefined)
              isAllowed = moment
                .tz(this.props.max_date, this.props.time_zone)
                .isSameOrAfter(current);
            if (this.props.min_date !== undefined)
              isAllowed = moment
                .tz(this.props.min_date, this.props.time_zone)
                .isSameOrBefore(current);
            return {
              date: current,
              day: current.format("D"),
              isBefored,
              isToday,
              isSelected,
              isAllowed,
            };
          })
      );
    }
    return calendar;
  }

  getItemStyle() {
    const background =
      this.props.item_color.background !== undefined
        ? this.props.item_color.background
        : "white";
    const selected =
      this.props.item_color.selected !== undefined
        ? this.props.item_color.selected
        : "#3578ea";
    return { background, selected };
  }

  onClickDate(date: Date) {
    this.setState({ select: date }, () => {
      this.props.onClickDate(date);
      return;
    });
  }

  renderCalendar() {
    const date = this.getCalendarInfo();
    const itemStyle = this.getItemStyle();

    return (
      <Grid
        id="goono-calendar"
        container
        item
        style={{ width: "100%", marginBottom: 2 }}
      >
        {date.map((week) => {
          return (
            <Grid
              id="goono-calendar"
              container
              direction="row"
              item
              style={{ margin: 1 }}
            >
              {week.map((day) => {
                const backgroundColor =
                  day.isSelected === true
                    ? itemStyle.selected
                    : itemStyle.background;
                const color =
                  day.isAllowed === true
                    ? day.isSelected === true
                      ? "white"
                      : "#212529"
                    : "#adb5bd";
                return (
                  <Grid id="goono-calendar" container item style={{ flex: 1 }}>
                    {day.isBefored === false && (
                      <IconButton
                        id="goono-calendar"
                        onClick={() => {
                          if (day.isAllowed === false) return;
                          this.onClickDate(new Date(day.date.toISOString()));
                        }}
                        style={{
                          width: this.props.item_size,
                          height: this.props.item_size,
                          backgroundColor,
                          margin: 1,
                        }}
                      >
                        <Typography
                          id="goono-calendar"
                          style={{ color, fontSize: 14, letterSpacing: -0.6 }}
                        >
                          {day.day}
                        </Typography>
                      </IconButton>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    );
  }

  render() {
    const weeks =
      this.props.lang === "ko"
        ? ["일", "월", "화", "수", "목", "금", "토"]
        : ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const headerDate = moment(this.state.now)
      .subtract(this.state.month, "month")
      .format("YYYY. MM");
    const isAllowNextMonth =
      this.props.max_date === undefined
        ? true
        : moment(this.props.max_date).format("YYYY. MM") !== headerDate;
    return (
      <Grid
        id="goono-calendar"
        alignContent="flex-start"
        container
        item
        style={{ width: "100%", height: "100%", ...this.props.style }}
      >
        <Grid id="goono-calendar" xs={12} container item>
          <Grid id="goono-calendar" xs={2} container item>
            <IconButton
              id="goono-calendar"
              onClick={() => {
                this.onPrevMonth();
              }}
            >
              <ArrowBackIosIcon
                id="goono-calendar"
                style={{ fontSize: 12, color: "#212529" }}
              />
            </IconButton>
          </Grid>
          <Grid
            id="goono-calendar"
            xs={8}
            container
            item
            justify="center"
            alignItems="center"
          >
            <Typography
              id="goono-calendar"
              style={{ fontSize: 14, color: "#495057", letterSpacing: -0.6 }}
            >
              {headerDate} (KST)
            </Typography>
          </Grid>
          <Grid id="goono-calendar" xs={2} container item justify="flex-end">
            {isAllowNextMonth && (
              <IconButton
                id="goono-calendar"
                onClick={() => {
                  this.onNextMonth();
                }}
              >
                <ArrowForwardIosIcon
                  id="goono-calendar"
                  style={{ fontSize: 12, color: "#212529" }}
                />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid
          id="goono-calendar"
          container
          item
          direction="row"
          style={{ width: "100%" }}
        >
          {weeks.map((day) => {
            return (
              <Grid
                id="goono-calendar"
                container
                item
                justify="center"
                alignItems="center"
                style={{ flex: 1 }}
              >
                <Typography
                  id="goono-calendar"
                  style={{ fontSize: 12, color: "#495057" }}
                >
                  {day}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        {this.renderCalendar()}
      </Grid>
    );
  }
}

export default GoonoCalendar;
