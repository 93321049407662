import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { Dispatch } from "redux";
import {
  LogState,
  LogAction,
  LogActionKind,
  LogStateStatus,
  LogStateMachineType,
  logStateMachine,
} from "../../store/reducers/log";
import { TokenStateStatus } from "../../store/reducers/token";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import { mkErr, InternalErrorKind } from "@redwit-commons/utils/exception2";
import Services from "@basalt-react-commons/services";

const { UserLogService } = Services;
const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.log,
    token: state.token,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type LogContainerProps = PropsFromRedux & { stateMachine: LogStateMachineType };

class FileContainer extends ReduxStateComponent3<LogContainerProps> {
  static defaultProps = {
    stateMachine: logStateMachine,
  };
  constructor(props: LogContainerProps) {
    super(props);
  }

  protected async onAction(
    prevState: LogState,
    action: LogAction
  ): Promise<LogState> {
    const { token } = this.props;
    if (token.state.status !== TokenStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "FileContainer::onAction",
        msg: "token state is unvalid",
      });
    const userToken = token.state.token;
    switch (action.kind) {
      case LogActionKind.TRY_GET_MORE_LOGS: {
        // const ret = await this.guardAwait( () => AdminService.getAdminLogs( userToken, { afterAt: action.afterAt, beforeAt: action.beforeAt, fetchSize: action.fetchSize } ) );
        // const logs = ret.response.results;
        // const prevLogs = prevState.status === LogStateStatus.SUCCESS_GET_LOGS ? [ ...prevState.adminLogs ] : [];

        // const filteredLogs = prevLogs.filter( ( log ) => {
        //   let isDuplicated = false;
        //   logs.map( ( aL ) => {
        //     if ( aL.id === log.id ) isDuplicated = true;
        //   } );
        //   return !isDuplicated;
        // } );
        // const adminLogs = [ ...logs, ...filteredLogs ].sort( ( a, b ) => {
        //   let aDate = moment( a.createdAt ).toDate().getTime();
        //   let bDate = moment( b.createdAt ).toDate().getTime();
        //   return aDate > bDate ? - 1 : 1;
        // } )
        // const newLogs = adminLogs;
        // let prevType: string | undefined = undefined;
        // const concurrencyLogs = newLogs.map( ( log ) => {
        //   if ( prevType === log.type ) {
        //     return { ...log, concurrency: true };
        //   } else {
        //     prevType = log.type;
        //     return log;
        //   }
        // } );
        return { status: LogStateStatus.SUCCESS_GET_LOGS, adminLogs: [] };
      }
      case LogActionKind.TRY_GET_ADMIN_LOGS: {
        // const { date } = action;
        // const ret = await this.guardAwait( () => AdminService.getAdminLogs( userToken, { fetchSize: 100 } ) );
        console.log(action.beforeAt, action.afterAt);
        // const ret = await this.guardAwait( () => AdminService.getAdminLogs( userToken, { afterAt: action.afterAt, beforeAt: action.beforeAt, fetchSize: action.fetchSize } ) );
        // const logs = ret.response.results;
        // const adminLogs = logs.sort( ( a, b ) => {
        //   let aDate = moment( a.createdAt ).toDate().getTime();
        //   let bDate = moment( b.createdAt ).toDate().getTime();
        //   return aDate > bDate ? - 1 : 1;
        // } )
        return { status: LogStateStatus.SUCCESS_GET_LOGS, adminLogs: [] };
      }
      case LogActionKind.TRY_MAKE_LOGS: {
        await this.guardAwait(() =>
          UserLogService.createUserLog(userToken, { ...action })
        );
        return prevState;
      }
    }
  }
}

export default connector(FileContainer);
