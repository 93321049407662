import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";

// import { Overrides } from '@material-ui/core/styles/overrides';
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module "@material-ui/core/styles/overrides" {
  // XXX 더 좋은 방법은 없을까...
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
  /* eslint-enable @typescript-eslint/no-empty-interface */
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 150,
      fontSize: 14,
      borderBottom: "0px solid #ffffff",
    },
  })
);

const muiTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        height: 30,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        backgroundColor: "#1450b8",
      },
      toolbarLandscape: {
        color: "black",
      },
    },
    MuiTypography: {
      root: {
        fontSize: 10,
        color: "black",
      },
    },
    MuiPickersDay: {
      day: {
        color: lightBlue.A700,
      },
      daySelected: {
        backgroundColor: lightBlue["400"],
      },
      dayDisabled: {
        color: lightBlue["100"],
      },
      current: {
        color: lightBlue["900"],
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue["400"],
      },
    },
  },
});

export function GoonoDatePickers(
  _props: {
    getDate: (date: string) => void;
    defaultDate: string | undefined;
  } = {
    getDate: (date) => {
      console.log("onChange", date);
    },
    defaultDate: "",
  }
) {
  const [date, setDate] = useState<Date | null>(new Date());
  return (
    <div style={{ border: "1px solid black" }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={muiTheme}>
          <DatePicker
            style={{ backgroundColor: "white", borderRadius: 10 }}
            value={date}
            onChange={(event) => {
              setDate(event);
            }}
            variant="static"
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default function DatePickers(
  props: {
    getDate: (date: string) => void;
    defaultDate: string | undefined;
  } = {
    getDate: (date) => {
      console.log("onChange", date);
    },
    defaultDate: "",
  }
) {
  const classes = useStyles();
  const { defaultDate, getDate } = props;
  const [date, setDate] = useState(moment(defaultDate).format("YYYY-MM-DD"));
  return (
    <form className={classes.container} noValidate>
      <TextField
        type="date"
        value={date}
        onChange={(e) => {
          setDate(e.target.value);
          getDate(e.target.value);
        }}
        className={classes.textField}
        style={{ fontSize: 14 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>
  );
}
