import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import {
  getDefaultLicense,
  getDateFormat,
  getDateTimeFormat,
  getPlanName,
  getTypeName,
  getRoleName,
} from "../../utils/data-validator";
import { RefinedUserWithLicense } from "@basalt-commons/api/object/license";
import { UserLogObject } from "@basalt-commons/api/object/user_log";
type UserListProps = {
  users: RefinedUserWithLicense[];
  isSelect: boolean;
  getUserLogs?: (userId: string) => UserLogObject[];
  onRowSelected: (select: RefinedUserWithLicense, isSelected: boolean) => void;
  selectModel: React.Key[];
};

class UserList extends React.PureComponent<UserListProps> {
  static defaultProps = {
    isSelect: false,
    onRowSelected: () => {},
    selectModel: [],
  };
  constructor(props: UserListProps) {
    super(props);
  }

  private dataColumns = [
    { headerName: "이름", field: "name" },
    { headerName: "이메일", field: "email", width: 250 },
    { headerName: "기관", field: "school", width: 200 },
    { headerName: "학과", field: "department", width: 200 },
    { headerName: "가입 날짜", field: "created_at", width: 250 },
    { headerName: "플랜(무료/표준)", field: "l_plan", width: 80 },
    { headerName: "타입(개인/기관)", field: "l_type", width: 80 },
    { headerName: "권한", field: "l_role" },
    { headerName: "시작 날짜", field: "l_start", width: 110 },
    { headerName: "종료 날짜", field: "l_end", width: 110 },
    { headerName: "비고", field: "l_desc" },
  ];

  private dataColumnsWConnect = [
    { headerName: "최근 접속", field: "l_visit", width: 160 },
    { headerName: "접속 정보", field: "l_device", width: 100 },
  ];

  private pageSize = 20;

  onRowSelected(id: React.Key, isSelected: boolean) {
    const target = this.props.users.find((u) => u.id === id);
    if (target === undefined) return;
    this.props.onRowSelected(target, isSelected);
  }

  render() {
    const { users, isSelect, selectModel, getUserLogs } = this.props;
    const rowUsers = users.map((u) => {
      const license = getDefaultLicense(u);
      const licenseStart = getDateFormat(license.start);
      const licneseEnd = getDateFormat(license.end);
      const start = licenseStart === licneseEnd ? "" : licenseStart;
      const end = licenseStart === licneseEnd ? "" : licneseEnd;
      let row = {
        ...u,
        created_at: getDateTimeFormat(u.createdAt),
        l_start: start,
        l_end: end,
        l_plan: getPlanName(license.plan),
        l_type: getTypeName(license.type),
        l_role: getRoleName(license.role),
        l_desc: license.description,
      };
      if (getUserLogs === undefined) {
        return row;
      }
      const visitlogs = getUserLogs(u.id);
      const visit = visitlogs[visitlogs.length - 1];
      return {
        ...row,
        l_visit:
          visit && visit.createdAt
            ? getDateTimeFormat(visit.createdAt)
            : undefined,
        l_device: visit && visit.device ? visit.device : undefined,
      };
    });
    return (
      <DataGrid
        checkboxSelection={isSelect}
        onRowSelected={(params) => {
          this.onRowSelected(params.data.id, params.isSelected);
        }}
        selectionModel={selectModel}
        rows={rowUsers}
        columns={
          this.props.getUserLogs === undefined
            ? this.dataColumns
            : this.dataColumns.concat(this.dataColumnsWConnect)
        }
        pageSize={this.pageSize}
      />
    );
  }
}

export default UserList;
