// @flow
import React from "react";
import TokenContainer from "./TokenContainer";
import FileContainer from "./FileContainer";
import AdminContainer from "./AdminContainer";
import LogContainer from "./LogContainer";

// 다양한 container 들을 포함하도록 감싸줌.
export default class ContainerProvider extends React.PureComponent {
  render() {
    return (
      <>
        <TokenContainer />
        <FileContainer />
        <AdminContainer />
        <LogContainer />
        {this.props.children}
      </>
    );
  }
}
