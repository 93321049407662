import React from "react";
import {
  Grid,
  Typography,
  Collapse,
  Box,
  IconButton,
  Button,
  Radio,
} from "@material-ui/core";
import { WorkspaceWithPaymentInfo } from "@basalt-commons/global-api/object/admin";
import theme from "../../../styles/Theme";
import { WorkspaceRoleType } from "@basalt-commons/global-api/object/user_workspace_map";
import moment from "moment";
import { WorkspacePlanType } from "@basalt-commons/global-api/object/workspace_plan";
import { getIPFSUrl } from "@basalt-react-commons/services/ipfs";
import { CloseRounded, MoreVertRounded } from "@material-ui/icons";
import TimeZone from "../buttons/TimeZone";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CalendarButton from "../calendar/CalendarButton";

export type WorkspaceItemProps = {
  onClickWorkspace: (workspace: WorkspaceWithPaymentInfo) => void;
  onUpdatePlan: (
    workspace: WorkspaceWithPaymentInfo,
    newPlan?: WorkspacePlanType,
    newStart?: string,
    newEnd?: string
  ) => Promise<void>;
};

type Props = {
  workspace: WorkspaceWithPaymentInfo;
} & WorkspaceItemProps;

type WorkspaceItemtState = {
  open: boolean;
  hover: boolean;
  planOpen: boolean;
  updatePlan?: WorkspacePlanType;
  time_zone: string;
  updateStart?: Date;
  updateEnd?: Date;
};

class WorkspaceItem extends React.PureComponent<Props, WorkspaceItemtState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      hover: false,
      planOpen: false,
      updatePlan: props.workspace.WorkspacePlan?.plan,
      time_zone: moment.tz.guess(),
      updateStart: undefined,
      updateEnd: undefined,
    };
  }

  getPlanName(plan?: WorkspacePlanType) {
    if (plan === undefined) return "플랜 없음";
    switch (plan) {
      case WorkspacePlanType.TRIAL:
        return "무료체험 진행 중";
      case WorkspacePlanType.INDIVIDUAL:
        return "INDIVIDUAL 플랜";
      case WorkspacePlanType.TEAM:
        return "TEAM 플랜";
      case WorkspacePlanType.ENTERPRISE:
        return "Enterprise 플랜";
    }
  }

  renderPlanRadio(plan: WorkspacePlanType) {
    return (
      <Grid container item xs={12} alignItems="center">
        <Radio
          checked={this.state.updatePlan === plan}
          onChange={() => {
            this.setState({ updatePlan: plan });
          }}
          value={plan}
          name="radio-buttons"
          inputProps={{ "aria-label": "A" }}
          style={{ color: "#4c6ef5" }}
        />
        <Typography style={{ fontSize: 14 }}>
          {this.getPlanName(plan)}
        </Typography>
      </Grid>
    );
  }

  cancelUpdate() {
    this.setState({
      updatePlan: this.props.workspace.WorkspacePlan?.plan,
      updateStart: undefined,
      updateEnd: undefined,
    });
    return;
  }
  checkDisable() {
    const workspace = this.props.workspace;
    const { updatePlan, updateStart, updateEnd } = this.state;
    if (
      workspace.WorkspacePlan?.plan === updatePlan &&
      updateStart === undefined &&
      updateEnd === undefined
    )
      return true;

    return false;
  }
  render() {
    const { workspace, onClickWorkspace } = this.props;
    const owner = workspace.Users.find(
      (user) => user.roleType === WorkspaceRoleType.OWNER
    );
    const hoverStyle =
      this.state.hover === true
        ? { marginTop: -2, boxShadow: "2px 3px 10px 2px rgba(0, 0, 0, 0.1)" }
        : { ...theme.box_shadow, marginTop: 0 };
    return (
      <Grid
        onMouseEnter={() => {
          this.setState({ hover: true });
        }}
        onMouseLeave={() => {
          this.setState({ hover: false });
        }}
        onClick={() => {
          this.setState({ open: true });
        }}
        key={`${workspace.id}::${workspace.name}`}
        container
        item
        xs={12}
        alignItems="center"
        style={{
          width: "100%",
          padding: 16,
          borderRadius: 10,
          backgroundColor: "white",
          marginBottom: 10,
          ...hoverStyle,
          cursor: "pointer",
        }}
      >
        <Grid container item xs={1} style={{ paddingLeft: 8 }}>
          {workspace.workspace_cid !== undefined && (
            <img
              src={getIPFSUrl(workspace.workspace_cid)}
              style={{ width: 44, height: 44, borderRadius: 10 }}
            />
          )}
          {workspace.workspace_cid === undefined && (
            <div
              style={{
                width: 44,
                height: 44,
                borderRadius: 10,
                backgroundColor: "#f1f3f5",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{ fontSize: 6, textAlign: "center", color: "#868e96" }}
              >
                이미지
              </Typography>
              <Typography
                style={{ fontSize: 6, textAlign: "center", color: "#868e96" }}
              >
                없음
              </Typography>
            </div>
          )}
        </Grid>
        <Grid container item xs={5}>
          <Typography style={{ fontSize: 16 }}>{workspace.name}</Typography>

          <Grid container item xs={12}>
            <Typography style={{ fontSize: 12 }}>
              {owner?.name} ({owner?.email})
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={5}>
          <Grid container item xs={12}>
            <Grid container item xs={4}>
              <Typography style={{ fontSize: 14 }}>
                {workspace.Users.length} 명 참여중
              </Typography>
            </Grid>
            <Grid container item xs={4}>
              <Typography style={{ fontSize: 14 }}>
                {this.getPlanName(workspace.WorkspacePlan?.plan)}
              </Typography>
            </Grid>
            <Grid container item xs={4}>
              <Typography style={{ fontSize: 14 }}>
                {moment(workspace.createdAt).format("YYYY. MM. DD")} 생성
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={1} justify="flex-end">
          <IconButton
            onClick={(evt) => {
              evt.stopPropagation();
              onClickWorkspace(workspace);
            }}
            style={{ width: 44, height: 44, backgroundColor: "#f8f9fa" }}
          >
            <MoreVertRounded style={{ fontSize: 30, color: "#adb5bd" }} />
          </IconButton>
        </Grid>
        <Collapse
          in={this.state.open}
          timeout={"auto"}
          unmountOnExit
          style={{ width: "100%" }}
        >
          <Box>
            <Grid
              xs={12}
              container
              item
              style={{
                marginTop: 16,
                paddingTop: 15,
                paddingBottom: 15,
                borderTop: "1px solid #e9ecef",
              }}
            >
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                style={{ width: "100%" }}
              >
                <Grid container item xs={9}>
                  <Typography style={{ fontSize: 16, fontWeight: 600 }}>
                    워크스페이스 플랜/크레딧
                  </Typography>
                </Grid>
                <Grid container item xs={3} justify="flex-end">
                  <IconButton
                    onClick={(evt) => {
                      evt.stopPropagation();
                      this.setState({ open: false });
                    }}
                  >
                    <CloseRounded />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                style={{
                  padding: 24,
                  borderRadius: 10,
                  width: "100%",
                  backgroundColor: "#f1f3f5",
                }}
              >
                <Grid container item style={{ width: "100%" }} xs={12}>
                  <Grid container item style={{ width: "100%" }} xs={4}>
                    <Typography style={{ fontSize: 12 }}>
                      현재 사용중인 플랜
                    </Typography>
                  </Grid>
                  <Grid container item style={{ width: "100%" }} xs={4}>
                    <Typography style={{ fontSize: 12 }}>
                      현재 보유 크레딧
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item style={{ width: "100%" }} xs={12}>
                  <Grid container item style={{ width: "100%" }} xs={4}>
                    <Grid container item xs={12}>
                      <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
                        {this.getPlanName(workspace.WorkspacePlan?.plan)}
                      </Typography>
                      {workspace.WorkspacePlan !== undefined && (
                        <Typography
                          style={{
                            fontSize: 12,
                            marginTop: 4,
                            color: "#868e96",
                            marginLeft: 8,
                          }}
                        >
                          {moment(workspace.WorkspacePlan.start).format(
                            "YYYY. MM. DD"
                          )}{" "}
                          ~{" "}
                          {moment(workspace.WorkspacePlan.end).format(
                            "YYYY. MM. DD"
                          )}
                        </Typography>
                      )}
                    </Grid>
                    <Grid container item xs={12}>
                      <Button
                        disabled={true}
                        style={{
                          marginTop: 8,
                          paddingTop: 8,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          paddingRight: 16,
                          backgroundColor: "white",
                          borderRadius: 10,
                        }}
                      >
                        <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                          플랜 변경하기
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container item style={{ width: "100%" }} xs={4}>
                    <Grid container item xs={6}>
                      <Grid container item xs={12}>
                        <Typography
                          style={{ fontSize: 16, fontWeight: "bold" }}
                        >
                          {workspace.WorkspacePlan.currency === "KRW"
                            ? workspace.krw_credit
                            : workspace.usd_credit}
                        </Typography>
                      </Grid>
                      <Grid container item xs={12}>
                        <Button
                          disabled={true}
                          style={{
                            marginTop: 8,
                            paddingTop: 8,
                            paddingBottom: 8,
                            paddingLeft: 16,
                            paddingRight: 16,
                            backgroundColor: "white",
                            borderRadius: 10,
                          }}
                        >
                          <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                            크레딧 추가하기
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item style={{ width: "100%" }} xs={4}>
                    <Grid container item xs={12}>
                      <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
                        플랜 표준 시간대 변경하기:
                      </Typography>
                      <TimeZone
                        onClick={(zone: string) => {
                          const newStart = new Date(
                            moment
                              .tz(this.state.updateStart, zone)
                              .toISOString()
                          );
                          const newEnd = new Date(
                            moment.tz(this.state.updateEnd, zone).toISOString()
                          );
                          this.setState({
                            time_zone: zone,
                            updateStart: newStart,
                            updateEnd: newEnd,
                          });
                        }}
                        curr_time_zone={this.state.time_zone}
                      />
                    </Grid>

                    <Grid container item xs={12}>
                      <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                        플랜 시작 기간 변경하기:
                      </Typography>
                      <Grid
                        xs={12}
                        container
                        item
                        style={{ width: "100%", height: 40, marginTop: 10 }}
                      >
                        <Grid
                          container
                          item
                          direction="column"
                          justify="center"
                          style={{ width: "100%", height: 40 }}
                        >
                          <Button
                            onClick={() => {
                              const date = new Date(
                                moment(this.state.updateStart)
                                  .subtract(1, "day")
                                  .toISOString()
                              );
                              this.setState({ updateStart: date });
                            }}
                          >
                            <ArrowBackIosIcon style={{ color: "#212529" }} />
                          </Button>
                          <CalendarButton
                            time_zone={this.state.time_zone}
                            lang="ko"
                            value={this.state.updateStart}
                            onClickDate={(date) => {
                              this.setState({ updateStart: date });
                            }}
                          />
                          <Button
                            onClick={() => {
                              const date = new Date(
                                moment(this.state.updateStart)
                                  .add(1, "day")
                                  .toISOString()
                              );
                              this.setState({ updateStart: date });
                            }}
                          >
                            <ArrowForwardIosIcon style={{ color: "#212529" }} />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12}>
                      <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                        플랜 만료 기간 변경하기:
                      </Typography>
                      <Grid
                        xs={12}
                        container
                        item
                        style={{ width: "100%", height: 40, marginTop: 10 }}
                      >
                        <Grid
                          container
                          item
                          direction="column"
                          justify="center"
                          style={{ width: "100%", height: 40 }}
                        >
                          <Button
                            onClick={() => {
                              const date = new Date(
                                moment(this.state.updateEnd)
                                  .subtract(1, "day")
                                  .toISOString()
                              );
                              this.setState({ updateEnd: date });
                            }}
                          >
                            <ArrowBackIosIcon style={{ color: "#212529" }} />
                          </Button>
                          <CalendarButton
                            time_zone={this.state.time_zone}
                            lang="ko"
                            value={this.state.updateEnd}
                            onClickDate={(date) => {
                              this.setState({ updateEnd: date });
                            }}
                          />
                          <Button
                            onClick={() => {
                              const date = new Date(
                                moment(this.state.updateEnd)
                                  .add(1, "day")
                                  .toISOString()
                              );
                              this.setState({ updateEnd: date });
                            }}
                          >
                            <ArrowForwardIosIcon style={{ color: "#212529" }} />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.state.planOpen === false && (
                    <Grid
                      container
                      item
                      xs={12}
                      style={{ paddingTop: 16, paddingBottom: 16 }}
                    >
                      <Grid container item xs={4} style={{ marginBottom: 20 }}>
                        {this.renderPlanRadio(WorkspacePlanType.TRIAL)}
                        {this.renderPlanRadio(WorkspacePlanType.INDIVIDUAL)}
                        {this.renderPlanRadio(WorkspacePlanType.TEAM)}
                        {/* ENTERPRISE 플랜은 결제 로직 완성 후 활성화 예정 */}
                        {/* { this.renderPlanRadio( WorkspacePlanType.ENTERPRISE ) } */}
                      </Grid>
                      <Grid container item xs={4} style={{ marginBottom: 20 }}>
                        <Grid container item xs={12} alignItems="center">
                          * 아직 지원하지 않는 기능입니다
                        </Grid>
                      </Grid>
                      <Grid container item xs={4} style={{ marginBottom: 20 }}>
                        {/* 비어있는 공간 입니다 */}
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={10}>
                          <Typography
                            style={{ fontWeight: "bold", fontSize: 14 }}
                          >
                            변경할 내역:
                          </Typography>
                          {workspace.WorkspacePlan?.plan !==
                            this.state.updatePlan && (
                            <Typography style={{ fontSize: 14 }}>
                              {`새 플랜: ${this.getPlanName(
                                this.state.updatePlan
                              )}`}
                            </Typography>
                          )}
                          {this.state.updateStart !== undefined && (
                            <Typography style={{ fontSize: 14 }}>
                              {`새 시작 기간: ${moment(
                                this.state.updateStart
                              ).format("YYYY. MM. DD")}`}
                            </Typography>
                          )}
                          {this.state.updateEnd !== undefined && (
                            <Typography style={{ fontSize: 14 }}>
                              {`새 만료 기간: ${moment(
                                this.state.updateEnd
                              ).format("YYYY. MM. DD")}`}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={1}>
                          <Button
                            onClick={() => this.cancelUpdate()}
                            style={{
                              paddingLeft: 8,
                              paddingRight: 8,
                              height: 36,
                            }}
                          >
                            <Typography
                              style={{ fontWeight: "bold", fontSize: 14 }}
                            >
                              변경 취소
                            </Typography>
                          </Button>
                        </Grid>
                        <Grid item xs={1}>
                          <Button
                            onClick={async (evt) => {
                              evt.stopPropagation();
                              await this.props.onUpdatePlan(
                                workspace,
                                this.state.updatePlan,
                                this.state.updateStart?.toISOString(),
                                this.state.updateEnd?.toISOString()
                              );
                              this.setState({ open: false });
                              this.cancelUpdate();
                              return;
                            }}
                            disabled={this.checkDisable()}
                            style={{
                              paddingLeft: 8,
                              paddingRight: 8,
                              height: 36,
                              fontSize: 14,
                              backgroundColor: "#4c6ef5",
                              opacity: this.checkDisable() ? 0.4 : undefined,
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "white",
                                fontSize: 14,
                              }}
                            >
                              변경 저장
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Grid>
    );
  }
}

export default WorkspaceItem;
