import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import {
  TokenActionKind,
  TokenStateStatus,
  doTokenAction,
} from "../store/reducers/token";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ScreenURL from "../routes/path";
import {
  Grid,
  Typography,
  Button,
  Container,
  InputBase,
} from "@material-ui/core";
import styles from "../styles/LoginStyles";
import Theme from "../styles/Theme";
import ScreenLayout from "../utils/templates/ScreenLayout";
import { ReactComponent as LogoIcon } from "../assets/images/logo.svg";

const mapStateToProps = (state: RootState) => {
  return {
    token: state.token,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {};
type LoginScreenProps = PropsFromRedux & RouteComponentProps<Props>;
type LoginScreenState = {
  email: string;
  pw: string;
};

class LoginScreen extends React.Component<LoginScreenProps, LoginScreenState> {
  constructor(props: LoginScreenProps) {
    super(props);
    this.state = {
      email: "",
      pw: "",
    };
  }

  componentDidUpdate() {
    const { token, history } = this.props;
    if (token.state.status === TokenStateStatus.SUCCESS) {
      history.replace(ScreenURL.MAIN);
      return;
    }
  }

  onChangeText(
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: "email" | "pw"
  ) {
    if (type === "email") {
      this.setState({ email: evt.target.value });
      return;
    } else {
      this.setState({ pw: evt.target.value });
      return;
    }
  }

  onPressLogin() {
    if (this.state.pw.length < 8) {
      alert("비밀번호를 8자 이상 작성해주세요");
      this.setState({ email: "", pw: "" });
      return;
    }
    console.log(this.state.pw);
    doTokenAction(
      this.props.dispatch,
      {
        kind: TokenActionKind.TRY_LOGIN,
        email: this.state.email,
        pw: this.state.pw,
      },
      () => {
        if (this.props.token.state.status === TokenStateStatus.INIT)
          alert("이메일 확인해주세요!");
        else this.props.history.replace(ScreenURL.MAIN);
      },
      (_) => {
        alert("관리자 권한이 없거나 로그인에 실패하였습니다. Contact to Terra");
        this.setState({ email: "", pw: "" });
        return;
      }
    );
    return;
  }

  onPressGsuite(token: string, email: string) {
    doTokenAction(
      this.props.dispatch,
      {
        kind: TokenActionKind.TRY_LOGIN_GSUITE,
        email,
        token,
      },
      () => {
        if (this.props.token.state.status === TokenStateStatus.INIT)
          alert("이메일 확인해주세요!");
        else this.props.history.replace(ScreenURL.MAIN);
      },
      (_) => {
        alert("관리자 권한이 없거나 로그인에 실패하였습니다. Contact to Terra");
        this.setState({ email: "", pw: "" });
        return;
      }
    );
    return;
  }

  render() {
    return (
      <ScreenLayout style={{ backgroundColor: "#f8f9fa" }}>
        <Container>
          <Grid xs={12} container item direction="column" alignItems="center">
            <Grid
              container
              item
              xs={12}
              md={5}
              direction="column"
              alignItems="center"
              style={{
                ...styles.container,
                ...Theme.box_shadow,
                backgroundColor: "white",
              }}
            >
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justify="center"
                style={{ marginTop: 40 }}
              >
                <LogoIcon style={{ width: 206, height: 44 }} />
              </Grid>
              <Typography style={styles.text}>
                레드윗 관계자 외에는 접근이 불가능합니다
              </Typography>
              <Typography
                style={{ fontSize: 12, color: "#868e96", marginBottom: 20 }}
              >
                서비스 페이지를 가시려면 basalt.so 로 접속해주세요
              </Typography>
              <InputBase
                style={styles.email_field}
                id="email"
                type="email"
                value={this.state.email}
                placeholder="이메일을 작성해주세요"
                onChange={(evt) => {
                  this.onChangeText(evt, "email");
                }}
              />
              <InputBase
                style={styles.pw_field}
                id="password"
                type="password"
                placeholder="비밀번호를 작성해주세요"
                value={this.state.pw}
                onChange={(evt) => {
                  this.onChangeText(evt, "pw");
                }}
              />
              <Button
                onClick={() => {
                  this.onPressLogin();
                }}
                style={styles.login_btn}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  로그인 하기
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </ScreenLayout>
    );
  }
}

export default connector(withRouter(LoginScreen));
