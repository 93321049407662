import React from "react";
import { Grid, IconButton, Typography, Button } from "@material-ui/core";
import { WorkspaceWithPaymentInfo } from "@basalt-commons/global-api/object/admin";
import { CloseRounded } from "@material-ui/icons";
import { getIPFSUrl } from "@basalt-react-commons/services/ipfs";
import { WorkspaceRoleType } from "@basalt-commons/global-api/object/user_workspace_map";
import { WorkspacePlanType } from "@basalt-commons/global-api/object/workspace_plan";
import moment from "moment";

type WorkspaceDetailModalProps = {
  workspace: WorkspaceWithPaymentInfo;
  onClose: () => void;
};

type WorkspaceDetailModalState = {
  tab: "default" | "plan" | "member";
};

class WorkspaceDetailModal extends React.PureComponent<
  WorkspaceDetailModalProps,
  WorkspaceDetailModalState
> {
  constructor(props: WorkspaceDetailModalProps) {
    super(props);
    this.state = {
      tab: "default",
    };
  }

  getRoleType(role: WorkspaceRoleType) {
    switch (role) {
      case WorkspaceRoleType.OWNER:
        return "소유자";
      case WorkspaceRoleType.ADMIN:
        return "관리자";
      case WorkspaceRoleType.MEMBER:
        return "멤버";
      case WorkspaceRoleType.GUEST:
        return "게스트";
    }
  }

  getPlanName(plan?: WorkspacePlanType) {
    if (plan === undefined) return "사용 중인 플랜이 없습니다.";
    switch (plan) {
      case WorkspacePlanType.TRIAL:
        return "무료체험 진행 중";
      case WorkspacePlanType.INDIVIDUAL:
        return "개인 플랜";
      case WorkspacePlanType.TEAM:
        return "팀 플랜";
      case WorkspacePlanType.ENTERPRISE:
        return "Enterprise 플랜";
    }
  }

  getWorkspaceOwner() {
    const { workspace } = this.props;
    const users = workspace.Users;
    return users.find((user) => user.roleType === WorkspaceRoleType.OWNER);
  }

  renderDefaultInfoLayout(title: string, body: string) {
    return (
      <Grid
        container
        item
        xs={12}
        style={{ paddingTop: 12, paddingBottom: 12 }}
      >
        <Grid container item xs={2}>
          <Typography style={{ color: "#495057" }}>{title}</Typography>
        </Grid>
        <Grid container item xs={10}>
          <Typography>{body}</Typography>
        </Grid>
      </Grid>
    );
  }

  renderDefault() {
    const { workspace } = this.props;

    return (
      <>
        <Grid
          container
          item
          xs={12}
          style={{ width: "100%", marginBottom: 32 }}
        >
          <Typography style={{ fontSize: 18, fontWeight: 600 }}>
            워크스페이스 기본 정보
          </Typography>
        </Grid>

        <Grid container item xs={12} style={{ width: "100%" }}>
          {this.renderDefaultInfoLayout("워크스페이스 이름", workspace.name)}
          {this.renderDefaultInfoLayout(
            "생성 날짜",
            moment(workspace.createdAt).format("YYYY. MM. DD")
          )}
          {this.renderDefaultInfoLayout(
            "참여한 멤버 수",
            `${workspace.Users.length} 명`
          )}
          {this.renderDefaultInfoLayout(
            "사용 중인 플랜",
            this.getPlanName(workspace.WorkspacePlan?.plan)
          )}
          {this.renderDefaultInfoLayout(
            "워크스페이스 공개여부",
            workspace.allow_search === true ? "공개" : "비공개"
          )}
          {this.renderDefaultInfoLayout(
            "워크스페이스 크레딧",
            `${
              workspace.WorkspacePlan.currency === "KRW"
                ? workspace.krw_credit
                : workspace.usd_credit
            }`
          )}
          {this.renderDefaultInfoLayout(
            "등록된 카드 개수",
            `${workspace.Cards.length} 개`
          )}
          {this.renderDefaultInfoLayout(
            "결제 진행 횟수",
            `${workspace.Payples.length} 개`
          )}
        </Grid>
      </>
    );
  }

  renderMembers() {
    const { workspace } = this.props;
    return (
      <>
        <Grid
          container
          item
          xs={12}
          style={{ width: "100%", marginBottom: 12 }}
        >
          <Typography style={{ fontSize: 18, fontWeight: 600 }}>
            워크스페이스 내 사용자
          </Typography>
        </Grid>

        <Grid container item xs={12} style={{ width: "100%" }}>
          {workspace.Users.map((user) => {
            return (
              <Grid
                key={`${user.id}`}
                container
                item
                xs={12}
                alignItems="center"
                style={{ marginTop: 4, marginBottom: 8 }}
              >
                <Grid container item xs={4} alignItems="center">
                  <img
                    src={getIPFSUrl(user.profile_cid)}
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: 20,
                      marginRight: 12,
                      objectFit: "cover",
                    }}
                  />
                  <Typography>{user.name}</Typography>
                </Grid>
                <Grid container item xs={4}>
                  <Typography style={{ fontSize: 14, color: "#868e96" }}>
                    {user.email}
                  </Typography>
                </Grid>
                <Grid container item xs={4}>
                  {this.getRoleType(user.roleType)}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  }

  renderPlan() {
    const { workspace } = this.props;
    const workspacePlan = workspace.WorkspacePlan;

    return (
      <>
        <Grid container item xs={12} style={{ width: "100%" }}>
          <Typography style={{ fontSize: 18, fontWeight: 600 }}>
            워크스페이스 플랜/크레딧
          </Typography>
        </Grid>

        <Grid container item xs={12} style={{ width: "100%", marginTop: 16 }}>
          {/* 기본 플랜 정보 */}
          <Grid
            container
            item
            xs={12}
            style={{
              padding: 24,
              borderRadius: 10,
              width: "100%",
              backgroundColor: "#f1f3f5",
            }}
          >
            <Grid container item style={{ width: "100%" }} xs={12}>
              <Grid container item style={{ width: "100%" }} xs={6}>
                <Typography style={{ fontSize: 12 }}>
                  현재 사용중인 플랜
                </Typography>
              </Grid>
              <Grid container item style={{ width: "100%" }} xs={6}>
                <Typography style={{ fontSize: 12 }}>
                  현재 보유 크레딧
                </Typography>
              </Grid>
            </Grid>
            <Grid container item style={{ width: "100%" }} xs={12}>
              <Grid container item style={{ width: "100%" }} xs={6}>
                <Grid container item xs={12}>
                  <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
                    {this.getPlanName(workspacePlan?.plan)}
                  </Typography>
                  {workspacePlan !== undefined && (
                    <Typography
                      style={{
                        fontSize: 12,
                        marginTop: 4,
                        color: "#868e96",
                        marginLeft: 8,
                      }}
                    >
                      {moment(workspacePlan.createdAt).format("YYYY. MM. DD")} ~{" "}
                      {moment(workspacePlan.end).format("YYYY. MM. DD")}
                    </Typography>
                  )}
                </Grid>
                <Grid container item xs={12}>
                  <Button
                    onClick={() => {}}
                    style={{
                      marginTop: 8,
                      paddingTop: 8,
                      paddingBottom: 8,
                      paddingLeft: 16,
                      paddingRight: 16,
                      backgroundColor: "white",
                      borderRadius: 10,
                    }}
                  >
                    <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                      플랜 변경하기
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid container item style={{ width: "100%" }} xs={6}>
                <Grid container item xs={12}>
                  <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
                    {workspace.WorkspacePlan.currency === "KRW"
                      ? workspace.krw_credit
                      : workspace.usd_credit}
                  </Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Button
                    style={{
                      marginTop: 8,
                      paddingTop: 8,
                      paddingBottom: 8,
                      paddingLeft: 16,
                      paddingRight: 16,
                      backgroundColor: "white",
                      borderRadius: 10,
                    }}
                  >
                    <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                      크레딧 추가하기
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 역대 플랜 정보 */}
          <Grid container item xs={12} style={{ width: "100%", marginTop: 24 }}>
            <Grid
              container
              item
              xs={12}
              style={{ width: "100%", marginBottom: 12 }}
            >
              <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                결제 내역
              </Typography>
            </Grid>
            <Grid container item style={{ padding: 12 }}>
              <Grid container xs={12} style={{ width: "100%" }}>
                <Grid container xs={3}>
                  <Typography
                    style={{
                      fontSize: 12,
                      color: "#495057",
                      fontWeight: "bold",
                    }}
                  >
                    결제 정보
                  </Typography>
                </Grid>
                <Grid container xs={3}>
                  <Typography
                    style={{
                      fontSize: 12,
                      color: "#495057",
                      fontWeight: "bold",
                    }}
                  >
                    금액
                  </Typography>
                </Grid>
                <Grid container xs={2}>
                  <Typography
                    style={{
                      fontSize: 12,
                      color: "#495057",
                      fontWeight: "bold",
                    }}
                  >
                    결제 일시
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {workspace.Payples.map((payment) => {
              return (
                <Grid
                  container
                  item
                  style={{ padding: 12, borderBottom: `1px solid #dee2e6` }}
                >
                  <Grid container xs={12} style={{ width: "100%" }}>
                    <Grid container xs={3}>
                      {payment.description}
                    </Grid>
                    <Grid container xs={3}>
                      {payment.totalAmount}
                    </Grid>
                    <Grid container xs={2}>
                      {payment.submitTimeUtc}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </>
    );
  }

  renderTab(
    title: string,
    onClick: () => void,
    emoji?: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    >
  ) {
    return (
      <Button
        onClick={() => {
          onClick();
        }}
        style={{ width: "100%", height: 56 }}
      >
        {emoji}
        <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
          {title}
        </Typography>
      </Button>
    );
  }

  render() {
    const { workspace, onClose } = this.props;
    const owner = this.getWorkspaceOwner();

    return (
      <Grid
        container
        item
        alignItems="center"
        justify="center"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#00000060",
          position: "absolute",
        }}
      >
        <Grid
          container
          item
          xs={10}
          style={{ borderRadius: 9, height: "85%", backgroundColor: "white" }}
        >
          <Grid
            container
            item
            xs={3}
            justify="center"
            alignContent="flex-start"
            style={{
              height: "100%",
              backgroundColor: "#f8f9fa",
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              borderRight: `1px solid #dee2e6`,
              paddingTop: 64,
            }}
          >
            {workspace.workspace_cid !== undefined && (
              <img
                src={getIPFSUrl(workspace.workspace_cid)}
                style={{ width: 120, height: 120, borderRadius: 10 }}
              />
            )}
            {workspace.workspace_cid === undefined && (
              <div
                style={{
                  width: 80,
                  height: 80,
                  borderRadius: 10,
                  backgroundColor: "#bac8ff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  style={{
                    color: "#495057",
                    textAlign: "center",
                    fontSize: 12,
                  }}
                >
                  이미지 없음
                </Typography>
              </div>
            )}
            <Grid
              container
              item
              xs={12}
              style={{ marginTop: 32 }}
              justify="center"
            >
              <Typography style={{ fontSize: 20, fontWeight: 600 }}>
                {workspace.name}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ marginTop: 4 }}
              justify="center"
            >
              <Typography style={{ fontSize: 14, color: "#495057" }}>
                {owner?.name}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ marginTop: 4 }}
              justify="center"
            >
              <Typography style={{ fontSize: 12, color: "#495057" }}>
                ({owner?.email})
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{
                width: "100%",
                marginTop: 42,
                borderTop: `1px solid #f1f3f5`,
              }}
            >
              {this.renderTab(
                "기본 정보",
                () => {
                  this.setState({ tab: "default" });
                },
                <span role="img" style={{ fontSize: 20, marginRight: 6 }}>
                  🛠
                </span>
              )}
              {this.renderTab(
                "워크스페이스 내 멤버",
                () => {
                  this.setState({ tab: "member" });
                },
                <span role="img" style={{ fontSize: 20, marginRight: 6 }}>
                  🧑‍🤝‍🧑
                </span>
              )}
              {this.renderTab(
                "사용 중인 Plan & 크레딧",
                () => {
                  this.setState({ tab: "plan" });
                },
                <span role="img" style={{ fontSize: 20, marginRight: 6 }}>
                  💳
                </span>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={9}
            alignContent="flex-start"
            style={{ padding: 16 }}
          >
            <Grid
              container
              item
              xs={12}
              justify="flex-end"
              style={{ width: "100%" }}
            >
              <IconButton
                onClick={() => {
                  onClose();
                }}
              >
                <CloseRounded style={{ color: "#343a40", fontSize: 20 }} />
              </IconButton>
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ width: "100%", paddingLeft: 24, paddingRight: 24 }}
            >
              {this.state.tab === "default" && this.renderDefault()}
              {this.state.tab === "member" && this.renderMembers()}
              {this.state.tab === "plan" && this.renderPlan()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default WorkspaceDetailModal;
