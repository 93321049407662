import { createStyles } from "@material-ui/core";

const styles = createStyles({
  root: {
    cursor: "pointer",
    width: "100%",
    border: `1px solid #dee2e6`,
    borderRadius: 8,
  },
  timezone_display: {
    cursor: "pointer",
    padding: "0px 16px",
    width: "100%",
    height: 56,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: { fontSize: 16, color: "#212529" },
  open_icon: { fontSize: 16, color: "#495057" },

  collapse_grid: {
    width: "100%",
    padding: 20,
    paddingTop: 10,
    maxHeight: 300,
    overflowY: "hidden",
  },
  search_input: {
    width: "100%",
    height: 44,
    borderRadius: 8,
    border: `1px solid #dee2e6`,
    color: "#212529",
    fontSize: 14,
    paddingLeft: 12,
    paddingRight: 12,
  },

  current_timezone_grid: { width: "100%", height: 44 },
  current_timezone_text: { fontSize: 14, color: "#5c7cfa" },
  current_timezone_icon: {
    color: "#5c7cfa",
    marginRight: 6,
    marginLeft: 4,
  },
});

export default styles;
