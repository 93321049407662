const ScreenURL = {
  LOADING: "/",
  CONTET_MAIN: "/content",
  LOGIN: "/login",
  EDIT: "/edit/:id",
  MAIN: "/main",
  USER_MAIN: "/users",
  LICENSE_LIST: "/license",
  ACCESS: "/access",
  NOTI: "/notice",
  DASHBOARD: "/dashboard",
  WORKSPACE: "/workspaces",
};

export default ScreenURL;
