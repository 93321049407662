import React from "react";
import { MenuItem, Typography } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";

type InfiniteScrollListProps = {
  items: string[];
  onClick: (time_zone: string) => void;
};

type InfiniteScrollListState = {
  hasMore: boolean;
  current: string[];
  count: { prev: number; next: number };
};

class InfiniteScrollList extends React.PureComponent<
  InfiniteScrollListProps,
  InfiniteScrollListState
> {
  constructor(props: InfiniteScrollListProps) {
    super(props);
    this.state = {
      hasMore: true,
      count: { prev: 0, next: 10 },
      current: props.items.slice(0, 10),
    };
  }

  componentDidUpdate(prevState: InfiniteScrollListProps) {
    const { items } = this.props;
    if (prevState.items !== items) {
      this.setState({
        hasMore: items.length > 5,
        count: { prev: 0, next: 10 },
        current: items.slice(0, 10),
      });
    }
  }

  getMoreData = () => {
    const { current, count } = this.state;
    if (current.length === this.props.items.length) {
      this.setState({ hasMore: false });
      return;
    }
    this.setState({
      current: current.concat(
        this.props.items.slice(count.prev + 10, count.next + 10)
      ),
    });
    this.setState({ count: { prev: count.prev + 10, next: count.next + 10 } });
  };

  render() {
    return (
      <>
        <div
          id="scrollableDiv"
          style={{
            height: 210,
            overflow: "auto",
            overflowX: "hidden",
            display: "flex",
            marginTop: 10,
            width: "100%",
          }}
        >
          <InfiniteScroll
            style={{ width: "100%" }}
            dataLength={this.state.current.length}
            next={this.getMoreData}
            hasMore={this.state.hasMore}
            loader={
              <Typography
                style={{
                  fontSize: 14,
                  color: "#212529",
                  marginLeft: 16,
                }}
              >
                로딩 중...
              </Typography>
            }
            scrollableTarget="scrollableDiv"
          >
            <div style={{ width: "100%" }}>
              {this.state.current &&
                this.state.current.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => this.props.onClick(item)}
                    style={{ height: 40, width: "100%" }}
                  >
                    <Typography style={{ fontSize: 14, color: "#212529" }}>
                      {item}
                    </Typography>
                  </MenuItem>
                ))}
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default InfiniteScrollList;
