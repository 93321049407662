import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { RefinedUserWithLicense } from "@basalt-commons/api/object/license";
import moment from "moment";
import { getIPFSUrl } from "@basalt-react-commons/services/ipfs";
import { WorkspaceWithPaymentInfo } from "@basalt-commons/global-api/object/admin";

export type UserItemProps = {
  onClick: (user: RefinedUserWithLicense) => void;
};

type Props = {
  user: RefinedUserWithLicense;
  workspaces: WorkspaceWithPaymentInfo[];
} & UserItemProps;

type UserItemState = {
  hover: boolean;
};

class UserItem extends React.PureComponent<Props, UserItemState> {
  static defaultProps = {
    onClick: () => {},
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  render() {
    const { user, workspaces, onClick } = this.props;
    const backgroundColor = this.state.hover === true ? "#f8f9fa" : "white";
    return (
      <Grid
        onMouseEnter={() => {
          this.setState({ hover: true });
        }}
        onMouseLeave={() => {
          this.setState({ hover: false });
        }}
        onClick={() => {
          onClick(user);
        }}
        container
        item
        xs={12}
        alignItems="center"
        style={{
          cursor: "pointer",
          width: "100%",
          paddingTop: 12,
          paddingBottom: 12,
          backgroundColor,
          borderBottom: `1px solid #f8f9fa`,
        }}
      >
        <Grid container item xs={1}>
          <img
            src={getIPFSUrl(user.profile_cid)}
            style={{
              width: 40,
              height: 40,
              borderRadius: 20,
              objectFit: "cover",
            }}
          />
        </Grid>
        <Grid container item xs={3}>
          <Typography style={{ fontSize: 16 }}>{user.name}</Typography>
        </Grid>
        <Grid container item xs={3}>
          <Typography style={{ fontSize: 14, wordBreak: "break-all" }}>
            {user.email}
          </Typography>
        </Grid>
        <Grid container item xs={1}>
          {workspaces.length}개
        </Grid>
        <Grid container item xs={2}>
          <Typography style={{ fontSize: 14 }}>{user.time_zone}</Typography>
        </Grid>
        <Grid container item xs={2}>
          <Typography style={{ fontSize: 14 }}>
            {moment(user.createdAt).format("YYYY. MM. DD")}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default UserItem;
