import { Button, withStyles, WithStyles, Typography } from "@material-ui/core";
import React from "react";
import GoonoCalendar, { GoonoCalendarProps } from "./Calendar";
import moment from "moment";
import styles from "./styles/CalendarButton";

type CalendarButtonProps = {
  mobileMode?: boolean;
  buttonElem?: React.Component;
  title?: string;
  placeHolder?: string;
  popover: boolean;
  value?: Date;
} & Omit<GoonoCalendarProps, "value"> &
  WithStyles<typeof styles>;

type CalendarButtonState = {
  open: boolean;
};

class CalendarButton extends React.PureComponent<
  CalendarButtonProps,
  CalendarButtonState
> {
  static defaultProps = {
    onClickDate: () => {},
    max_date: undefined,
    item_size: 27,
    item_color: {
      hover: "#f1f3f5",
      selected: "#3578ea",
      background: "white",
    },
    popover: true,
  };
  private _unmounted: boolean = false;
  constructor(props: CalendarButtonProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", (evt) => {
      this.handleClickOutside(evt);
    });
  }

  componentWillUnmount() {
    if (this._unmounted === true) return;
    document.removeEventListener("mousedown", (evt) => {
      this.handleClickOutside(evt);
    });
    this._unmounted = true;
  }

  handleClickOutside(event: MouseEvent) {
    const targetId = (event.target as Element).id; // component click시 제외
    if (targetId === "goono-calendar-input" || targetId === "goono-calendar")
      return;
    this.setState({ open: false });
  }
  selectOptStyle(isSelect: boolean) {
    return isSelect
      ? {
          backgroundColor: "#edf2ff",
          border: `1px solid #4263eb`,
          color: "#4263eb",
        }
      : {
          backgroundColor: "white",
        };
  }

  defaultButton() {
    return (
      <Button
        onClick={() => {
          this.setState({ open: !this.state.open });
        }}
        style={{
          width: 200,
          height: 40,
          borderRadius: 10,
          boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.04)",
          ...this.selectOptStyle(this.props.value !== undefined),
        }}
      >
        <Typography>
          {this.props.value === undefined
            ? moment().tz(this.props.time_zone).format("YYYY. MM. DD")
            : moment(this.props.value)
                .tz(this.props.time_zone)
                .format("YYYY. MM. DD")}
        </Typography>
      </Button>
    );
  }

  onClick(date: Date) {
    this.setState({ open: false }, () => {
      this.props.onClickDate(date);
    });
    return;
  }

  render() {
    return (
      <div style={{ position: "relative" }}>
        {this.props.buttonElem !== undefined
          ? this.props.buttonElem
          : this.defaultButton()}
        {this.state.open === true && (
          <GoonoCalendar
            id="goono-calendar"
            {...this.props}
            onClickDate={(date) => {
              this.onClick(date);
            }}
            max_date={this.props.max_date}
            style={
              this.props.popover === false
                ? { ...styles.calender }
                : { position: "absolute", ...styles.calender }
            }
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CalendarButton);
