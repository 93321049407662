import React from "react";
import moment from "moment-timezone";
import {
  InputBase,
  Grid,
  Collapse,
  Box,
  withStyles,
  WithStyles,
  Typography,
} from "@material-ui/core";
import PublicRoundedIcon from "@material-ui/icons/PublicRounded";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import styles from "./styles/TimeZone";
import InfiniteScrollList from "../lists/InfiniteScrollList";

type TimeZoneProps = {
  curr_time_zone?: string;
  onClick: (time_zone: string) => void;
} & WithStyles<typeof styles>;

type TimeZoneState = {
  open: boolean;
  searchInput: string;
  all_zone: string[];
};

class TimeZone extends React.PureComponent<TimeZoneProps, TimeZoneState> {
  constructor(props: TimeZoneProps) {
    super(props);
    this.state = {
      open: false,
      searchInput: "",
      all_zone: [...moment.tz.names()],
    };
  }

  onChangeSearchInput(
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    this.setState({ searchInput: evt.currentTarget.value });
  }

  getFilterList() {
    const searchInput = this.state.searchInput;
    if (searchInput.trim().length === 0) return this.state.all_zone;
    const filtered = this.state.all_zone.filter((zone) => {
      if (zone.toLowerCase().includes(searchInput.toLowerCase()) === true)
        return true;
      else return false;
    });
    return filtered;
  }

  getTimeZone() {
    const { curr_time_zone } = this.props;
    return curr_time_zone || moment.tz.guess();
  }

  render() {
    const { classes } = this.props;
    const timezone = this.getTimeZone();
    const filtered = this.getFilterList();
    return (
      <>
        <Grid container item alignItems="center" className={classes.root}>
          <Grid
            container
            item
            alignItems="center"
            onClick={() => {
              this.setState({ open: !this.state.open });
            }}
            className={classes.timezone_display}
          >
            <Typography className={classes.text}>{timezone}</Typography>
            <ArrowDropDownRoundedIcon className={classes.open_icon} />
          </Grid>
          <Collapse
            in={this.state.open}
            timeout={"auto"}
            unmountOnExit
            className={classes.collapse_grid}
          >
            <Box>
              <InputBase
                placeholder="표준 시간대를 검색합니다"
                onChange={(evt) => this.onChangeSearchInput(evt)}
                value={this.state.searchInput}
                className={classes.search_input}
              />
              <Grid
                container
                item
                direction="row"
                alignItems="center"
                className={classes.current_timezone_grid}
              >
                <PublicRoundedIcon className={classes.current_timezone_icon} />
                <Typography className={classes.current_timezone_text}>
                  현재 : {timezone}
                </Typography>
              </Grid>

              <InfiniteScrollList
                onClick={(zone: string) => {
                  this.setState({ open: false, searchInput: "" }, () => {
                    this.props.onClick(zone);
                  });
                }}
                items={filtered}
              />
            </Box>
          </Collapse>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(TimeZone);
