import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoadingScreen from "../screens/LoadingScreen";
import LoginScreen from "../screens/LoginScreen";
import MainScreen from "../screens/MainScreen";
import DashBoardScreen from "../screens/DashBoardScreen";
import LicenseScreen from "../screens/LicenseScreen";
import AccessScreen from "../screens/AccessScreen";
import WorkspaceScreen from "../screens/WorkspaceScreen";

import ScreenURL from "./path";

class RootRouter extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path={ScreenURL.LOADING} component={LoadingScreen} />
          <Route exact path={ScreenURL.LOGIN} component={LoginScreen} />
          <Route exact path={ScreenURL.MAIN} component={MainScreen} />
          <Route exact path={ScreenURL.ACCESS} component={AccessScreen} />
          <Route exact path={ScreenURL.DASHBOARD} component={DashBoardScreen} />
          <Route exact path={ScreenURL.USER_MAIN} component={LicenseScreen} />
          <Route exact path={ScreenURL.WORKSPACE} component={WorkspaceScreen} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default RootRouter;
