import React from "react";
import Route from "./routes";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import ContainerProvider from "./component/smart/ContainerProvider";
import { changeEnv, ENV } from "@basalt-react-commons/services/api";

if (typeof process.env.REACT_APP_TEST_ENABLED === "string") {
  if (
    process.env.REACT_APP_TEST_ENABLED === "1" ||
    process.env.REACT_APP_TEST_ENABLED === "y" ||
    process.env.REACT_APP_TEST_ENABLED === "Y"
  ) {
    changeEnv("test");
  }
  if (typeof process.env.REACT_APP_API_SERVER === "string") {
    ENV.API_SERVER = process.env.REACT_APP_API_SERVER;
  }
}
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ContainerProvider>
          <Route />
        </ContainerProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
