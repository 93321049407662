import React from "react";
import { Grid, Typography, IconButton, MenuItem } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { RefinedUserWithLicense } from "@basalt-commons/api/object/license";
import moment from "moment";
import Services from "@basalt-react-commons/services";
import { UserLogObject } from "@basalt-commons/api/object/user_log";
import { WorkspaceWithPaymentInfo } from "@basalt-commons/global-api/object/admin";
import { WorkspaceRoleType } from "@basalt-commons/global-api/object/user_workspace_map";
import { WorkspacePlanType } from "@basalt-commons/global-api/object/workspace_plan";
const { IPFSService } = Services;
type UserInfoViewProps = {
  onClose: () => void;
  visitLogs: UserLogObject[];
  noteLogs: UserLogObject[];
  pdfLogs: UserLogObject[];
  linkLogs: UserLogObject[];
  workspaces: WorkspaceWithPaymentInfo[];
  user: RefinedUserWithLicense;
};
type UserInfoViewState = {
  mode: "visit" | "license" | "info";
};
class UserInfoView extends React.PureComponent<
  UserInfoViewProps,
  UserInfoViewState
> {
  constructor(props: UserInfoViewProps) {
    super(props);
    this.state = {
      mode: "info",
    };
  }

  getWorkspaceRole(role?: WorkspaceRoleType) {
    if (role === undefined) return "Role 없음";
    switch (role) {
      case WorkspaceRoleType.ADMIN:
        return "Admin";
      case WorkspaceRoleType.OWNER:
        return "Owner";
      case WorkspaceRoleType.MEMBER:
        return "Member";
      default:
        return "Guest";
    }
  }

  getWorkspacePlanname(plan?: WorkspacePlanType) {
    if (plan === undefined) return "Plan 없음";
    switch (plan) {
      case WorkspacePlanType.TRIAL:
        return "체험 진행 중";
      case WorkspacePlanType.INDIVIDUAL:
        return "개인 Plan";
      case WorkspacePlanType.TEAM:
        return "팀 Plan";
      case WorkspacePlanType.ENTERPRISE:
        return "Enterprise Plan";
    }
  }

  renderVisitInfo() {
    const { visitLogs } = this.props;
    const tempLog = [...visitLogs];
    const logs = tempLog.reverse();
    return (
      <Grid
        xs={12}
        container
        item
        style={{ height: 560, overflow: "scroll" }}
        alignContent="flex-start"
      >
        <Grid xs={12} container item>
          <Typography>접속 정보</Typography>
        </Grid>
        {logs.map((log) => {
          return (
            <Grid
              xs={12}
              container
              item
              alignContent="flex-start"
              style={{
                backgroundColor: "#eeeeee50",
                padding: 10,
                paddingBottom: 20,
                borderRadius: 10,
              }}
            >
              <Grid xs={12} container item>
                <Typography style={{ color: "#1e2022" }}>
                  접속 일자 :{" "}
                  {moment(log.createdAt).format("YYYY. MM. DD HH:mm")}
                </Typography>
              </Grid>
              <Grid xs={4} container item>
                <Typography>접속 기기 : {log.platformOS}</Typography>
              </Grid>
              <Grid xs={4} container item>
                <Typography>접속 버전 (앱) : {log.app_version}</Typography>
              </Grid>
              <Grid xs={4} container item>
                <Typography>접속 정보 : {log.device}</Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  renderVisitLogMode() {
    const { visitLogs } = this.props;
    const tempLog = [...visitLogs];
    const logs = tempLog.reverse();
    return (
      <>
        <Grid xs={12} container item alignItems="center" style={{ height: 55 }}>
          <Grid xs={2} container item>
            <Typography
              style={{ fontSize: 13, fontWeight: "bold", color: "#495057" }}
            >
              접속 내역
            </Typography>
          </Grid>
          <Grid xs={10} container item>
            <Typography
              style={{ fontSize: 14, fontWeight: "bold", color: "#212529" }}
            >
              {logs.length} 개의 이력
            </Typography>
          </Grid>
        </Grid>
        {logs.map((log) => {
          return (
            <Grid
              xs={12}
              container
              item
              alignItems="center"
              style={{
                borderRadius: 10,
                padding: 15,
                backgroundColor: "#f8f9fa",
                marginBottom: 20,
              }}
            >
              <Grid xs={2} container item>
                <Typography
                  style={{ fontSize: 13, fontWeight: "bold", color: "#495057" }}
                >
                  접속 정보
                </Typography>
              </Grid>
              <Grid xs={4} container item>
                <Typography
                  style={{ fontSize: 14, fontWeight: "bold", color: "#212529" }}
                >
                  {moment(log.createdAt).format("YYYY. MM. DD HH:MM:SS")}
                </Typography>
              </Grid>
              <Grid xs={2} container item>
                <Typography
                  style={{ fontSize: 14, fontWeight: "bold", color: "#212529" }}
                >
                  {log.platformOS}
                </Typography>
              </Grid>
              <Grid xs={3} container item>
                <Typography
                  style={{ fontSize: 14, fontWeight: "bold", color: "#212529" }}
                >
                  {log.device}
                </Typography>
              </Grid>
              <Grid xs={1} container item>
                {log.app_version !== undefined && (
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      padding: 5,
                      paddingLeft: 8,
                      paddingRight: 8,
                      borderRadius: 8,
                      backgroundColor: "#2f9e44",
                      color: "white",
                    }}
                  >
                    {log.app_version}
                  </Typography>
                )}
              </Grid>
            </Grid>
          );
        })}
      </>
    );
  }

  renderLicenseMode() {
    const { user, workspaces } = this.props;

    return (
      <>
        <Grid xs={12} container item alignItems="center" style={{ height: 55 }}>
          <Grid xs={2} container item>
            <Typography
              style={{ fontSize: 13, fontWeight: "bold", color: "#495057" }}
            >
              소속된 워크스페이스
            </Typography>
          </Grid>
          <Grid xs={10} container item>
            <Typography
              style={{ fontSize: 14, fontWeight: "bold", color: "#212529" }}
            >
              {workspaces.length} 개의 워크스페이스에 소속되어 있습니다.
            </Typography>
          </Grid>
        </Grid>
        {workspaces.map((w) => {
          return (
            <Grid
              container
              item
              style={{
                width: "100%",
                borderRadius: 10,
                border: `1px solid #4263eb`,
                padding: 25,
                marginBottom: 20,
              }}
            >
              <Grid
                xs={12}
                container
                item
                alignItems="center"
                style={{ height: 40 }}
              >
                <Grid container item style={{ width: "30%" }}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "#495057",
                    }}
                  >
                    워크스페이스 이름
                  </Typography>
                </Grid>
                <Grid container item style={{ width: "70%" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      color: "#000000",
                    }}
                  >
                    {w.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                item
                alignItems="center"
                style={{ height: 40 }}
              >
                <Grid container item style={{ width: "30%" }}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "#495057",
                    }}
                  >
                    워크스페이스 내 role
                  </Typography>
                </Grid>
                <Grid container item style={{ width: "70%" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      color: "#212529",
                    }}
                  >
                    {this.getWorkspaceRole(
                      w.Users.find((u) => u.id === user.id)?.roleType
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                item
                alignItems="center"
                style={{ height: 40 }}
              >
                <Grid container item style={{ width: "30%" }}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "#495057",
                    }}
                  >
                    워크스페이스 내 인원
                  </Typography>
                </Grid>
                <Grid container item style={{ width: "70%" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      color: "#212529",
                    }}
                  >
                    {w.Users.length} 명
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                item
                alignItems="center"
                style={{ height: 40 }}
              >
                <Grid container item style={{ width: "30%" }}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "#495057",
                    }}
                  >
                    워크스페이스 플랜
                  </Typography>
                </Grid>
                <Grid container item style={{ width: "70%" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      color: "#212529",
                    }}
                  >
                    {this.getWorkspacePlanname(w.WorkspacePlan?.plan)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                item
                alignItems="center"
                style={{ height: 40 }}
              >
                <Grid container item style={{ width: "30%" }}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "#495057",
                    }}
                  >
                    워크스페이스 내 크레딧 정보
                  </Typography>
                </Grid>
                <Grid container item style={{ width: "70%" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      color: "#212529",
                    }}
                  >
                    {w.WorkspacePlan.currency === "KRW"
                      ? w.krw_credit
                      : w.usd_credit}{" "}
                    크레딧 보유 중
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </>
    );
  }

  renderUserInfoMode() {
    const { user } = this.props;
    return (
      <>
        <Grid xs={12} container item alignItems="center" style={{ height: 55 }}>
          <Grid xs={2} container item>
            <Typography
              style={{ fontSize: 13, fontWeight: "bold", color: "#495057" }}
            >
              소속 기관
            </Typography>
          </Grid>
          <Grid xs={10} container item>
            <Typography
              style={{ fontSize: 14, fontWeight: "bold", color: "#212529" }}
            >
              {user.school}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} container item alignItems="center" style={{ height: 55 }}>
          <Grid xs={2} container item>
            <Typography
              style={{ fontSize: 13, fontWeight: "bold", color: "#495057" }}
            >
              학과
            </Typography>
          </Grid>
          <Grid xs={10} container item>
            <Typography
              style={{ fontSize: 14, fontWeight: "bold", color: "#212529" }}
            >
              {user.department}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} container item alignItems="center" style={{ height: 55 }}>
          <Grid xs={2} container item>
            <Typography
              style={{ fontSize: 13, fontWeight: "bold", color: "#495057" }}
            >
              최근 접속
            </Typography>
          </Grid>
          {this.props.visitLogs.length > 0 && (
            <Grid xs={10} container item>
              <Typography
                style={{ fontSize: 14, fontWeight: "bold", color: "#212529" }}
              >
                {moment(
                  this.props.visitLogs[this.props.visitLogs.length - 1]
                    .createdAt
                ).format("YYYY. MM. DD - HH:mm")}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid xs={12} container item alignItems="center" style={{ height: 55 }}>
          <Grid xs={2} container item>
            <Typography
              style={{ fontSize: 13, fontWeight: "bold", color: "#495057" }}
            >
              마케팅 활용 동의
            </Typography>
          </Grid>
          <Grid xs={10} container item>
            {user.marketing_term === undefined && (
              <Typography
                style={{ fontSize: 14, fontWeight: "bold", color: "#212529" }}
              >
                동의 안내를 받지 않았습니다
              </Typography>
            )}
            {user.marketing_term !== undefined && (
              <Typography
                style={{ fontSize: 14, fontWeight: "bold", color: "#212529" }}
              >
                {user.marketing_term === true ? (
                  <span
                    role="img"
                    aria-label="writing hand"
                    style={{ marginRight: 8 }}
                  >
                    ✅
                  </span>
                ) : (
                  <span
                    role="img"
                    aria-label="writing hand"
                    style={{ marginRight: 8 }}
                  >
                    ❌
                  </span>
                )}
                {user.marketing_term === true ? "동의함" : "비동의"}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid xs={12} container item alignItems="center" style={{ height: 55 }}>
          <Grid xs={2} container item>
            <Typography
              style={{ fontSize: 13, fontWeight: "bold", color: "#495057" }}
            >
              최근 업로드 횟수
            </Typography>
          </Grid>
          <Grid xs={10} container item>
            <Typography
              style={{ fontSize: 14, fontWeight: "bold", color: "#212529" }}
            >
              {this.props.noteLogs.length} 회 업로드
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} container item alignItems="center" style={{ height: 55 }}>
          <Grid xs={2} container item>
            <Typography
              style={{ fontSize: 13, fontWeight: "bold", color: "#495057" }}
            >
              PDF 다운로드 횟수
            </Typography>
          </Grid>
          <Grid xs={10} container item>
            <Typography
              style={{ fontSize: 14, fontWeight: "bold", color: "#212529" }}
            >
              {this.props.pdfLogs.length} 회 다운로드
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} container item alignItems="center" style={{ height: 55 }}>
          <Grid xs={2} container item>
            <Typography
              style={{ fontSize: 13, fontWeight: "bold", color: "#495057" }}
            >
              링크 생성 횟수
            </Typography>
          </Grid>
          <Grid xs={10} container item>
            <Typography
              style={{ fontSize: 14, fontWeight: "bold", color: "#212529" }}
            >
              {this.props.linkLogs.length} 회 링크 생성
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  getPlatformIcon() {
    const platform = this.props.user.platform;
    switch (platform) {
      case "kakao": {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              height: 40,
              backgroundColor: "#f7e600",
              borderRadius: 8,
              marginTop: 8,
            }}
          >
            <img
              src={require("../../assets/images/kakao.png")}
              style={{ width: 20, height: 20 }}
            />
          </div>
        );
      }
      case "google": {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              height: 40,
              backgroundColor: "white",
              border: "1px solid #f1f3f5",
              borderRadius: 8,
              marginTop: 8,
            }}
          >
            <img
              src={require("../../assets/images/google.png")}
              style={{ width: 20, height: 20 }}
            />
          </div>
        );
      }
      case "apple": {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              height: 40,
              backgroundColor: "black",
              borderRadius: 8,
              marginTop: 8,
            }}
          >
            <img
              src={require("../../assets/images/apple.png")}
              style={{ width: 20, height: 20 }}
            />
          </div>
        );
      }
      case "test": {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              height: 40,
              backgroundColor: "#c8daf9",
              borderRadius: 8,
              marginTop: 8,
            }}
          ></div>
        );
      }
      case "email": {
        return (
          <img
            src={require("../../assets/images/app_icon.png")}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              height: 40,
              borderRadius: 8,
              marginTop: 8,
            }}
          />
        );
      }
    }
  }

  render() {
    const { user, onClose } = this.props;
    return (
      <Grid
        alignItems="center"
        justify="center"
        xs={12}
        container
        item
        style={{
          height: "100%",
          backgroundColor: "#00000080",
          position: "absolute",
        }}
      >
        <Grid
          xs={7}
          container
          item
          alignContent="flex-start"
          style={{ height: "85%", backgroundColor: "white", borderRadius: 20 }}
        >
          <Grid xs={12} container style={{ height: "100%" }}>
            <Grid
              direction="column"
              justify="center"
              style={{
                width: "24.5%",
                height: "100%",
                backgroundColor: "#f8f9fa",
                borderRight: "1px solid #f1f3f5",
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
              }}
            >
              <Grid
                xs={12}
                container
                item
                justify="center"
                style={{ marginTop: 40 }}
              >
                <img
                  src={IPFSService.getIPFSUrl(user.profile_cid)}
                  style={{ width: 80, height: 80, borderRadius: 40 }}
                />
              </Grid>
              <Grid
                xs={12}
                direction="column"
                container
                item
                alignItems="center"
                style={{ marginTop: 20 }}
              >
                <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
                  {user.name}
                </Typography>
                <Typography style={{ fontSize: 14 }}>{user.email}</Typography>
                {this.getPlatformIcon()}
                <Typography
                  style={{ fontSize: 12, color: "#495057", marginTop: 10 }}
                >
                  {moment(user.createdAt).format("YYYY. MM. DD")} 에 가입
                </Typography>
              </Grid>
              <Grid
                xs={12}
                container
                item
                style={{
                  marginTop: 40,
                  borderTop: "1px solid #f1f3f5",
                  paddingTop: 20,
                }}
              >
                <MenuItem
                  onClick={() => {
                    this.setState({ mode: "info" });
                  }}
                  style={{
                    width: "100%",
                    height: 60,
                    backgroundColor:
                      this.state.mode === "info" ? "#f1f3f5" : "transparent",
                  }}
                >
                  <span
                    role="img"
                    aria-label="writing hand"
                    style={{ marginRight: 15 }}
                  >
                    😃
                  </span>
                  <Typography
                    style={{
                      fontSize: 15,
                      fontWeight: this.state.mode === "info" ? 600 : 550,
                      color: this.state.mode === "info" ? "#212529" : "#868e96",
                    }}
                  >
                    유저 정보
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.setState({ mode: "license" });
                  }}
                  style={{
                    width: "100%",
                    height: 60,
                    backgroundColor:
                      this.state.mode === "license" ? "#f1f3f5" : "transparent",
                  }}
                >
                  <span
                    role="img"
                    aria-label="writing hand"
                    style={{ marginRight: 15 }}
                  >
                    👑
                  </span>
                  <Typography
                    style={{
                      fontSize: 15,
                      fontWeight: this.state.mode === "info" ? 600 : 550,
                      color:
                        this.state.mode === "license" ? "#212529" : "#868e96",
                    }}
                  >
                    워크스페이스 내역
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.setState({ mode: "visit" });
                  }}
                  style={{
                    width: "100%",
                    height: 60,
                    backgroundColor:
                      this.state.mode === "visit" ? "#f1f3f5" : "transparent",
                  }}
                >
                  <span
                    role="img"
                    aria-label="writing hand"
                    style={{ marginRight: 15 }}
                  >
                    🖥
                  </span>
                  <Typography
                    style={{
                      fontSize: 15,
                      fontWeight: this.state.mode === "info" ? 600 : 550,
                      color:
                        this.state.mode === "visit" ? "#212529" : "#868e96",
                    }}
                  >
                    접속 내역
                  </Typography>
                </MenuItem>
              </Grid>
            </Grid>
            <Grid
              container
              item
              alignContent="flex-start"
              style={{ height: "100%", width: "75%", overflow: "hidden" }}
            >
              <Grid
                xs={12}
                container
                item
                justify="flex-end"
                style={{ height: "5%" }}
              >
                <IconButton
                  onClick={() => {
                    onClose();
                  }}
                >
                  <CloseIcon style={{ color: "black" }} />
                </IconButton>
              </Grid>
              <Grid
                xs={12}
                container
                item
                justify="flex-start"
                alignContent="flex-start"
                style={{
                  padding: 20,
                  paddingLeft: "4%",
                  paddingRight: "4%",
                  height: "95%",
                  overflow: "scroll",
                }}
              >
                {this.state.mode === "info" && (
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      textDecoration: "underline 3px #c7b9f3",
                      marginBottom: 20,
                    }}
                  >
                    유저 정보
                  </Typography>
                )}
                {this.state.mode === "license" && (
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      textDecoration: "underline 3px #c7b9f3",
                      marginBottom: 20,
                    }}
                  >
                    워크스페이스 내역
                  </Typography>
                )}
                {this.state.mode === "visit" && (
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      textDecoration: "underline 3px #c7b9f3",
                      marginBottom: 20,
                    }}
                  >
                    접속 내역
                  </Typography>
                )}
                {this.state.mode === "info" && this.renderUserInfoMode()}
                {this.state.mode === "license" && this.renderLicenseMode()}
                {this.state.mode === "visit" && this.renderVisitLogMode()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default UserInfoView;
